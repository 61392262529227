import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'

export const paymentPlanRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('payment-plans', {
      list: () => import(/* webpackChunkName: "payments" */ './Pages/List.vue'),
    })
      .only(['list'])
      .permissions({
        list: 'payment-plans.view',
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
