import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const invoiceRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('invoices', {
      show: () => import(/* webpackChunkName: "payments" */ './Pages/Show.vue'),
      edit: () => import(/* webpackChunkName: "payments" */ './Pages/Edit.vue'),
    })
      .only(['show', 'edit'])
      .permissions({
        show: 'invoices.view',
        edit: 'invoices.update',
      })
      .resolve(),

    new Route('invoices.list', '/invoices/:tab?', () =>
      import(/* webpackChunkName: "payments" */ './Pages/List.vue')
    )
      .permission('invoices.view')
      .resolve(),
    new Route('invoices.documents.list', '/invoices/:invoiceId/documents', () =>
      import(
        /* webpackChunkName: "payments" */ './InvoiceDocument/Pages/List.vue'
      )
    )
      .permission('invoices.view')
      .resolve(),
    new Route('invoices.payments.list', '/invoices/:invoiceId/payments', () =>
      import(
        /* webpackChunkName: "payments" */ './InvoicePayment/Pages/List.vue'
      )
    )
      .permission('invoices.view')
      .resolve(),
    new Route(
      'invoices.activities.list',
      '/invoices/:invoiceId/activities',
      () =>
        import(
          /* webpackChunkName: "payments" */ './InvoiceActivity/Pages/List.vue'
        )
    )
      .permission('invoices.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
