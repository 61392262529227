<template>
  <div>
    <div v-b-toggle="collapseId" class="text-danger cursor-pointer">
      <small>{{ message }}</small>
      <Icon icon="angle-down" size="14" />
    </div>
    <b-collapse :id="collapseId">
      <pre v-html="prettyPrintJson.toHtml(rule)" />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'UiRulesError',

  props: {
    message: {
      type: String,
      default: 'Error!',
    },
    rule: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    collapseId() {
      return this.id + 'uiRulesError'
    },
  },
}
</script>
