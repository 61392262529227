import auth from '@/Middlewares/auth.js'
import driveDocuments from '@/Middlewares/driveDocuments.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

const quoteShowComponent = () =>
  import(/* webpackChunkName: "quotes" */ './Pages/Show.vue')
const policySuperEdit = () =>
  import(/* webpackChunkName: "quotes" */ './Policy/Pages/SuperEdit.vue')

const quoteObjectComponents = {
  create: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteObject/Pages/Create.vue'
    ),
  edit: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteObject/Pages/Edit.vue'
    ),
  list: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteObject/Pages/List.vue'
    ),
}

const quoteObjectListComponent = () =>
  import(/* webpackChunkName: "quote-pages" */ './QuoteObject/Pages/List.vue')

const documentListComponent = () =>
  import(/* webpackChunkName: "quote-pages" */ './QuoteDocument/Pages/List.vue')

const documentListOneDriveComponent = () =>
  import(
    /* webpackChunkName: "quote-pages" */ './QuoteDocument/Pages/OneDrive/List.vue'
  )

const transactionListComponent = () =>
  import(
    /* webpackChunkName: "quote-pages" */ './QuoteTransaction/Pages/List.vue'
  )

const transactionGridComponent = () =>
  import(
    /* webpackChunkName: "quote-pages" */ './QuoteTransaction/Pages/Grid.vue'
  )

const quoteInvoiceComponents = {
  create: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteInvoice/Pages/Create.vue'
    ),
  edit: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteInvoice/Pages/Edit.vue'
    ),
  show: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteInvoice/Pages/Show.vue'
    ),
}

const activityComponents = {
  list: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteActivity/Pages/List.vue'
    ),
  create: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteActivity/Pages/Create.vue'
    ),
  edit: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteActivity/Pages/Edit.vue'
    ),

  show: () =>
    import(
      /* webpackChunkName: "quote-pages" */ './QuoteActivity/Pages/Show.vue'
    ),
}

const messagesListComponent = () =>
  import(/* webpackChunkName: "quote-pages" */ './QuoteMessage/Pages/List.vue')

const apiLogComponent = () =>
  import(/* webpackChunkName: "quote-pages" */ './QuoteApiLog/Pages/List.vue')

const changeLogComponent = () =>
  import(
    /* webpackChunkName: "quote-pages" */ './QuoteChangeLog/Pages/List.vue'
  )

const claimsComponent = () =>
  import(/* webpackChunkName: "quote-pages" */ './QuoteClaim/Pages/List.vue')

export const quoteAndPolicyRoutes = new RouteGroup(() => {
  return [
    new Route('quotes.select-schema', '/quotes/select', () =>
      import(/* webpackChunkName: "quote-pages" */ './Pages/SelectSchema.vue')
    )
      .permission('quotes.view')
      .resolve(),

    new Route('quotes.quick-select-schema', '/quotes/quick-select', () =>
      import(
        /* webpackChunkName: "quote-pages" */ './Pages/QuickSelectSchema.vue'
      )
    )
      .permission('quotes.view')
      .resolve(),

    new Route(
      'quotes.create',
      '/quotes/create/:schemaId/:temporaryFolderId?',
      () => import(/* webpackChunkName: "quotes" */ './Pages/Create.vue')
    )
      .permission('quotes.create')
      .resolve(),

    new Route('quotes.list', '/quotes/:tab?', () =>
      import(/* webpackChunkName: "quote-pages" */ './Pages/List.vue')
    )
      .permission('quotes.view')
      .resolve(),
    new Route('policies.list', '/policies/:tab?', () =>
      import(/* webpackChunkName: "quote-pages" */ './Policy/Pages/List.vue')
    )
      .permission('policies.view')
      .resolve(),
    new Route('renewals.list', '/renewals/:tab?', () =>
      import(
        /* webpackChunkName: "quote-pages" */ './Policy/Renewal/Pages/List.vue'
      )
    )
      .permission('policies.view')
      .resolve(),
    new Route(
      'quotes.show',
      '/quotes/:quoteId/:temporaryFolderId?/show',
      quoteShowComponent
    )
      .permission('quotes.view')
      .resolve(),
    new Route('policies.show', '/policies/:quoteId/show', quoteShowComponent)
      .permission('policies.view')
      .resolve(),

    ...new ResourceRoute('quotes.objects', quoteObjectComponents)
      .permissions({
        create: 'quote-objects.create',
        edit: 'quote-objects.update',
      })
      .meta({
        create: { back: 'quotes.objects.list' },
        edit: { back: 'quotes.objects.list' },
      })
      .except(['show', 'list'])
      .resolve(),
    new Route(
      'quotes.objects.list',
      '/quotes/:quoteId/objects/:tab?',
      quoteObjectListComponent
    )
      .permission('quote-objects.view')
      .resolve(),

    ...new ResourceRoute('policies.objects', quoteObjectComponents)
      .permissions({
        create: 'quote-objects.create',
        edit: 'quote-objects.update',
      })
      .except(['show', 'list'])
      .parameters({ policyId: 'quoteId' })
      .resolve(),

    new Route(
      'policies.locked.super-edit',
      '/policies/:quoteId/super-edit',
      policySuperEdit
    )
      .permission('policies.locked.super-edit')
      .resolve(),
    new Route(
      'policies.objects.list',
      '/policies/:quoteId/objects/:tab?',
      quoteObjectListComponent
    )
      .permission('quote-objects.view')
      .resolve(),

    new Route(
      'quotes.documents.list',
      '/quotes/:quoteId/documents',
      documentListComponent
    )
      .permission('quotes.documents.view')
      .resolve(),
    new Route(
      'quotes.documents.list.folder',
      '/quotes/:quoteId/documents/:folderId',
      documentListComponent
    )
      .permission('quotes.documents.view')
      .resolve(),
    new Route(
      'quotes.driveDocuments.list',
      '/quotes/:quoteId/drive-documents',
      documentListOneDriveComponent,
      driveDocuments
    )
      .permission('quotes.documents.view')
      .resolve(),
    new Route(
      'policies.documents.list',
      '/policies/:quoteId/documents',
      documentListComponent
    )
      .permission('quotes.documents.view')
      .resolve(),
    new Route(
      'policies.documents.list.folder',
      '/policies/:quoteId/documents/:folderId',
      documentListComponent
    )
      .permission('quotes.documents.view')
      .resolve(),
    new Route(
      'policies.driveDocuments.list',
      '/policies/:quoteId/drive-documents',
      documentListOneDriveComponent,
      driveDocuments
    )
      .permission('quotes.documents.view')
      .resolve(),

    new Route(
      'quotes.transactions.list',
      '/quotes/:quoteId/transactions/list',
      transactionListComponent
    )
      .permission('transactions.view')
      .resolve(),
    new Route(
      'policies.transactions.list',
      '/policies/:quoteId/transactions/list',
      transactionListComponent
    )
      .permission('transactions.view')
      .resolve(),
    new Route(
      'quotes.transactions.list.grid',
      '/quotes/:quoteId/transactions/grid',
      transactionGridComponent
    )
      .permission('transactions.view')
      .resolve(),
    new Route(
      'policies.transactions.list.grid',
      '/policies/:quoteId/transactions/grid',
      transactionGridComponent
    )
      .permission('transactions.view')
      .resolve(),

    ...new ResourceRoute('quotes.invoices', quoteInvoiceComponents)
      .permissions({
        create: 'invoices.create',
        edit: 'invoices.update',
        show: 'invoices.view',
      })
      .except(['list'])
      .resolve(),

    new Route(
      'quotes.invoices.documents.list',
      '/quotes/:quoteId/invoices/:invoiceId/documents',
      () =>
        import(
          /* webpackChunkName: "quote-pages" */ './QuoteInvoice/QuoteInvoiceDocument/Pages/List.vue'
        )
    )
      .permission('invoices.view')
      .resolve(),
    new Route(
      'quotes.invoices.payments.list',
      '/quotes/:quoteId/invoices/:invoiceId/payments',
      () =>
        import(
          /* webpackChunkName: "quote-pages" */ './QuoteInvoice/QuoteInvoicePayment/Pages/List.vue'
        )
    )
      .permission('invoices.view')
      .resolve(),

    ...new ResourceRoute('policies.invoices', quoteInvoiceComponents)
      .permissions({
        create: 'invoices.create',
        edit: 'invoices.update',
        show: 'invoices.view',
      })
      .except(['list'])
      .parameters({ policyId: 'quoteId' })
      .resolve(),

    new Route(
      'policies.invoices.documents.list',
      '/policies/:quoteId/invoices/:invoiceId/documents',
      () =>
        import(
          /* webpackChunkName: "quote-pages" */ './QuoteInvoice/QuoteInvoiceDocument/Pages/List.vue'
        )
    )
      .permission('invoices.view')
      .resolve(),
    new Route(
      'policies.invoices.payments.list',
      '/policies/:quoteId/invoices/:invoiceId/payments',
      () =>
        import(
          /* webpackChunkName: "quote-pages" */ './QuoteInvoice/QuoteInvoicePayment/Pages/List.vue'
        )
    )
      .permission('invoices.view')
      .resolve(),

    new Route('quotes.claims.list', '/quotes/:quoteId/claims', claimsComponent)
      .permission('claims.view')
      .resolve(),

    new Route(
      'policies.claims.list',
      '/policies/:quoteId/claims',
      claimsComponent
    )
      .permission('claims.view')
      .resolve(),

    ...new ResourceRoute('quotes.activities', activityComponents)
      .permissions({
        create: 'activities.create',
        edit: 'activities.update',
        show: 'activities.view',
        list: 'activities.view',
      })
      .resolve(),
    ...new ResourceRoute('policies.activities', activityComponents)
      .permissions({
        create: 'activities.create',
        edit: 'activities.update',
        show: 'activities.view',
        list: 'activities.view',
      })
      .parameters({ policyId: 'quoteId' })
      .resolve(),

    new Route(
      'quotes.api-logs.list',
      '/quotes/:quoteId/api-logs',
      apiLogComponent
    )
      .permission('quotes.logs.integration')
      .resolve(),

    new Route(
      'policies.api-logs.list',
      '/policies/:quoteId/api-logs',
      apiLogComponent
    )
      .permission('quotes.logs.integration')
      .resolve(),

    new Route(
      'quotes.changelogs.list',
      '/quotes/:quoteId/changelogs',
      changeLogComponent
    )
      .permission('quotes.logs.changes')
      .resolve(),
    new Route(
      'policies.changelogs.list',
      '/policies/:quoteId/changelogs',
      changeLogComponent
    )
      .permission('quotes.logs.changes')
      .resolve(),

    new Route(
      'policies.messages.list',
      '/policies/:quoteId/messages',
      messagesListComponent
    )
      .permission('messages.view')
      .resolve(),

    new Route(
      'quotes.messages.list',
      '/quotes/:quoteId/messages',
      messagesListComponent
    )
      .permission('messages.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()

export const persistableQuoteRoutes = [
  'quotes.show',
  'quotes.create',
  'policies.show',

  'quotes.documents.list',
  'quotes.documents.list.folder',
  'quotes.driveDocuments.list',
  'policies.documents.list',
  'policies.documents.list.folder',
  'policies.driveDocuments.list',

  'quotes.objects.list',
  'quotes.objects.create',
  'quotes.objects.edit',
  'policies.objects.list',
  'policies.objects.create',
  'policies.objects.edit',

  'quotes.activities.list',
  'quotes.activities.show',
  'quotes.activities.create',
  'quotes.activities.edit',
  'policies.activities.list',
  'policies.activities.show',
  'policies.activities.create',
  'policies.activities.edit',

  'quotes.claims.list',
  'policies.claims.list',

  'quotes.transactions.list',
  'quotes.transactions.list.random',
  'quotes.transactions.list.grid',
  'quotes.invoices.create',
  'quotes.invoices.show',
  'quotes.invoices.edit',
  'policies.transactions.list',
  'policies.invoices.create',
  'policies.invoices.show',
  'policies.invoices.edit',

  'quotes.payments.create',
  'quotes.payments.edit',
  'policies.payments.create',
  'policies.payments.edit',

  'quotes.api-logs.list',
  'policies.api-logs.list',

  'quotes.changelogs.list',
  'policies.changelogs.list',

  'policies.locked.super-edit',

  'quotes.messages.list',
  'policies.messages.list',
]
