import _ from 'lodash'

export default class QuoteEndorsementModel {
  quoteId = null
  endorsementId = null
  name = null
  tag = null
  index = null
  extraPremium = null
  description = null
  section = null
  currencyCode = null
  version = 1
  frozen = false
  productId = null
  product = null
  libraryId = null
  coins = null
  valuation = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  setFrozen(quote) {
    this.frozen = Number(quote?.version) !== Number(this.version)
  }
}
