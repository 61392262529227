<template>
  <b-card :body-class="{ 'p-0': table }" class="lift">
    <template #header>
      <div class="col pr-0 pl-0">
        <h4 class="card-header-title">
          <Icon v-if="icon" :icon="icon" class="text-muted mr-2 mt-4" size="22" style="margin-left: -5px;"/>
          {{ label }}
        </h4>
      </div>

      <div v-if="$slots.actions" class="col-auto pr-0 pl-0 mr-n2 btn-group-sm">
        <slot name="actions"/>
      </div>
    </template>

    <div :style="limitHeight ? `max-height: ${limitHeight}px; overflow-y: scroll; overflow-x: hidden;` : ''">
      <slot/>
    </div>

    <template v-if="$slots.footer" #footer>
      <slot name="footer"/>
    </template>
  </b-card>
</template>

<script>
export default {
  name: 'Card',

  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    table: {
      type: Boolean,
      default: false
    },
    limitHeight: {
      type: Number,
      default: null
    }
  }
}
</script>
