<template>
  <div
    v-if="visible"
    :id="name"
    :class="classColumn"
    class="mb-5 json-editor-field"
  >
    <div class="d-flex align-items-center justify-content-between">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
      <FieldHint v-if="hint" html :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <VJsoneditor
      :id="id || dotKey"
      v-model="model"
      v-validate="validation"
      :value="value"
      :name="name"
      :data-vv-name="dotKey"
      :readonly="readonly"
      :class="{
        [customClass]: !!customClass,
        readonly: readonly,
        'is-invalid': hasErrors,
        [`is-${highlightClass}`]: !!highlightClass,
      }"
      :height="height"
      :options="{
        search: false,
        mode: 'code',
        indentation: 2,
      }"
      :plus="fullScreenEnabled"
      @error="$emit('error')"
      @input="emitChange"
    />

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import VJsoneditor from '@wedevlt/v-jsoneditor'

export default {
  name: 'JsonField',

  components: {
    VJsoneditor,
  },

  mixins: [InputComponent],

  props: {
    height: {
      type: String,
      default: '300px',
    },
    fullScreenEnabled: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    emitChange(data) {
      this.$emit('input', data)
      this.$emit('onChange', data)
      this.$emit('changeDynamicFieldValue', data)
    },
  },
}
</script>

<style lang="scss">
.json-editor-field {
  .jsoneditor {
    border-radius: 0.5rem;
    overflow: hidden;
    border-color: #95aac9;
  }

  .jsoneditor-container {
    &.max-box {
      .jsoneditor {
        border-radius: 0;
      }
    }
  }

  .jsoneditor-menu {
    background-color: #fff;
    border-bottom: 1px solid #edf2f9;
  }

  .jsoneditor-container.min-box {
    min-width: inherit !important;
  }

  .jsoneditor-poweredBy {
    display: none;
  }

  .max-btn {
    right: 5px !important;
  }

  .jsoneditor-compact {
    display: none;
  }

  .jsoneditor-sort {
    display: none;
  }

  .jsoneditor-transform {
    display: none;
  }
}
</style>
