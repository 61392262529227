
<div v-if="visible" :id="id" :class="{ 'form-group': true }">
  <div class="row">
    <div class="col">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
    </div>

    <div class="col-auto">
      <button
        v-if="canReset"
        class="btn btn-link text-danger p-0 mb-2"
        @click="reset"
      >
        <Icon icon="times" class="text-danger" size="14" />
        <small>{{ __('buttons.reset') }}</small>
      </button>
    </div>
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <div :class="{ 'd-none': hideUploadCanvas }" class="input-group">
    <b-form-file
      :ref="`fileField_${name}`"
      v-model="model"
      :name="name"
      class="big-file-input"
      :multiple="multiple"
      :disabled="readonly"
      :class="{
        readonly: readonly,
        'is-invalid': hasErrors,
        [`is-${highlightClass}`]: !!highlightClass,
      }"
      :accept="accept"
      @input="onChange"
    >
      <template #file-name="{ names }">
        <b-button
          v-for="(name, key) in names"
          :key="key"
          class="mr-2"
          variant="outline-secondary"
        >
          <img
            width="24"
            :src="getFileTypeIcon(name)"
            class="mr-2"
            :alt="name"
          />
          {{ name }}
        </b-button>
      </template>

      <template #placeholder>
        <div>
          <Icon icon="cloud-upload" size="40" class="text-primary" />
          <br />
          {{ __('fields.file-field-placeholder') }}
        </div>
      </template>
    </b-form-file>
  </div>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>
</div>
