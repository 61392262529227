import _ from 'lodash'

export default class ReleaseModel {
  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  body = ''
  name = null
  version = null
  createdAt = null
  updatedAt = null
}
