
<div
  v-if="showMoreByHeight"
  class="reveal-wrapper"
  @mouseover="handleMouseOver"
  @mouseout="handleMouseOut"
>
  <div
    :style="{
      maxHeight: expanded ? 'none' : `${maxHeight}px`,
      overflow: 'hidden',
      paddingBottom: expanded ? '20px' : '0px',
    }"
    :class="customClass"
    v-html="html"
  />
  <div v-if="!expanded" class="overlay-bottom" />
  <b-button
    variant="light"
    size="sm"
    class="ml-2"
    :class="buttonClass"
    @click="handleShowMoreClick()"
  >
    {{ expanded ? __('common.show-less') : __('common.show-more') }}
  </b-button>
</div>
<div v-else>
  <div :class="customClass" v-html="html" />
</div>
