
<div
  :tabindex="searchable ? -1 : tabindex"
  :class="{
    'multiselect--active': isOpen,
    'multiselect--disabled': disabled,
    'multiselect--above': isAbove,
  }"
  class="multiselect"
  @focus="activate()"
  @blur="searchable ? false : deactivate()"
  @keydown.self.down.prevent="pointerForward()"
  @keydown.self.up.prevent="pointerBackward()"
  @keypress.enter.tab.stop.self="addPointerElement($event)"
  @keyup.esc="deactivate()"
>
  <slot name="caret" :toggle="toggle">
    <div class="multiselect__select" @mousedown.prevent.stop="toggle()"></div>
  </slot>

  <slot name="clear" :search="search"></slot>

  <div ref="tags" class="multiselect__tags">
    <slot
      name="selection"
      :search="search"
      :remove="removeElement"
      :values="visibleValues"
      :is-open="isOpen"
    >
      <div v-show="visibleValues.length > 0" class="multiselect__tags-wrap">
        <template v-for="(option, index) of visibleValues">
          <slot
            name="tag"
            :option="option"
            :search="search"
            :remove="removeElement"
          >
            <span :key="index" class="multiselect__tag">
              <span v-text="getOptionLabel(option)"></span>
              <i
                aria-hidden="true"
                tabindex="1"
                class="multiselect__tag-icon"
                @keypress.enter.prevent="removeElement(option)"
                @mousedown.prevent="removeElement(option)"
              ></i>
            </span>
          </slot>
        </template>
      </div>

      <template v-if="internalValue && internalValue.length > limit">
        <slot name="limit">
          <strong
            class="multiselect__strong"
            v-text="limitText(internalValue.length - limit)"
          />
        </slot>
      </template>
    </slot>

    <transition name="multiselect__loading">
      <slot name="loading">
        <div v-show="loading" class="multiselect__spinner" />
      </slot>
    </transition>

    <input
      v-if="searchable"
      :id="id"
      ref="search"
      :name="name + '_search'"
      type="text"
      autocomplete="off"
      :placeholder="placeholder"
      :style="inputStyle"
      :value="search"
      :disabled="disabled"
      :tabindex="tabindex"
      class="multiselect__input"
      style="color: #5b6a7b"
      @input="updateSearch($event.target.value)"
      @focus.prevent="activate()"
      @blur.prevent="deactivate()"
      @keyup.esc="deactivate()"
      @keydown.down.prevent="pointerForward()"
      @keydown.up.prevent="pointerBackward()"
      @keypress.enter.prevent.stop.self="addPointerElement($event)"
      @keydown.delete.stop="removeLastElement()"
    />

    <span
      v-if="isSingleLabelVisible"
      class="multiselect__single"
      @mousedown.prevent="toggle"
    >
      <slot name="singleLabel" :option="singleValue">
        {{ currentOptionLabel }}
      </slot>
    </span>

    <span
      v-if="isPlaceholderVisible"
      class="multiselect__placeholder"
      @mousedown.prevent="toggle"
    >
      <slot name="placeholder">
        {{ placeholder }}
      </slot>
    </span>
  </div>

  <transition name="multiselect">
    <div
      v-show="isOpen"
      ref="list"
      class="multiselect__content-wrapper"
      tabindex="-1"
      :style="{ maxHeight: optimizedHeight + 'px' }"
      @focus="activate"
      @mousedown.prevent
    >
      <ul class="multiselect__content" :style="contentStyle">
        <slot name="beforeList"></slot>
        <li v-if="multiple && max === internalValue.length">
          <span class="multiselect__option">
            <slot name="maxElements">
              {{ __('fields.multiselect-max-elements-selected', { max }) }}
            </slot>
          </span>
        </li>

        <template v-if="!max || internalValue.length < max">
          <li
            v-for="(option, index) of filteredOptions"
            :key="index"
            class="multiselect__element"
          >
            <span
              v-if="!(option && (option.$isLabel || option.$isDisabled))"
              :class="optionHighlight(index, option)"
              :data-select="
                option && option.isTag ? tagPlaceholder : selectLabelText
              "
              :data-selected="selectedLabelText"
              :data-deselect="deselectLabelText"
              class="multiselect__option"
              @click.stop="select(option)"
              @mouseenter.self="pointerSet(index)"
            >
              <slot name="option" :option="option" :search="search">
                <span>{{ getOptionLabel(option) }}</span>
              </slot>
            </span>

            <span
              v-if="option && (option.$isLabel || option.$isDisabled)"
              :data-select="groupSelect && selectGroupLabelText"
              :data-deselect="groupSelect && deselectGroupLabelText"
              :class="groupHighlight(index, option)"
              class="multiselect__option"
              @mouseenter.self="groupSelect && pointerSet(index)"
              @mousedown.prevent="selectGroup(option)"
            >
              <slot name="option" :option="option" :search="search">
                <span>{{ getOptionLabel(option) }}</span>
              </slot>
            </span>
          </li>
        </template>

        <li
          v-show="
            showNoResults &&
            filteredOptions.length === 0 &&
            search &&
            !loading
          "
        >
          <span class="multiselect__option">
            <slot name="noResult" :search="search">
              {{ __('fields.multiselect-no-elements-found') }}
            </slot>
          </span>
        </li>

        <li
          v-show="
            showNoOptions && options.length === 0 && !search && !loading
          "
        >
          <span class="multiselect__option">
            <slot name="noOptions">
              {{ __('fields.multiselect-list-empty') }}
            </slot>
          </span>
        </li>

        <slot name="afterList"></slot>
      </ul>
    </div>
  </transition>
</div>
