import FileManager from '@/General/FileManager.js'
import SchemaModel from '@/Modules/Schema/SchemaModel.js'
import axios from 'axios'

export default class SchemaService {
  static available = ({ parameters, filters }, excludeLoader = true) =>
    axios.get(`/schemas/available${filters}`, {
      excludeLoader,
      params: parameters,
    })
  static publishedAvailable = ({ parameters, filters }, excludeLoader = true) =>
    axios.get(`/schemas/published-available${filters}`, {
      excludeLoader,
      params: parameters,
    })
  static list = ({ parameters, filters }) =>
    axios.get(`/schemas${filters}`, { params: parameters, excludeLoader: true })
  static create = (data) =>
    axios
      .post('/schemas', data)
      .then((response) => new SchemaModel(response.data.data))
  static createViaBlueprint = ({ coverholderId, url }) =>
    axios
      .post(`/schemas/create-from-blueprint`, { coverholderId, url })
      .then((response) => new SchemaModel(response.data.data))
  static update = (schemaId, payload) =>
    axios.put(`/schemas/${schemaId}`, payload)
  static validate = (id, data) => axios.post(`schemas/${id}/validate`, data)
  static show = (id) =>
    axios.get(`schemas/${id}`).then((response) => response.data)
  static delete = (schemaId) => axios.delete(`/schemas/${schemaId}`)
  static duplicate = (id) =>
    axios
      .post(`/schemas/${id}/duplicate`)
      .then((response) => new SchemaModel(response.data.data))
  static publish = (id) => axios.post(`/schemas/${id}/publish`)
  static updateChangeNotes = (id, changeNotes) =>
    axios.put(`/schemas/${id}/update-change-notes`, {
      changeNotes: changeNotes,
    })
  static cancel = (id) => axios.post(`/schemas/${id}/cancel`)
  static newVersion = (id) =>
    axios
      .post(`/schemas/${id}/new-version`)
      .then((response) => new SchemaModel(response.data.data))
  static updateFile = (id) => axios.post(`/schemas/${id}/update-file`)
  static exportJson = (schemaId) =>
    axios
      .get(`schemas/${schemaId}/export`, { responseType: 'arraybuffer' })
      .then((response) => FileManager.downloadFile(response))
  static upload = (id, data) => axios.post(`/schemas/${id}/upload`, data)
  static integrations = (id) =>
    axios.get(`schemas/${id}/integrations`).then((response) => response.data)
  static parse = (id, payload) =>
    axios.post(`schemas/${id}/parse`, payload).then((response) => response.data)
  static versionsByReference = (reference) =>
    axios.get(`schemas/${reference}/versions`, { excludeLoader: true })
  static emailTemplatesBySchema = (id) =>
    axios.get(`schemas/${id}/email-templates`, { excludeLoader: true })
  static preGenerate = (id) =>
    axios
      .get(`schemas/${id}/pre-generate`, {
        responseType: 'arraybuffer',
      })
      .then((response) => FileManager.downloadFile(response))
  static getAiPrompts = async (id, limitToFields) => {
    const limitToFieldsQuery = `?limitToFields=${JSON.stringify(limitToFields)}`;
    const response = await axios.get(
      `schemas/${id}/ai/prompts${limitToFieldsQuery}`,
      {
        excludeLoader: true,
      }
    )
    return response.data
  }
}
