import _ from 'lodash'

export default class ErrorModel {
  msg = null
  level = 'danger'
  hasError = true
  key = null
  label = null

  constructor(error, dotKey = null, label = null) {
    if (!error) {
      this.hasError = false
    }

    _.forEach(error, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.key = dotKey
    this.label = label
  }
  getMessage() {
    if (!_.isString(this.key) || !_.isString(this.msg)) {
      this.hasError = false
      return ''
    }

    return _.upperFirst(
      this.msg.replace(/The.*field/, `The ${_.upperFirst(this.label)} field `)
    )
  }
}
