import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const PaymentPlanStatus = Object.freeze({
  active: 'active',
  inactive: 'inactive',
})

export const PaymentPlanRoundingStyle = Object.freeze({
  ceil: 'ceil',
  floor: 'floor',
  round: 'round',
})

export default class PaymentPlanModel {
  id = null
  tag = null
  name = null
  status = PaymentPlanStatus.active
  transactionsCount = 1
  createInvoice = null
  willGenerateInvoiceDocument = true
  proportions = null
  roundOnTransaction = 1
  roundingStyle = PaymentPlanRoundingStyle.round
  specificMonthDay = null
  dateOffsetDays = 1

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  static statuses() {
    return [
      {
        label: i18n.tc('statuses.active'),
        value: PaymentPlanStatus.active,
      },
      {
        label: i18n.tc('statuses.inactive'),
        value: PaymentPlanStatus.inactive,
      },
    ]
  }

  static roundingStyles() {
    return [
      {
        label: i18n.tc('payment-plans.rounding-ceil'),
        value: PaymentPlanRoundingStyle.ceil,
      },
      {
        label: i18n.tc('payment-plans.rounding-floor'),
        value: PaymentPlanRoundingStyle.floor,
      },
      {
        label: i18n.tc('payment-plans.rounding-round'),
        value: PaymentPlanRoundingStyle.round,
      },
    ]
  }

  statusData() {
    switch (this.status) {
      case PaymentPlanStatus.active: {
        return {
          label: i18n.tc('statuses.active'),
          badge: 'soft-success',
        }
      }
      case PaymentPlanStatus.inactive: {
        return {
          label: i18n.tc('statuses.inactive'),
          badge: 'soft-danger',
        }
      }
    }
  }
}
