import camelcase from 'camelcase'
import _ from 'lodash'

export default class ClientOccupationModel {
  id = null
  name = null
  code = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })
  }
}
