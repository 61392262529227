<template>
  <span v-if="showTooltip">
    <span :id="id">
      {{ truncateText }}
    </span>
    <b-tooltip :target="id" triggers="hover" :boundary="boundary">
      {{ tooltipContent || text }}
    </b-tooltip>
  </span>
  <span v-else>
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'TooltipTruncate',

  props: {
    id: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: '',
    },
    tooltipContent: {
      type: String,
      default: '',
    },
    maxChar: {
      type: Number,
      default: 20,
    },
    boundary: {
      type: String,
      default: 'window',
    },
  },

  computed: {
    truncateText() {
      if (this.text.length > this.maxChar) {
        return this.text.slice(0, this.maxChar) + '…'
      }
      return this.text
    },
    showTooltip() {
      if (
        typeof this.tooltipContent === 'string' &&
        this.tooltipContent.length > 0
      ) {
        return true
      }
      if (
        typeof this.text === 'string' &&
        (this.text.length > this.maxChar || this.maxChar === -1)
      ) {
        return true
      }
      return false
    },
  },
}
</script>
