import FileManager from '@/General/FileManager.js'
import axios from 'axios'

export default class DocumentService {
  static resource = null
  static documentsKey = 'documents'

  static list(quoteId, { parameters, filters }) {
    return axios.get(
      `/${this.resource}/${quoteId}/${this.documentsKey}${filters}`,
      {
        params: parameters,
        excludeLoader: true,
      }
    )
  }

  static listNested(quoteId, folderId, { parameters }) {
    return axios.get(
      `/${this.resource}/${quoteId}/${this.documentsKey}/folders/${folderId}`,
      {
        params: parameters,
        excludeLoader: true,
      }
    )
  }

  static listFolders(quoteId) {
    return axios.get(
      `/${this.resource}/${quoteId}/${this.documentsKey}/folders`,
      {
        excludeLoader: true,
      }
    )
  }

  static show(quoteId, id, fileName) {
    return axios
      .get(
        `/${this.resource}/${quoteId}/${this.documentsKey}/${id}?name=${fileName}`,
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => FileManager.downloadFile(response))
  }

  static preview(quoteId, id) {
    return axios
      .get(`/${this.resource}/${quoteId}/${this.documentsKey}/${id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => response)
  }

  static download(quoteId, id) {
    return axios
      .get(`/${this.resource}/${quoteId}/${this.documentsKey}/${id}/preview`)
      .then((response) => response.data?.url)
  }

  static create(quoteId, data) {
    return axios.post(`/${this.resource}/${quoteId}/${this.documentsKey}`, data)
  }

  static createMultiple(quoteId, data) {
    return axios.post(
      `/${this.resource}/${quoteId}/${this.documentsKey}/multiple`,
      data
    )
  }

  static delete(quoteId, id) {
    return axios.delete(
      `/${this.resource}/${quoteId}/${this.documentsKey}/${id}`
    )
  }

  static deleteFolder(quoteId, id) {
    return axios.delete(
      `/${this.resource}/${quoteId}/${this.documentsKey}/folders/${id}`
    )
  }

  static createFolder(quoteId, data) {
    return axios.post(
      `/${this.resource}/${quoteId}/${this.documentsKey}/folders`,
      data
    )
  }

  static updateFolder(quoteId, id, payload) {
    return axios.put(
      `/${this.resource}/${quoteId}/${this.documentsKey}/folders/${id}`,
      payload
    )
  }

  static moveDocument(quoteId, documentId, payload) {
    return axios.put(
      `/${this.resource}/${quoteId}/${this.documentsKey}/${documentId}/move`,
      payload
    )
  }

  static moveDocumentsInBulk(quoteId, payload) {
    return axios.put(
      `/${this.resource}/${quoteId}/${this.documentsKey}/move-bulk`,
      payload
    )
  }

  static send(quoteId, data) {
    return axios.post(
      `/${this.resource}/${quoteId}/${this.documentsKey}/send`,
      data
    )
  }
}
