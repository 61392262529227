import CounterModel from '@/Modules/Counter/CounterModel.js'
import axios from 'axios'
import _ from 'lodash'
import { fallbackLocale, localeKey } from '@/Setup/SetupI18n.js'

export const SET_LOADING_MUTATION = 'setLoading'
export const SET_IMMUTABLE_LOADING_MUTATION = 'setLoadingImmutable'
export const RESET_IMMUTABLE_LOADING_MUTATION = 'resetImmutableLoader'
export const SET_LOCAL_LOADING_MUTATION = 'setLocalLoading'
export const SET_DIALOG_MUTATION = 'setDialog'
export const RESET_DIALOG_MUTATION = 'resetDialog'
export const SET_INFORMATION_TOAST = 'setInformationToast'
export const RESET_INFORMATION_TOAST = 'resetInformationToast'
export const SET_CONFIRMATION_DIALOG_MUTATION = 'setConfirmationDialog'
export const RESET_CONFIRMATION_DIALOG_MUTATION = 'resetConfirmationDialog'
export const SET_QUOTE_STATE_COUNTER_MUTATION = 'setQuoteStateCounter'
export const SET_POLICY_STATE_COUNTER_MUTATION = 'setPolicyStateCounter'
export const SET_FROM_ROUTE = 'setFromRoute'
export const SET_LOCALE_MUTATION = 'setLocale'
export const SET_COUNTER_MUTATION = 'setCounter'
export const SET_QUOTE_TYPE_COUNTER_MUTATION = _.camelCase(
  'SET_QUOTE_TYPE_COUNTER_MUTATION'
)
export const SET_HELP_MUTATION = _.camelCase('SET_HELP_MUTATION')

export const LOADING_GETTER = 'getLoading'
export const INFORMATION_TOAST_GETTER = 'getInformationToast'
export const LOCAL_LOADING_GETTER = 'getLocalLoading'
export const DIALOG_GETTER = 'getDialog'
export const CONFIRMATION_DIALOG_GETTER = 'getConfirmationDialog'
export const QUOTE_STATE_COUNTER_GETTER = 'quoteStateCounter'
export const POLICY_STATE_COUNTER_GETTER = 'policyStateCounter'
export const FROM_ROUTE_GETTER = 'fromRouteGetter'
export const LOCALE_GETTER = 'getLocale'
export const COUNTER_GETTER = 'counterGetter'
export const QUOTE_TYPE_COUNTER_GETTER = _.camelCase(
  'QUOTE_TYPE_COUNTER_GETTER'
)
export const HELP_GETTER = _.camelCase('HELP_GETTER')

export const SHOW_DIALOG_ACTION = 'showDialog'
export const SHOW_INFORMATION_TOAST_ACTION = 'showInformationToast'
export const CLOSE_DIALOG_ACTION = 'closeDialog'
export const HIDE_INFORMATION_TOAST_ACTION = 'hideInformationToast'
export const SHOW_CONFIRMATION_DIALOG_ACTION = 'showConfirmationDialog'
export const CLOSE_CONFIRMATION_DIALOG_ACTION = 'closeConfirmationDialog'
export const GET_QUOTE_STATE_COUNTER_ACTION = 'getQuoteStateCounter'
export const GET_COUNTER_ACTION = 'getCounter'
export const SET_LOCALE_ACTION = 'setLocale'
export const GET_POLICY_STATE_COUNTER_ACTION = 'getPolicyStateCounter'
export const UPDATE_FROM_ROUTE = 'updateFromRoute'
export const GET_QUOTE_TYPE_COUNTER_ACTION = _.camelCase(
  'GET_QUOTE_TYPE_COUNTER_ACTION'
)
export const TOGGLE_HELP_ACTION = _.camelCase('TOGGLE_HELP_ACTION')

export const BaseModule = {
  namespaced: true,

  state: {
    loading: false,
    isLoaderImmutable: false,
    localLoading: false,
    fromRoute: {},

    counter: {},

    quoteStateCounter: null,
    quoteTypeCounter: null,
    policyStateCounter: null,

    informationToast: {
      show: false,
      title: null,
      text: null,
      variant: 'danger',
    },

    dialog: {
      show: false,
      component: null,
      label: null,
      icon: null,
      onClose: null,
      onSuccess: null,
      meta: {},
      class: 'modal-md',
      showExpand: false,
    },

    confirmationDialog: {
      show: false,
      text: null,
      onConfirm: null,
      onCancel: null,
    },

    locale: localStorage.getItem(localeKey) || fallbackLocale,
    help: {
      visible: false,
    },
  },

  mutations: {
    [SET_LOADING_MUTATION]: (state, loading) => {
      if (state.isLoaderImmutable === true) {
        return
      }

      state.loading = loading
    },

    [SET_IMMUTABLE_LOADING_MUTATION]: (state) => {
      state.loading = true
      state.isLoaderImmutable = true
    },

    [RESET_IMMUTABLE_LOADING_MUTATION]: (state) => {
      state.loading = false
      state.isLoaderImmutable = false
    },

    [SET_LOCAL_LOADING_MUTATION]: (state, loading) => {
      state.localLoading = loading
    },

    [SET_DIALOG_MUTATION]: (state, dialog) => {
      state.dialog = dialog
    },

    [SET_INFORMATION_TOAST]: (state, toast) => {
      state.informationToast = toast
    },

    [SET_CONFIRMATION_DIALOG_MUTATION]: (state, dialog) => {
      state.confirmationDialog = dialog
    },

    [RESET_DIALOG_MUTATION]: (state) => {
      state.dialog = {
        show: false,
        component: null,
        label: null,
        icon: null,
        meta: {},
        class: 'modal-md',
        showExpand: false,
      }
    },

    [RESET_INFORMATION_TOAST]: (state) => {
      state.informationToast = {
        show: false,
        title: null,
        text: null,
        variant: 'danger',
      }
    },

    [RESET_CONFIRMATION_DIALOG_MUTATION]: (state) => {
      state.confirmationDialog = {
        show: false,
        text: null,
        onConfirm: null,
        onCancel: null,
      }
    },

    [SET_QUOTE_STATE_COUNTER_MUTATION]: (state, count) => {
      state.quoteStateCounter = count
    },

    [SET_POLICY_STATE_COUNTER_MUTATION]: (state, count) => {
      state.policyStateCounter = count
    },

    [SET_FROM_ROUTE]: (state, fromRoute) => {
      state.fromRoute = fromRoute
    },

    [SET_COUNTER_MUTATION]: (state, counter) => {
      state.counter = counter
    },

    [SET_LOCALE_MUTATION]: (state, locale) => {
      state.locale = locale
    },

    [SET_QUOTE_TYPE_COUNTER_MUTATION]: (state, quoteTypeCounter) => {
      state.quoteTypeCounter = quoteTypeCounter
    },

    [SET_HELP_MUTATION]: (state, data) => {
      state.help = data
    },
  },

  getters: {
    [LOADING_GETTER]: (state) => {
      return state.loading
    },

    [INFORMATION_TOAST_GETTER]: (state) => {
      return state.informationToast
    },

    [LOCAL_LOADING_GETTER]: (state) => {
      return state.localLoading
    },

    [DIALOG_GETTER]: (state) => {
      return state.dialog
    },

    [CONFIRMATION_DIALOG_GETTER]: (state) => {
      return state.confirmationDialog
    },

    [QUOTE_STATE_COUNTER_GETTER]: (state) => {
      return state.quoteStateCounter
    },

    [POLICY_STATE_COUNTER_GETTER]: (state) => {
      return state.policyStateCounter
    },

    [FROM_ROUTE_GETTER]: (state) => {
      return state.fromRoute
    },

    [LOCALE_GETTER]: (state) => {
      return state.locale
    },

    [COUNTER_GETTER]: (state) => {
      return state.counter
    },

    [QUOTE_TYPE_COUNTER_GETTER]: (state) => {
      return state.quoteTypeCounter
    },

    [HELP_GETTER]: (state) => {
      return state.help
    },
  },

  actions: {
    [SHOW_DIALOG_ACTION]: ({ commit }, payload) => {
      payload.show = true
      commit(SET_DIALOG_MUTATION, payload)
    },

    [SHOW_INFORMATION_TOAST_ACTION]: ({ commit }, payload) => {
      payload.show = true
      commit(SET_INFORMATION_TOAST, payload)
    },

    [SHOW_CONFIRMATION_DIALOG_ACTION]: ({ commit }, payload) => {
      payload.show = true
      commit(SET_CONFIRMATION_DIALOG_MUTATION, payload)
    },

    [CLOSE_DIALOG_ACTION]: ({ commit }, payload) => {
      commit(RESET_DIALOG_MUTATION, payload)
    },

    [HIDE_INFORMATION_TOAST_ACTION]: ({ commit }, payload) => {
      commit(RESET_INFORMATION_TOAST, payload)
    },

    [CLOSE_CONFIRMATION_DIALOG_ACTION]: ({ commit }, payload) => {
      commit(RESET_CONFIRMATION_DIALOG_MUTATION, payload)
    },

    [GET_QUOTE_STATE_COUNTER_ACTION]: ({ commit }, state) => {
      axios
        .get(`/quotes/get-state-count/${state}`)
        .then((response) =>
          commit(SET_QUOTE_STATE_COUNTER_MUTATION, response.data.stateCount)
        )
    },

    [GET_QUOTE_TYPE_COUNTER_ACTION]: ({ commit }) => {
      axios
        .get(`/quotes/get-type-count`)
        .then((response) =>
          commit(SET_QUOTE_TYPE_COUNTER_MUTATION, response.data)
        )
    },

    [GET_POLICY_STATE_COUNTER_ACTION]: ({ commit }, state) => {
      axios
        .get(`/policies/get-state-count/${state}`)
        .then((response) =>
          commit(SET_POLICY_STATE_COUNTER_MUTATION, response.data.stateCount)
        )
    },

    [GET_COUNTER_ACTION]: ({ commit }, reference) => {
      axios
        .get(`/counters/${reference}`)
        .then((response) =>
          commit(SET_COUNTER_MUTATION, new CounterModel(response.data.data))
        )
        .catch(() => commit(SET_COUNTER_MUTATION, new CounterModel()))
    },

    [UPDATE_FROM_ROUTE]: ({ commit }, payload) => {
      commit(SET_FROM_ROUTE, payload)
    },

    [SET_LOCALE_ACTION]: ({ commit }, payload) => {
      commit(SET_LOCALE_MUTATION, payload)
    },

    [TOGGLE_HELP_ACTION]: ({ commit, state }, visible) => {
      const helpClone = _.cloneDeep(state.help)
      helpClone.visible = visible
      commit(SET_HELP_MUTATION, helpClone)
    },
  },
}
