import DatasetModel from '@/Modules/Dataset/DatasetModel.js'
import SelectOptionModel from '@/Modules/Dataset/SelectOptionModel.js'
import axios from 'axios'

export default class DatasetService {
  static OPTIONS_LIMIT() {
    return 30
  }
  static listOptionsByTagAndValue = (tag, parameter, value) =>
    axios
      .get(`/datasets/by-value`, { params: { tag, parameter, value } })
      .then((response) =>
        _.map(response.data.data, (item) => new SelectOptionModel(item))
      )

  static list = ({ parameters, filters }) =>
    axios.get(`datasets${filters}`, { params: parameters, excludeLoader: true })

  static newVersion = (datasetId) =>
    axios
      .post(`datasets/${datasetId}/new-version`)
      .then((response) => new DatasetModel(response.data.data))

  static create = (payload) => axios.post(`datasets`, payload)
  static update = (datasetId, payload) =>
    axios.put(`datasets/${datasetId}`, payload)
  static delete = (datasetId) => axios.delete(`datasets/${datasetId}`)
  static show = (datasetId) =>
    axios
      .get(`datasets/${datasetId}`)
      .then((response) => new DatasetModel(response.data.data))

  static byTag = (tag, options) => {
    let pathname = `/datasets/by-tag/${tag}`

    if (!_.isEmpty(options)) {
      const limit = isNaN(options.limit)
        ? DatasetService.OPTIONS_LIMIT()
        : options.limit
      const query = options.q || ''
      pathname = pathname.concat(`?limit=${limit}&q=${query}`)
    }

    return axios
      .get(pathname, {
        excludeLoader: options?.excludeLoader,
      })
      .then((response) =>
        _.map(response.data.data, (item) => new SelectOptionModel(item))
      )
  }

  static byTagWithMeta = (tag, meta, value = undefined) => {
    return axios
      .post(`/datasets/by-tag-with-meta/${tag}`, { meta, value })
      .then((response) =>
        _.map(response.data.data, (item) => new SelectOptionModel(item))
      )
  }
}
