import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const integrationRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('integrations', {
      list: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/List.vue'),
      edit: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Edit.vue'),
      create: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Create.vue'),
    })
      .permissions({
        list: 'integrations.view',
        edit: 'integrations.update',
        create: 'integrations.create',
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
