import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import { TOTAL_CELL } from '@/Modules/Quote/Components/QuoteForm/Elements/UiTypeMoneyTable/MoneyTableCell.js'
import UiTypes from '@/Modules/Quote/Components/QuoteForm/UiTypes.js'

export default class CalculateTable extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    const target = this.resolver.getPathToNode(uiRule.target)
    const baseNode = _.cloneDeep(this.baseNode)
    const targetNode = _.get(
      baseNode,
      `properties.${this.resolver.getPathToNode(uiRule.target)}`
    )

    const sourceNodeKey = this.getTableNodeKey(uiRule, baseNode)

    const sourceNode = _.get(
      baseNode,
      `properties.${this.resolver.getPathToNode(sourceNodeKey)}`
    )

    if (!this.isValidToCalculate(targetNode, sourceNode)) {
      return this.baseNode
    }

    /**
     * Traverse target table properties and access source node with same keys/indexes
     * Could use path to get source table values from baseNode to make less coupled
     */
    targetNode.rows.forEach((row, index) => {
      _.each(row, (property, key) => {
        if (!_.isArray(property)) {
          return
        }

        const sourceProperty = _.get(sourceNode.rows[index], key, [])

        if (!_.isArray(sourceProperty)) return

        property
          .filter((item) => item.id !== TOTAL_CELL)
          .forEach((item, itemIndex) => {
            if (!_.isObject(item?.node) || item.locked) return

            const sourceValue = _.get(sourceProperty[itemIndex], 'node.value')

            const expr = uiRule.parameters.replace(sourceNodeKey, sourceValue)

            item.node.value = this.resolver.evaluateMathExpression(expr)
          })
      })
    })

    _.set(
      this.baseNode,
      `properties.${this.resolver.getPathToNode(target)}`,
      targetNode
    )
    return this.baseNode
  }

  isValidToCalculate(targetNode, sourceNode) {
    if (!_.isObject(targetNode) || !_.isObject(sourceNode)) return false
    if (targetNode.uiType !== UiTypes.moneyTable) {
      console.warn(`Ui rule target must be moneyTable type.`)
      return false
    }
    if (!_.isArray(targetNode.rows) || !_.isArray(sourceNode.rows)) {
      return false
    }
    return true
  }

  /**
   *
   * @param {object} uiRule
   * @param {object} baseNode
   * @returns {string|undefined}
   */
  getTableNodeKey(uiRule, baseNode) {
    if (!_.isString(uiRule.parameters)) return

    return uiRule.parameters
      .split(UiRule.common().operatorsRegexp)
      .map((x) => x && x.trim())
      .find((item) => {
        if (!item) return

        const node = _.get(
          baseNode,
          `properties.${this.resolver.getPathToNode(item)}`
        )

        return node?.uiType === UiTypes.moneyTable
      })
  }
}
