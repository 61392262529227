import _ from 'lodash'

export default class HelpPageModel {
  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  helpPage = null
  name = null
  relatedHelpPages = null
  system = null
  url = null
}
