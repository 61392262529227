import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'

export const videoRoutes = new RouteGroup(() => {
  return [
    new Route('videos.list', '/videos/:tab?', () =>
      import('./Pages/List.vue')
    )
      .permission('videos.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
