import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'

export const appRoutes = [
  { path: '/', redirect: '/home' },
  new Route('home', '/home', () =>
    import('@/Modules/Dashboard/Pages/Dashboard.vue')
  )
    .middlewares([auth])
    .resolve(),
  new Route('not-found', '*', () => import('@/Modules/Base/Pages/NotFound.vue'))
    .middlewares([auth])
    .resolve(),
]
