import SelectOptionModel from '@/Modules/Dataset/SelectOptionModel.js'
import QuoteModel from '@/Modules/Quote/QuoteModel.js'
import axios from 'axios'

export default class QuoteService {
  static list = ({ parameters, filters }) =>
    axios.get(`/quotes${filters}`, { params: parameters, excludeLoader: true })
  static getVersions = (id) =>
    axios
      .get(`/quotes/${id}/versions`, { excludeLoader: true })
      .then((response) =>
        _.map(response.data.data, (item) => new SelectOptionModel(item))
      )
  static preloadNewQuote = (reference) =>
    axios.get(`/quote-render/preload-new-quote`, { params: { reference } })
  static preloadQuote = (id) => axios.get(`/quote-render/${id}`)
  static getSingle = (id) =>
    axios
      .get(`/quotes/${id}/single`)
      .then((response) => new QuoteModel(response.data.data))
  static getStateCount = (state) =>
    axios
      .post(`/quotes/get-state-count`, { state: state })
      .then((response) => response.data)
  static getTabCount = (id, config) =>
    axios
      .get(`/quotes/${id}/get-tabs-count`, config)
      .then((response) => response.data)
  static update = (id, data) => axios.put(`/quotes/${id}`, data)
  static create = (data) => axios.post('/quotes', data)
  static delete = (id) => axios.delete(`/quotes/${id}`)
  static duplicate = (id, data) =>
    axios
      .post(`/quotes/${id}/duplicate`, data)
      .then((response) => new QuoteModel(response.data.data))
  static createMta = (id) =>
    axios
      .post(`/quotes/${id}/create-mta`)
      .then((response) => new QuoteModel(response.data.data))
  static createMtaFromVersion = (id, version) =>
    axios
      .post(`/quotes/${id}/create-mta-from-version`, { version })
      .then((response) => new QuoteModel(response.data.data))
  static createTermination = (id, version) =>
    axios
      .post(`/quotes/${id}/create-termination`, { version })
      .then((response) => new QuoteModel(response.data.data))
  static createTerminationFromVersion = (id, version) =>
    axios
      .post(`/quotes/${id}/create-termination-from-version`, { version })
      .then((response) => new QuoteModel(response.data.data))

  static renderSuperEdit = (id) =>
    axios.get(`/quote-render/${id}/render-super-edit`, { excludeLoader: true })

  static renderQuote = (id) =>
    axios.get(`/quote-render/${id}/render`, { excludeLoader: true })

  static renderNewQuote = (reference) =>
    axios.get('/quote-render/render-new-quote', {
      params: { reference },
      excludeLoader: true,
    })

  static renderPreviewQuote = (schema_id) =>
    axios.get(`/quote-render/render-preview/${schema_id}`, {
      excludeLoader: true,
    })

  static updateStatus = (id, status) =>
    axios.put(`quotes/${id}/change-status`, { status })

  static executeIntegration = (id, integrationOptions, options) =>
    axios
      .post(
        `/quotes/${id}/api-integrations/execute`,
        integrationOptions,
        options
      )
      .catch((error) => {
        return Promise.reject(error)
      })

  static updateMeta = (id, meta) =>
    axios.put(`/quotes/${id}/update-meta`, meta, { excludeLoader: true })

  static getTypeCount = () =>
    axios.post(`/quotes/get-type-count`).then((response) => response.data)

  static updatePartial = (id, data) =>
    axios.put(`/quotes/${id}/update-partial-form`, data)

  static renderPartialForm = (id) =>
    axios.get(`/quote-render/${id}/render-partial-form`)

  static completePartial = (id, data) =>
    axios.put(`/quotes/${id}/complete-partial-form`, data)
}
