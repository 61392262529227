import ClientModel from '@/Modules/Client/ClientModel.js'
import axios from 'axios'

export default class ClientService {
  static list = ({ parameters, filters }) =>
    axios.get(`/clients${filters}`, { params: parameters, excludeLoader: true })

  static create = (payload) =>
    axios
      .post(`/clients`, payload)
      .then((response) => new ClientModel(response.data.data))

  static update = (clientId, payload) =>
    axios
      .put(`/clients/${clientId}`, payload)
      .then((response) => new ClientModel(response.data.data))

  static partialUpdate = (clientId, payload) =>
    axios
      .patch(`/clients/${clientId}`, payload)
      .then((response) => new ClientModel(response.data.data))

  static delete = (clientId) => axios.delete(`/clients/${clientId}`)

  static updateStatus = (clientId, payload) =>
    axios.put(`/clients/${clientId}/update-status`, payload)

  static show = (clientId) =>
    axios.get(`clients/${clientId}`).then((response) => response.data)

  static generateCode = () => axios.get('clients/code')

  static lookUpClient = ({ query, agencyCode = null, branchCode = null }) =>
    axios
      .get('/lookup/clients', {
        params: {
          query,
          agencyCode,
          branchCode,
        },
        excludeLoader: true,
      })
      .then((response) => response.data)

  static updateData = (clientId, data) =>
    axios.put(`clients/${clientId}/update-data`, data)
}
