
<div
  v-if="visible"
  :id="name"
  :class="classColumn"
  class="mb-5 json-editor-field"
>
  <div class="d-flex align-items-center justify-content-between">
    <TraceLabel
      :node="quoteNode"
      :label="label"
      :required="required"
      :name="name"
    />
    <FieldHint v-if="hint" html :text="hint" />
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <VJsoneditor
    :id="id || dotKey"
    v-model="model"
    v-validate="validation"
    :value="value"
    :name="name"
    :data-vv-name="dotKey"
    :readonly="readonly"
    :class="{
      [customClass]: !!customClass,
      readonly: readonly,
      'is-invalid': hasErrors,
      [`is-${highlightClass}`]: !!highlightClass,
    }"
    :height="height"
    :options="{
      search: false,
      mode: 'code',
      indentation: 2,
    }"
    :plus="fullScreenEnabled"
    @error="$emit('error')"
    @input="emitChange"
  />

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>
</div>
