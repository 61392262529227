import _ from 'lodash'

export default class CheckboxOptionModel {
  value = null
  checked = null
  selected = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  static findOption(options, option) {
    if (_.isArray(options) && _.isObject(option)) {
      return _.find(
        options,
        item => item?.value?.toString() === option.value.toString()
      )
    }
  }
}
