import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const activityRoutes = new RouteGroup(() => {
  return [
    new Route('activities.list', '/activities/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('activities.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
