<template>
  <b-button
    :id="id"
    v-b-tooltip.manual
    :title="__('badges.copy_number')"
    :class="customClass"
    variant="link"
    size="small"
    @click="copyToClipboard(content, id)"
  >
    <Icon icon="copy" class="mr-2" size="22" style="color: #6e84a3" />
  </b-button>
</template>

<script>
export default {
  name: 'CopyButton',

  props: {
    id: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    autoHideDelay: {
      type: Number,
      default: 500,
    },
  },

  methods: {
    async copyToClipboard(data, id) {
      this.$copyText(data)
      this.$root.$emit('bv::show::tooltip', id)
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, this.autoHideDelay)
    },
  },
}
</script>
