
<stack-modal
  :show="dialog.show"
  :save-button="{ visible: false }"
  :cancel-button="{ visible: false }"
  :modal-class="{
    [dialog.class]: !!dialog.class,
  }"
>
  <template slot="modal-header">
    <div class="modal-header align-items-center">
      <span>{{ __('modal.activity-header') }}</span>
      <a
        class="modal-close"
        href="#"
        @click.prevent="closeActivityMonitorModal"
      >
        <Icon icon="times" size="18" />
      </a>
    </div>
  </template>
  <div class="d-flex align-items-center justify-content-center">
    <Icon icon="exclamation-octagon" class="text-danger mr-4" size="32" />
    <p class="p-0 m-0">
      {{ __('modal.activity-text', { sessionTimeLeft }) }}
    </p>
  </div>
  <template slot="modal-footer">
    <div class="p-4 d-flex align-items-center justify-content-end">
      <b-button
        class="mr-3"
        variant="outline-danger"
        size="sm"
        @click="closeModalAndLogout"
      >
        {{ __('buttons.logout') }}
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        @click="closeActivityMonitorModal"
      >
        {{ __('modal.button-session') }}
      </b-button>
    </div>
  </template>
</stack-modal>
