import EntityModel from '@/Modules/Entity/EntityModel.js'
import ProductModel from '@/Modules/Product/ProductModel.js'
import WorkflowModel from '@/Modules/Workflow/WorkflowModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export default class SchemaModel {
  id = null
  reference = null
  nameWithVersion = null
  updatedAt = null
  status = null
  name = null
  tag = null
  productId = null
  coverholderId = null
  version = 1
  quoteNumbering = '{NNNNN}'
  policyNumbering = null
  changeNotes = null
  isRenewable = false
  termination = false
  renewalOptions = this.getRenewalOptionsStub()
  useDefaultWorkflow = true
  index = 0
  description = null
  canCancel = false
  canEdit = false
  canDelete = false
  canPublish = false
  previousId = null
  createdAt = null
  isNewVersionAvailable = false
  summary = null
  badge = null
  image = null

  coverholder = {}
  product = {}
  workflow = {}
  previousSchema = {}

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
    this.coverholder = data.coverholder && new EntityModel(data.coverholder)
    this.product = data.product && new ProductModel(data.product)
    this.workflow = data.workflow && new WorkflowModel(data.workflow)
    this.previousSchema =
      data.previous_schema && new SchemaModel(data.previous_schema)
  }

  statusData() {
    switch (this.status) {
      case 'draft':
        return {
          label: i18n.tc(`statuses.draft`),
          badge: 'soft-secondary',
        }
      case 'published':
        return {
          label: i18n.tc(`statuses.published`),
          badge: 'soft-success',
        }
      case 'canceled':
        return {
          label: i18n.tc(`statuses.canceled`),
          badge: 'soft-danger',
        }
    }
  }

  getRenewalOptionsStub() {
    return {
      renewal_default_expiry_period: {
        years: 1,
        months: 0,
        days: 0,
      },
      renewal_default_inception_date: {
        years: 0,
        months: 0,
        days: 1,
      },
      renewal_start_before_expiry: {
        years: 0,
        months: 1,
        days: 0,
      },
      renewal_keep_same_policy_number: false,
      renewal_keep_same_quote_number: false,
    }
  }
}
