
<currency-field
  v-model="model"
  :show-error="showError"
  :precision="precision"
  v-bind="$props"
  @input=";[emitInput($event), $emit('onChange', $event)]"
>
  <template slot="append">
    <select
      v-model="currencyModel"
      name="currency_code"
      data-testid="currency-code"
      :class="{
        'form-control': true,
        readonly: readonly,
        'is-invalid': hasErrors || currencyHasError,
      }"
      :disabled="readonly"
      style="max-width: 70px; padding-left: 5px; padding-right: 0px"
    >
      <option
        v-for="(option, key) in currencies"
        :key="key"
        :value="option.value"
      >
        {{ option.label.toUpperCase() }}
      </option>
    </select>
  </template>
</currency-field>
