import DatasetService from '@/Modules/Dataset/DatasetService.js'
import UiTypes from '@/Modules/Quote/Components/QuoteForm/UiTypes.js'

export default {
  methods: {
    loadOptionsIfNotLoaded(datasetTag) {
      if (datasetTag === undefined || this.node.options.length > 1) {
        return
      }

      const nodePath = this.getNodePath()

      const dependableNode = this.baseNode.dependables[nodePath]

      if (dependableNode) {
        let value
        // Say multipleSection source is unreliable in baseNode.dependables and value should be taken from baseNode again!
        if (
          this.index &&
          dependableNode.source &&
          dependableNode.source.match(/\.{\d}\./)
        ) {
          const sourceNodePath = dependableNode.source.replace(
            /\.{\d}\./,
            `.value.${this.index}.`
          )
          const nodeValue = _.get(this.baseNode.properties, sourceNodePath)
          value = nodeValue
        }

        if (!this.index && _.has(dependableNode, 'value')) {
          value = dependableNode.value
        }

        if (!value) {
          return
        }

        DatasetService.listOptionsByTagAndValue(
          dependableNode.options,
          dependableNode.parameter,
          value
        ).then((items) => {
          this.node.options = items
        })

        return
      }

      const quoteId = this.$router.currentRoute?.params?.quoteId
      if (quoteId) {
        DatasetService.byTagWithMeta(datasetTag, {
          quote_id: quoteId,
        }).then((items) => {
          this.node.options = _.unionBy(this.node.options, items, 'value')
          this.node.value = items[0]
        })
      } else {
        DatasetService.byTag(datasetTag).then((items) => {
          this.node.options = _.unionBy(this.node.options, items, 'value')
        })
      }
    },

    getNodePath() {
      if (!this.node.nodePath) {
        return
      }

      if (this.node.parentUiType === UiTypes.multipleSection) {
        let nodePath = this.node.nodePath.replace(
          this.node.parentKey,
          `${this.node.parentKey}.{0}`
        )

        return nodePath
      }

      const sectionNodeKey = _.first(this.node.nodePath.split('.'))

      const node = _.get(this.baseNode.properties, sectionNodeKey)

      if (node?.uiType === UiTypes.multipleSection) {
        let nodePath = this.node.nodePath.replace(
          sectionNodeKey,
          `${sectionNodeKey}.{0}`
        )

        return nodePath
      }

      return this.node.nodePath
    },
  },
}
