<template>
  <div
    v-if="visible"
    :id="id"
    :class="{ 'form-group': true, [customClass]: !!customClass }"
  >
    <div class="d-flex align-items-center justify-content-between">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
      <slot name="labelRight"></slot>
      <FieldHint v-if="hint" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div class="input-group" :class="{ 'input-group-merge': !!icon }">
      <input
        :id="id || dotKey"
        ref="input"
        v-model="model"
        v-validate="validation"
        v-b-tooltip.hover="{
          html: true,
          customClass: tooltipClass,
          title: tooltip,
        }"
        :data-vv-name="dotKey"
        :type="type"
        v-bind="$attrs"
        :name="name"
        :step="step"
        :precision="precision"
        :data-mask="datamask"
        :placeholder="placeholder"
        :disabled="readonly"
        :autofill="autofill"
        :class="{
          'form-control': true,
          'form-control-appended': !!icon,
          readonly: readonly,
          'is-invalid': hasErrors,
          [inputClass]: !!inputClass,
          [`is-${highlightClass}`]: !!highlightClass,
        }"
        @input="emitChange"
        @blur="handleInputBlur"
        @keydown="onKeyDown"
      />
      <div v-if="icon" class="input-group-append">
        <span :class="{ 'input-group-text': icon, readonly: readonly }">
          <i :class="icon"></i>
        </span>
      </div>

      <slot name="append"></slot>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import applyFastNumber, {
  isFastNumberKeyInput,
} from '@/General/applyFastNumber.js'

export default {
  name: 'TextField',

  mixins: [InputComponent],

  data: function () {
    return {
      previousValue: null,
    }
  },

  methods: {
    emitChange(event) {
      const value = event?.target?.value
      const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
      const shouldSkip =
        this.fastInput && event.inputType === 'insertText' && value === ''
      if (isFirefox && shouldSkip) {
        // FIREFOX ONLY ISSUE - QS-5620
        event.target.value = this.previousValue
        this.model = this.previousValue
        return
      }

      this.$emit('input', value)
      this.$emit('onChange', value)
      this.$emit('change', event)
      this.$emit('changeDynamicFieldValue', value)
    },

    handleInputBlur(event) {
      const value =
        isNaN(this.min) && isNaN(this.max)
          ? event?.target?.value
          : this.applyMinMax(event?.target?.value)
      this.model = value
      this.$emit('onBlur', value)
    },

    onKeyDown(event) {
      if (!this.fastInput || isNaN(this.model)) {
        return
      }
      this.previousValue = this.model

      const key = event.key.toLowerCase()

      if (!isFastNumberKeyInput(key)) {
        return
      }

      const newValue = applyFastNumber(this.model, key)

      if (isNaN(newValue)) {
        return
      }

      this.model = newValue
      this.emitChange({ target: { value: newValue } })
      this.previousValue = newValue
    },
  },
}
</script>
