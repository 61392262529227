import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import moment from 'moment'

export default class ParsePersonalCodeLt extends UiRuleAction {
  constructor(baseNode, dateFormat) {
    super(baseNode, new ConditionResolver(baseNode))
    this.dateFormat = dateFormat
    this.data = {
      birthDate: null,
      gender: null,
    }
  }

  static common() {
    return {
      personalNumberRegExp: new RegExp(/^(\d{1})(\d{2})(\d{2})(\d{2})\d*$/),
      defaultUnit: 'birthDate',
      GENDER_INDEX: 0,
      MALE: 'Male',
      FEMALE: 'Female',
      GENDER_CODE_MALE: 3,
      GENDER_CODE_FEMALE: 4,
      GENDER_CODE_MALE_21: 5,
      GENDER_CODE_FEMALE_21: 6,
      DATE_FORMAT: 'YY-MM-DD',
    }
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    const [unit] = this.resolver.parseParameters(uiRule.parameters) || []
    const sourceValue = this.resolver.getValueByRuleOrSource(uiRule)
    const parsedCodeMatch = sourceValue.match(
      ParsePersonalCodeLt.common().personalNumberRegExp
    )
    const parsedCode = parsedCodeMatch && parsedCodeMatch.slice(1)

    if (parsedCode) {
      const genderCode = Number(
        parsedCode[ParsePersonalCodeLt.common().GENDER_INDEX]
      )
      let twoDigitYearBase = 2000
      const birthDate = this.getBirthdate(parsedCode)

      switch (genderCode) {
        case ParsePersonalCodeLt.common().GENDER_CODE_MALE_21:
          twoDigitYearBase = 2000
          break
        case ParsePersonalCodeLt.common().GENDER_CODE_FEMALE_21:
          twoDigitYearBase = 2000
          break
        case ParsePersonalCodeLt.common().GENDER_CODE_FEMALE:
          twoDigitYearBase = 1900
          break
        case ParsePersonalCodeLt.common().GENDER_CODE_MALE:
          twoDigitYearBase = 1900
          break
        default:
          break
      }
      moment.parseTwoDigitYear = function(yearString) {
        return parseInt(yearString) + twoDigitYearBase
      }

      if (
        moment(birthDate, ParsePersonalCodeLt.common().DATE_FORMAT).isValid() &&
        moment(birthDate, ParsePersonalCodeLt.common().DATE_FORMAT).isBefore(
          new Date()
        )
      ) {
        this.data.birthDate = moment(
          birthDate,
          ParsePersonalCodeLt.common().DATE_FORMAT
        ).format(this.dateFormat)
        this.data.gender = this.extractGenderFromCode(genderCode)
      }
    }

    const newValue = unit
      ? this.data[unit]
      : this.data[ParsePersonalCodeLt.common().defaultUnit]

    this.resolver.setValueToNode(uiRule.target, newValue, uiRule.trigger)

    return this.baseNode
  }

  extractGenderFromCode(code) {
    if (
      [
        ParsePersonalCodeLt.common().GENDER_CODE_MALE,
        ParsePersonalCodeLt.common().GENDER_CODE_MALE_21,
      ].includes(code)
    ) {
      return ParsePersonalCodeLt.common().MALE
    }
    if (
      [
        ParsePersonalCodeLt.common().GENDER_CODE_FEMALE,
        ParsePersonalCodeLt.common().GENDER_CODE_FEMALE_21,
      ].includes(code)
    ) {
      return ParsePersonalCodeLt.common().FEMALE
    }
    return ''
  }

  getBirthdate(parsedCode) {
    const date = parsedCode
      .reduce((acc, currentValue, currentIndex) => {
        if (currentIndex !== ParsePersonalCodeLt.common().GENDER_INDEX) {
          acc.push(currentValue)
        }
        return acc
      }, [])
      .toString()
      .replace(/,/g, '-')

    return date
  }
}
