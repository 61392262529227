<template>
  <div v-if="isEnabled">
    <img
      id="language-popover-button"
      :alt="locale"
      :src="getLanguageIcon(locale)"
      width="20"
    />

    <b-popover
      custom-class="language-popover"
      placement="bottom"
      target="language-popover-button"
      triggers="hover"
      variant="info"
    >
      <b-list-group class="my-n3" flush>
        <b-list-group-item>
          <b-row
            v-for="language in languages"
            :key="language.name"
            class="align-items-center mb-2"
          >
            <b-col class="d-flex align-items-center">
              <img
                :alt="language.languageCode"
                :src="getLanguageIcon(language.languageCode)"
                class="mr-2"
                width="16"
              />

              <span
                :class="`cursor-pointer language ${
                  locale === language.languageCode ? 'active' : ''
                }`"
                @click="changeLocale(language)"
                v-text="language.name"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
import { getTranslations, setLocale } from '@/Setup/SetupI18n.js'
import TranslationLibraryService from '@/Modules/TranslationLibrary/TranslationLibraryService.js'
import { LOCALE_GETTER, SET_LOCALE_ACTION } from '@/Modules/Base/BaseModule.js'
import { mapGetters } from 'vuex'
import ProfileService from '@/Modules/Profile/ProfileService.js'

const localeIcons = {
  ar_AE: () => import('@/Assets/images/icons/languages/ar_AE.png'),
  da_DK: () => import('@/Assets/images/icons/languages/da_DK.png'),
  de_AT: () => import('@/Assets/images/icons/languages/de_AT.png'),
  de_DE: () => import('@/Assets/images/icons/languages/de_DE.png'),
  default: () => import('@/Assets/images/icons/languages/default.png'),
  en_CA: () => import('@/Assets/images/icons/languages/en_CA.png'),
  en_US: () => import('@/Assets/images/icons/languages/en_US.png'),
  es_ES: () => import('@/Assets/images/icons/languages/es_ES.png'),
  et_EE: () => import('@/Assets/images/icons/languages/et_EE.png'),
  fi_FI: () => import('@/Assets/images/icons/languages/fi_FI.png'),
  fr_CA: () => import('@/Assets/images/icons/languages/fr_CA.png'),
  fr_FR: () => import('@/Assets/images/icons/languages/fr_FR.png'),
  he_IL: () => import('@/Assets/images/icons/languages/he_IL.png'),
  it_IT: () => import('@/Assets/images/icons/languages/it_IT.png'),
  lt_LT: () => import('@/Assets/images/icons/languages/lt_LT.png'),
  lv_LV: () => import('@/Assets/images/icons/languages/lv_LV.png'),
  nb_NO: () => import('@/Assets/images/icons/languages/nb_NO.png'),
  pl_PL: () => import('@/Assets/images/icons/languages/pl_PL.png'),
  pt_PT: () => import('@/Assets/images/icons/languages/pt_PT.png'),
  ro_RO: () => import('@/Assets/images/icons/languages/ro_RO.png'),
  ru_RU: () => import('@/Assets/images/icons/languages/ru_RU.png'),
  sv_SE: () => import('@/Assets/images/icons/languages/sv_SE.png'),
  uk_UA: () => import('@/Assets/images/icons/languages/uk_UA.png'),
}

export default {
  name: 'LanguagePopover',

  data() {
    return {
      languages: [],
      enabled: true,
      iconSrcs: {},
    }
  },

  computed: {
    ...mapGetters('BaseModule', {
      locale: LOCALE_GETTER,
    }),

    isEnabled: function () {
      return this.enabled && this.languages.length > 0
    },
  },

  created: async function () {
    this.preloadLanguageIcons()
    this.languages = await TranslationLibraryService.assigned()

    const translations = getTranslations()

    this.languages = _.filter(this.languages, (language) =>
      _.keys(translations).includes(language.languageCode)
    )
  },

  methods: {
    async preloadLanguageIcons() {
      for (const locale in localeIcons) {
        try {
          const module = await localeIcons[locale]()
          this.$set(this.iconSrcs, locale, module.default)
        } catch (e) {
          console.error('Error loading language icon:', e)
        }
      }
    },
    changeLocale(language) {
      const locale = language.languageCode

      if (!locale) {
        return
      }

      if (this.$authCheck() && this.$route.name !== 'quotes-partial.show') {
        this.$showConfirmationModal(
          this.__('translations.change-confirmation'),
          () => {
            localStorage.setItem('locale', locale)

            ProfileService.changeLocale(locale).then(() => location.reload())
          }
        )

        return
      }

      setLocale(locale)

      this.$store.dispatch(`BaseModule/${SET_LOCALE_ACTION}`, locale)
    },

    getLanguageIcon(locale) {
      return this.iconSrcs[locale] || this.iconSrcs['default']
    },

    isActive(languageCode) {
      return this.locale === languageCode
    },
  },
}
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.language {
  &.active {
    color: #2c7be5;
  }
}
</style>
