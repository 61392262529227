
<b-row align-content="end" class="align-items-end">
  <b-col>
    <h6 v-if="preTitle" class="header-pretitle">
      {{ preTitle }}
    </h6>

    <h1 class="header-title">
      {{ title }} {{ extraTitle }}
      <CopyButton
        v-if="showCopyIcon"
        :id="`item-key-${extraTitle}`"
        class="copy-button"
        :content="`${extraTitle}`"
      ></CopyButton>
      <span
        style="font-size: 0.9375rem; vertical-align: middle"
        data-testid="page-title-badge"
        class="ml-0"
      >
        <slot name="afterTitle" />
      </span>
    </h1>
    <small v-show="underTitle" class="text-muted pt-2" v-html="underTitle" />
    <div v-if="$scopedSlots.meta">
      <slot name="meta" />
    </div>
  </b-col>

  <b-col cols="auto">
    <slot />
  </b-col>

  <b-col v-if="$scopedSlots.actions" cols="auto" class="ml-n3 actions-height">
    <slot name="actions"></slot>
  </b-col>
</b-row>
