
<span v-if="showTooltip">
  <span :id="id">
    {{ truncateText }}
  </span>
  <b-tooltip :target="id" triggers="hover" :boundary="boundary">
    {{ tooltipContent || text }}
  </b-tooltip>
</span>
<span v-else>
  {{ text }}
</span>
