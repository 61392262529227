import dataHelpers from '@/helpers/dataHelpers.js'
import Icon from '@/General/Icon.vue'
import _ from 'lodash'
import store from '@/Setup/SetupStore.js'
import { AUTH_USER_GETTER } from '@/Modules/Auth/AuthModule.js'
import UserModel from '@/Modules/User/UserModel.js'
import { SETTINGS_GETTER } from '@/Modules/Base/SettingModule.js'
import applyNumberFormatting from '@/General/applyNumberFormatting.js'
import {
  FROM_ROUTE_GETTER,
  SHOW_CONFIRMATION_DIALOG_ACTION,
  SHOW_DIALOG_ACTION,
} from '@/Modules/Base/BaseModule.js'
import { prettyPrintJson } from 'pretty-print-json'

export default {
  install(Vue) {
    Vue.component('Icon', Icon)

    Vue.prototype.$authCheck = function () {
      return !!localStorage.getItem('auth_token')
    }

    Vue.prototype.__ = function (path, values) {
      return this.$t(path, values)
    }

    Vue.prototype.tc = function (path, choice, values) {
      return this.$tc(path, choice, values)
    }

    Vue.prototype.dataHelpers = dataHelpers
    Vue.dataHelpers = dataHelpers

    // Helper to easily set validation errors from response.
    Vue.prototype.setErrorsFromResponse = function (error) {
      this.errors.clear()

      if (error?.response?.status === 422) {
        let errors = error.response.data.errors
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : 'You have entered invalid data!'
        _.each(errors, (error, field) => {
          if (_.isObject(error[0])) {
            this.errors.add({
              field,
              msg: error[0].message,
              level: error[0].level,
            })
            return
          }
          this.errors.add({ field, msg: error[0] })
        })

        this.$errorToast(errorMessage)

        this.scrollToErrors()
      }
    }

    Vue.prototype.authUser = function () {
      return store.getters[`AuthModule/${AUTH_USER_GETTER}`] || new UserModel()
    }

    Vue.prototype.setting = function (key, defaultValue) {
      const settings = store.getters[`SettingModule/${SETTINGS_GETTER}`]

      return (
        _.find(settings, (setting) => setting.key === key)?.value ||
        defaultValue
      )
    }

    Vue.prototype.applyFormatting = function (value, currency) {
      const locale = Vue.prototype.setting('formatting_value')

      return applyNumberFormatting(value, locale, currency)
    }

    Vue.prototype.getCanonicalLocale = function () {
      return this.setting('formatting_locale')?.replaceAll('_', '-') || 'en-US'
    }

    Vue.prototype.scrollToErrors = function () {
      const [element] =
        (this.errors &&
          this.errors.items &&
          this.errors.items
            .map((item) => {
              return document.getElementById(item.field)
            })
            .filter((item) => !!item)) ||
        []

      element &&
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
    }

    Vue.prototype.$showConfirmationModal = function (
      text,
      onConfirm,
      onCancel = null
    ) {
      this.$store.dispatch(`BaseModule/${SHOW_CONFIRMATION_DIALOG_ACTION}`, {
        onCancel,
        onConfirm,
        text,
      })
    }

    Vue.prototype.$selectValue = function (from, key) {
      return (from[key] && from[key].value) || from[key]
    }

    Vue.prototype.showModal = function (parameters) {
      this.$store.dispatch(`BaseModule/${SHOW_DIALOG_ACTION}`, parameters)
    }

    Vue.prototype.$successToast = function (
      text,
      title = this.$t('toast.success')
    ) {
      this.$bvToast.toast(text, {
        title: title,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
    }

    Vue.prototype.$infoToast = function (text, title = this.$t('toast.info')) {
      this.$bvToast.toast(text, {
        title: title,
        variant: 'info',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
    }

    Vue.prototype.$errorToast = function (
      text,
      title = this.$t('toast.error'),
      options = {}
    ) {
      this.$bvToast.toast(text, {
        title: title,
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: options.autoHideDelay,
      })
    }

    Vue.prototype.$navigate = function (name, params, query) {
      return this.$router.push({ name, params, query }).catch(() => {})
    }

    Vue.prototype.$navigateBack = function () {
      const fromRoute = store.getters[`BaseModule/${FROM_ROUTE_GETTER}`]
      if (fromRoute && fromRoute.name) {
        this.$router.back()
        return
      }
      if (this.$route?.meta?.back) {
        this.$router
          .push({
            name: this.$route.meta.back,
            params: this.$router.params,
          })
          .catch(() => {})
        return
      }
      this.$router.push({ name: 'home' }).catch(() => {})
    }

    Vue.prototype.$can = function (permission) {
      const user = store.getters['AuthModule/' + AUTH_USER_GETTER]

      return user && user.permissions.includes(permission)
    }

    Vue.prototype.prettyPrintJson = prettyPrintJson

    Vue.prototype.handleResourceError = function (error, to = {}) {
      if (_.isEmpty(error?.message)) {
        return
      }
      if (error.message.match(/404/i)) {
        this.$router
          .push({ name: 'not-found', params: [to.path] })
          .catch(() => {})
      }
    }

    // Directives
    Vue.directive('can', {
      inserted(el, binding, vnode) {
        if (!vnode.elm.parentElement) {
          return
        }
        const user = store.getters['AuthModule/' + AUTH_USER_GETTER]
        const argument = binding.rawName.slice(6)

        if (user && !user.permissions.includes(argument)) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        }
      },
    })

    Vue.directive('can-dynamic', {
      inserted(el, binding, vnode) {
        if (!vnode.elm.parentElement) {
          return
        }
        const user = store.getters['AuthModule/' + AUTH_USER_GETTER]
        if (user && !user.permissions.includes(binding.arg)) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        }
      },
    })

    // Directives
    Vue.directive('setting', {
      inserted(el, binding, vnode) {
        const settingValue = Vue.prototype.setting(binding.arg)

        if (settingValue === undefined) {
          return
        }

        if (
          (binding.value === undefined &&
            (!settingValue || !Boolean(Number(settingValue)))) ||
          (binding.value && binding.value !== settingValue)
        )
          vnode.elm.parentElement.removeChild(vnode.elm)
      },
    })

    Vue.directive('is-supervisor', {
      inserted(el, binding, vnode) {
        const user = store.getters['AuthModule/' + AUTH_USER_GETTER]

        if (!user.isSupervisor) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        }
      },
    })

    Vue.directive('can-any', {
      inserted(el, binding, vnode) {
        const user = store.getters['AuthModule/' + AUTH_USER_GETTER]

        if (
          _.isArray(binding.value) &&
          binding.value.length > 0 &&
          !binding.value.some((item) => user && user.permissions.includes(item))
        ) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        }
      },
    })

    Vue.directive('click-outside', {
      bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {
          if (!(element === event.target || element.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', element.clickOutsideEvent)
      },
      unbind: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
      },
    })
  },
}
