import { PRIMARY_CURRENCY_GETTER } from '@/Modules/Base/SettingModule.js'
import ClientModel from '@/Modules/Client/ClientModel.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import SchemaModel from '@/Modules/Schema/SchemaModel.js'
import UserModel from '@/Modules/User/UserModel.js'
import store from '@/Setup/SetupStore.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'
import BinderModel from '@/Modules/Binder/BinderModel.js'
import QuoteDuplicateModel from '@/Modules/Quote/QuoteDuplicateModel.js'

export const TYPE = Object.freeze({
  NEW: 'new',
  ADJUSTMENT: 'adjustment',
  TERMINATION: 'termination',
  RENEWAL: 'renewal',
})

export default class QuoteModel {
  id = null
  number = null
  policyNumber = null
  client = null
  clientCode = null
  clientTaxId = null
  clientAddress = null
  entityId = null
  canRenew = false
  canCreateMta = false
  canCreateTermination = false
  canCreateFromVersion = false
  canSuperEdit = false
  premium = null
  premiumWithCurrency = null
  limitWithCurrency = null
  type = TYPE.NEW
  inceptionDate = null
  expiryDate = null
  status = {}
  isPolicy = false
  currencyCode = null
  binderId = null
  agentId = null
  renewedId = null
  renewed = null
  primaryQuote = null

  user = null
  schema = null
  quoteClient = null
  entity = null
  binder = null
  agent = null
  duplicate = null
  duplicatedFrom = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.user = data.user && new UserModel(data.user)
    this.schema = data.schema && new SchemaModel(data.schema)
    this.quoteClient = data.quoteClient && new ClientModel(data.quoteClient)
    this.entity = data.entity && new EntityModel(data.entity)
    this.binder = data.binder && new BinderModel(data.binder)
    this.agent = data.agent && new EntityModel(data.agent)
    this.duplicate = data.duplicate && new QuoteDuplicateModel(data.duplicate)
    this.duplicatedFrom =
      data.duplicated_from && new QuoteDuplicateModel(data.duplicated_from)

    if (!this.currencyCode) {
      this.currencyCode =
        store.getters[`SettingModule/${PRIMARY_CURRENCY_GETTER}`]
    }
  }

  typeData() {
    switch (this.type) {
      case TYPE.NEW:
        return {
          label: i18n.tc('statuses.new'),
          badge: 'soft-info',
          icon: 'file-alt',
        }
      case TYPE.ADJUSTMENT:
        return {
          label: i18n.tc('statuses.adjustment'),
          badge: 'soft-info',
          icon: 'file-plus',
        }
      case TYPE.TERMINATION:
        return {
          label: i18n.tc('statuses.termination'),
          badge: 'soft-secondary',
          icon: 'file-minus',
        }
      case TYPE.RENEWAL:
        return {
          label: i18n.tc('statuses.renewal'),
          badge: 'soft-warning',
          icon: 'file',
        }
    }
  }

  statusValue = (key = 'name') => _.get(this.status || {}, key)

  static typesList() {
    return TYPE
  }

  static types() {
    return [
      {
        label: i18n.tc('statuses.new'),
        value: TYPE.NEW,
      },
      {
        label: i18n.tc('statuses.adjustment'),
        value: TYPE.ADJUSTMENT,
      },
      {
        label: i18n.tc('statuses.termination'),
        value: TYPE.TERMINATION,
      },
      {
        label: i18n.tc('statuses.renewal'),
        value: TYPE.RENEWAL,
      },
    ]
  }
}
