export const TAB_COUNTS_GETTER = 'tabCounts'

export const SET_TAB_COUNTS = 'setTabCounts'

export const INCREASE_TAB_COUNT = 'increaseTabCount'
export const DECREASE_TAB_COUNT = 'decreaseTabCount'

export const StatementModule = {
  namespaced: true,

  state: {
    tabCounts: {},
  },

  mutations: {
    [SET_TAB_COUNTS]: (state, tabCounts) => {
      state.tabCounts = tabCounts
    },
  },

  getters: {
    [TAB_COUNTS_GETTER]: state => {
      return state.tabCounts
    },
  },

  actions: {
    [INCREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) + 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [DECREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) - 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },
  },
}
