import router from '@/Setup/Routes/SetupRouter.js'
import VueSpinners from '@saeris/vue-spinners'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import SetupCommonPlugins from '@/Setup/SetupCommonPlugins.js'
import * as packageJson from '../../package.json'
if (moment) {
  moment.locale(localStorage.getItem('locale'))
}
const packageVersion = packageJson.version

if (
  import.meta.env.NODE_ENV !== 'test' &&
  import.meta.env.MIX_SENTRY_DSN_PUBLIC
) {
  Sentry.init({
    Vue: Vue,
    dsn: import.meta.env.MIX_SENTRY_DSN_PUBLIC,
    debug: import.meta.env.NODE_ENV !== 'production',
    release: packageVersion,
    environment:
      import.meta.env.MIX_SENTRY_ENVIRONMENT || import.meta.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  })

  Sentry.setTag('website_side', 'frontend')
}

Vue.use(VueSpinners)

// Axios setup
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = '/api'
import '@/Setup/SetupInterceptors'

// Include all the global components
Vue.use(BootstrapVue)
Vue.use(SetupCommonPlugins)

import '@/Setup/SetupI18n'

Vue.use(VueClipboard)

export const version = packageVersion
