<template>
  <div v-if="showMore">
    <div class="d-inline" v-html="expanded ? html : truncateText" />
    <b-button
      variant="link"
      size="sm"
      class="ml-2"
      @click="handleShowMoreClick()"
    >
      {{ expanded ? __('common.show-less') : __('common.show-more') }}
    </b-button>
  </div>
  <div v-else>
    <div v-html="html" />
  </div>
</template>

<script>
export default {
  name: 'ShowMoreText',

  props: {
    html: {
      type: String,
      default: '',
    },
    maxChar: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    showMore() {
      return typeof this.html === 'string' && this.html.length > this.maxChar
    },
    truncateText() {
      if (this.html.length > this.maxChar) {
        return this.html.slice(0, this.maxChar) + '…'
      }
      return this.html
    },
  },

  methods: {
    handleShowMoreClick(ev) {
      this.expanded = !this.expanded
    },
  },
}
</script>