import ClientAddressModel from '@/Modules/Client/ClientAddress/ClientAddressModel.js'
import ClientContactModel from '@/Modules/Client/ClientContact/ClientContactModel.js'
import ClientOccupationModel from '@/Modules/Client/ClientOccupation/ClientOccupationModel.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import UserModel from '@/Modules/User/UserModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'
import ClientRelationshipModel from '@/Modules/Client/ClientRelationship/ClientRelationshipModel.js'

export const clientType = Object.freeze({
  corporate: 'corporate',
  individual: 'individual',
})

export default class ClientModel {
  id = null
  name = null
  legalName = null
  code = null
  companyRegistrationCode = null
  companyTaxCode = null
  active = false
  status = null
  type = clientType.corporate
  email = null
  phone = null
  firstName = null
  lastName = null
  dateOfBirth = null
  website = null
  accountManagerId = null
  language = null
  occupationCode = null
  bankAccountDetails = null
  remoteFolder = null
  data = null

  manager = null
  addresses = []
  contacts = []
  relationships = []
  addedRelationships = []
  occupation = null

  contact = null
  address = null
  primaryAddress = null

  entityId = null
  entity = null
  hasRelations = false
  notes = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additional_data
    this.addresses =
      data.addresses &&
      _.map(data.addresses, address => new ClientAddressModel(address))
    this.contacts =
      data.contacts &&
      _.map(data.contacts, contact => new ClientContactModel(contact))
    this.relationships =
      data.relationships &&
      _.map(data.relationships, relationship => new ClientRelationshipModel(relationship))
    this.addedRelationships =
      data.added_relationships &&
      _.map(data.added_relationships, relationship => new ClientRelationshipModel(relationship))

    this.manager = data.manager && new UserModel(data.manager)
    this.occupation =
      data.occupation && new ClientOccupationModel(data.occupation)
    this.address = data.address && new ClientAddressModel(data.address)
    this.primaryAddress = data.primary_address && new ClientAddressModel(data.primary_address)
    this.contact = data.contact && new ClientContactModel(data.contact)
    this.entity = data.entity && new EntityModel(data.entity)
  }

  static types() {
    return [
      {
        label: i18n.tc('clients.corporate'),
        value: clientType.corporate,
      },
      {
        label: i18n.tc('clients.individual'),
        value: clientType.individual,
      },
    ]
  }

  getOccupation(occupationOptions) {
    if (Array.isArray(occupationOptions) && occupationOptions.length > 0) {
      return _.find(
        occupationOptions,
        item => item.value === this.occupationCode
      )?.label
    }
    return this.occupationCode
  }

  getClientIcon() {
    switch (this.type) {
      case clientType.corporate:
        return 'building'
      case clientType.individual:
        return 'user-square'
      default:
        return ''
    }
  }

  get statusText() {
    if (_.isObject(this.status)) {
      return i18n.t(`statuses.client-${this.status.key}`)
    }

    if (this.active) {
      return i18n.tc('statuses.active')
    }

    return i18n.tc('statuses.inactive')
  }

  get statusBadgeVariant() {
    if (this.active) {
      return 'soft-success'
    }
    return 'soft-danger'
  }

  static normalizeName(name = '') {
    return _.isString(name) ? name.trim() : name
  }
}
