<template>
  <b-badge :variant="parsedVariant" class="pl-3 pr-3 pt-2 pb-1 text-uppercase">
    <slot/>
  </b-badge>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    variant: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    parsedVariant: function () {
      return this.variant.replace('badge-', '')
    }
  }
}
</script>
