import InvoiceModel from '@/Modules/Invoice/InvoiceModel.js'
import PaymentModel from '@/Modules/Payment/PaymentModel.js'
import ProductModel from '@/Modules/Product/ProductModel.js'
import QuoteModel from '@/Modules/Quote/QuoteModel.js'
import TransactionCategoryModel from '@/Modules/Transaction/TransactionCategory/TransactionCategoryModel.js'
import _ from 'lodash'
import applyNumberFormatting from '@/General/applyNumberFormatting.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import StatementModel from '@/Modules/Statement/StatementModel.js'
import { i18n } from '@/Setup/SetupI18n.js'
import * as math from 'mathjs'

export const MODE_RECEIVABLE = 'receivable'

export const transactionMode = Object.freeze({
  receivable: 'receivable',
  payment: 'payment',
})

export const paymentStatus = Object.freeze({
  paid: 'paid',
  unpaid: 'unpaid',
  partial: 'partially_paid',
  all: 'all',
})

export const transactionBalance = Object.freeze({
  inBalancePositive: 1,
  inBalanceNegative: -1,
})

export default class TransactionModel {
  id = null
  quoteId = null
  mode = null
  type = null
  transactionDate = null
  categoryId = null
  productId = null
  ownerEntityId = null
  binderId = null
  roundingStyle = null
  childSummary = null
  summary = null
  number = null
  userId = null
  inBalance = false
  rate = null
  currencyCode = null
  credit = null
  creditWithCurrency = null
  debit = null
  payablePremium = null
  payablePremiumRaw = null
  debitWithCurrency = null
  invoiceDefaultDueDate = null
  note = null
  hasNote = false
  index = null
  childs = []
  invoiceId = null
  dependentOnTransactionId = null
  originalTransactionId = null
  createdAt = null
  dependentChildren = null

  grossPremiumSum = 0
  agentCommissionSum = 0
  commissionSum = 0
  paymentsSum = 0

  quote = null
  category = null
  product = null
  ownerEntity = null
  payment = null
  parentProduct = null
  invoice = null
  dependentOnTransaction = null
  originalTransaction = null
  statements = null
  binder = null
  rowGroup = {
    visible: false,
    expandable: false,
  }

  mappedAmount = null
  mappedAmountWithCurrency = null
  amount = null
  outstanding = null

  settled = null
  settledWithCurrency = null
  writeOff = null
  writeOffWithCurrency = null
  overdraftAmount = null
  overdraftWithCurrency = null
  paymentsTransactions = null
  schemaProduct = null
  agentCommissionSumWithCurrency = null
  commissionSumWithCurrency = null
  grossPremiumSumWithCurrency = null

  agentCommissionSumWithoutCurrency = null
  static transactionMode = { ...transactionMode }
  static paymentStatus = { ...paymentStatus }

  static ROUNDING_DECIMALS() {
    return 3
  }

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    if (
      null !== this.paymentsTransactions &&
      this.paymentsTransactions.length
    ) {
      _.forEach(this.paymentsTransactions, (transaction, key) => {
        this.paymentsTransactions[key] = new TransactionModel(transaction)
        this.paymentsSum += parseFloat(transaction.debit)
      })
    }
    this.quote = data.quote && new QuoteModel(data.quote)
    this.category = data.category && new TransactionCategoryModel(data.category)
    this.product = data.product && new ProductModel(data.product)
    this.ownerEntity = this.ownerEntity && new EntityModel(this.ownerEntity)
    this.binder = data.binder && new EntityModel(data.binder)
    this.payment = data.payment && new PaymentModel(data.payment)
    this.parentProduct =
      this.parentProduct && new ProductModel(this.parentProduct)
    this.invoice = data.invoice && new InvoiceModel(data.invoice)
    this.dependentOnTransaction =
      this.dependentOnTransaction &&
      new TransactionModel(this.dependentOnTransaction)
    this.originalTransaction =
      this.originalTransaction && new TransactionModel(this.originalTransaction)
    this.childs = _.mapValues(this.childs, (item) => new TransactionModel(item))
    this.rowGroup.expandable = this.childs && _.keys(this.childs).length > 0

    this.childs = Object.keys(this.childs).map(
      (key) =>
        new TransactionModel({
          ...this.childs[key],
          dependentChildren:
            this.childs[key].dependentChildren &&
            _.mapValues(
              this.childs[key].dependentChildren,
              (dependentChild) => new TransactionModel(dependentChild)
            ),
        })
    )

    this.statements =
      data.statements &&
      _.map(data.statements, (statement) => new StatementModel(statement))

    _.forEach(this.childs, (child) => {
      if (child.type === 'agent_commission') {
        this.agentCommissionSum += parseFloat(child.credit)
      }
      if (child.type && child.type.includes('commission')) {
        this.commissionSum += parseFloat(child.credit)
      }
      if (child.type === 'premium') {
        this.grossPremiumSum += parseFloat(child.credit)
      }
    })
  }

  isPayment() {
    return this.mode === transactionMode.payment
  }

  productName() {
    if (!this.product) return ''
    if (this.parentProduct) {
      return `${this.parentProduct.name} / ${this.product.name}`
    }
    return `${this.product.name}`
  }

  outstandingAmountError(locale) {
    const isNegative = Number(this.amount) < 0
    const outstandingAmount = math.round(
      Number(this.amount) - Number(this.mappedAmount),
      TransactionModel.ROUNDING_DECIMALS()
    )
    const outstanding = Number(this.outstanding)

    if (isNegative) {
      if (outstanding < outstandingAmount) {
        return i18n.tc('transactions.minimum-amount', undefined, {
          amount: applyNumberFormatting(
            outstandingAmount,
            locale,
            this.currency.code
          ),
        })
      }
      if (this.outstanding > 0) {
        return i18n.tc('transactions.negative-amount')
      }
      return ''
    }

    if (outstanding > outstandingAmount) {
      return i18n.tc('transactions.maximum-amount', undefined, {
        amount: applyNumberFormatting(
          outstandingAmount,
          locale,
          this.currency.code
        ),
      })
    }

    if (outstanding < 0) {
      return i18n.tc('transactions.positive-amount')
    }

    return ''
  }

  outstandingValueRange() {
    const isNegative = Number(this.amount) < 0
    const outstandingAmount = math.round(
      Number(this.amount) - Number(this.mappedAmount),
      TransactionModel.ROUNDING_DECIMALS()
    )

    if (isNegative) {
      return { min: outstandingAmount, max: 0 }
    }
    return { min: 0, max: outstandingAmount }
  }

  quoteVersion() {
    return this.quote?.data?.version
  }

  typeBadge() {
    if (this.mode === transactionMode.receivable) {
      return { label: 'RECV', variant: 'soft-warning' }
    }
    return { label: 'PYMT', variant: 'soft-success' }
  }

  transactionClass() {
    const className = {
      negative: 'text-danger',
      positive: 'text-success',
    }
    const isNegativeCredit = parseFloat(this.credit) < 0
    const isPositiveBalance =
      isNegativeCredit && transactionBalance.inBalanceNegative

    if (
      this.inBalance === transactionBalance.inBalancePositive &&
      isPositiveBalance
    ) {
      return className.negative
    }
    if (
      this.inBalance === transactionBalance.inBalanceNegative &&
      isNegativeCredit
    ) {
      return className.positive
    }
    if (this.inBalance === transactionBalance.inBalancePositive) {
      return className.positive
    }
    if (this.inBalance === transactionBalance.inBalanceNegative) {
      return className.negative
    }
    return ''
  }

  setOutstandingAmount(newValue) {
    if (isNaN(newValue)) {
      return
    }
    this.outstanding = newValue
  }

  resetOutstandingAmount() {
    const outstandingAmount = math.round(
      Number(this.amount) - Number(this.mappedAmount),
      TransactionModel.ROUNDING_DECIMALS()
    )

    if (!isNaN(outstandingAmount)) {
      this.outstanding = outstandingAmount
    }
  }

  getFormattingAmount() {
    return this.inBalance === 0 ? this.credit : Math.abs(this.credit)
  }

  get parentTransactions() {
    if (!this.childs) return
    return this.childs.filter((child) => !child.dependentOnTransactionId)
  }

  get localizedSummary() {
    if (!_.isString(this.summary)) {
      return ''
    }
    if (this.summary.match(/payment made/i)) {
      const account = _.first(this.summary.match(/\(.*\)/g))
      if (account) {
        return `${i18n.t('transactions.payment-made')} ${account}`
      }
      return i18n.t('transactions.payment-made')
    }
    if (this.summary.match(/amount receivable/i)) {
      return i18n.t('transactions.amount-receivable')
    }
    return this.summary
  }
}
