<template>
  <div class="header pt-2" :class="headerClass">
    <div class="pl-0 pr-0">
      <div :class="{ 'header-body': true, 'without-underline': ! underline }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    headerClass: {
      type: String,
      default: null,
    },
    underline: {
      type: Boolean,
      default: true
    },
  },
}
</script>
<style lang="scss">
.header-body {
  &.without-underline {
    border-bottom: 0;
    padding: 0;
  }
}
</style>
