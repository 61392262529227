<template functional>
  <div
    v-if="props.showError && props.error.hasError"
    :class="{ [`text-${props.error.level}`]: !!props.error.level }"
    class="invalid-feedback mb-1"
  >
    {{ props.error.getMessage() }}
  </div>
</template>
<script>
import ErrorModel from '@/General/Form/ErrorModel.js'

export default {
  name: 'ErrorLabel',

  props: {
    showError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: function _default() {
        return new ErrorModel({})
      },
    },
  },
}
</script>
