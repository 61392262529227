import _ from 'lodash'

export const settingKeys = Object.freeze({
  entityCounterId: 'entity_counter_uuid',
})

export default class SettingModel {
  key = null
  value = null
  type = null
  options = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }
}
