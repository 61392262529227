import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const statementRoutes = new RouteGroup(() => {
  return [
    new Route('statements.agent-list', '/statements/agent/:tab', () =>
      import(/* webpackChunkName: "payments" */ './Pages/AgentList.vue')
    )
      .permission('statements.view')
      .resolve(),

    new Route('statements.insurer-list', '/statements/insurer/:tab', () =>
      import(/* webpackChunkName: "payments" */ './Pages/List.vue')
    )
      .permission('statements.view')
      .resolve(),

    new Route('statements.show', '/statements/:statementId/show', () =>
      import(/* webpackChunkName: "payments" */ './Pages/Show.vue')
    )
      .permission('statements.view')
      .resolve(),

    new Route('statements.create', '/statements/:type/create', () =>
      import(/* webpackChunkName: "payments" */ './Pages/Create.vue')
    )
      .permission('statements.create')
      .resolve(),

    new Route(
      'statements.manage-transactions',
      '/statements/:statementId/manage-transactions',
      () =>
        import(
          /* webpackChunkName: "payments" */ './Pages/ManageTransactions.vue'
        )
    )
      .permission('statements.create')
      .resolve(),

    new Route(
      'statements.documents.list',
      '/statements/:statementId/documents',
      () =>
        import(
          /* webpackChunkName: "payments" */ './StatementDocument/Pages/List.vue'
        )
    )
      .permission('statements.documents.view')
      .resolve(),

    new Route(
      'statements.documents.list.folder',
      '/statements/:statementId/documents/:folderId',
      () =>
        import(
          /* webpackChunkName: "payments" */ './StatementDocument/Pages/List.vue'
        )
    )
      .permission('statements.documents.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
