<template>
  <div
    v-if="html"
    v-b-popover.html.hover="`<pre style='width: 500px'>${html}</pre>`"
    :class="className"
  >
    <Icon icon="question-circle" size="18" />
  </div>
  <div v-else v-b-popover.hover="text" :class="className">
    <Icon icon="question-circle" size="18" />
  </div>
</template>

<script>
export default {
  name: 'FieldHint',

  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
    className: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      html: null,
    }
  },

  created() {
    try {
      const content = JSON.parse(this.text)
      this.html = this.prettyPrintJson.toHtml(content, { quoteKeys: true })
    } catch (e) {}
  },
}
</script>
