import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const releaseRoutes = new RouteGroup(() => {
  return [
    new Route('releaseNotes.show', '/release-notes/', () =>
      import('./Pages/Show.vue')
    ).resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
