<template>
  <div class="scroll-container" :style="{ maxHeight: modalMaxHeight }">
    <slot />
  </div>
</template>

<script>
import ModalMixin from '@/General/Modal/ModalMixin.js'
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: 'ScrollContainer',

  mixins: [ModalMixin],

  data() {
    return {
      modalMaxHeight: 0,
      ps: null,
    }
  },

  mounted() {
    this.modalMaxHeight = this.getModalMaxHeight()
    if (this.$el) {
      this.ps = new PerfectScrollbar(this.$el)
    }
  },
}
</script>
<style scoped>
.scroll-container {
  position: relative;
  max-height: 600px;
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 20px;
  margin-left: -1px;
  padding-left: 1px;
}
</style>
