
<div
  v-if="visible"
  :id="id"
  :class="{ 'form-group': true, [customClass]: !!customClass }"
>
  <div class="row">
    <div class="col">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
    </div>

    <div class="col-auto">
      <slot name="labelRight"></slot>
      <FieldHint v-if="hint" :text="hint" />
    </div>
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <div class="input-group" :class="{ 'input-group-merge': !!icon }">
    <input
      :id="id || dotKey"
      ref="lookUpField"
      v-model="model"
      v-validate="validation"
      autocomplete="off"
      :data-vv-name="dotKey"
      :type="type"
      v-bind="$attrs"
      :name="name"
      :placeholder="placeholder"
      :disabled="readonly"
      :class="{
        'form-control': true,
        'form-control-appended': !!icon,
        readonly: readonly,
        'is-invalid': hasErrors,
        [inputClass]: !!inputClass,
      }"
    />

    <div v-if="icon" class="input-group-append">
      <span :class="{ 'input-group-text': icon, readonly: readonly }">
        <i :class="icon"></i>
      </span>
    </div>

    <slot name="append"></slot>
  </div>

  <div v-if="apiError" class="invalid-feedback mb-1">
    {{ apiError }}
  </div>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>
</div>
