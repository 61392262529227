import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import * as fallbackMessages from '../../lang/translations.json'

Vue.use(VueI18n)

export const localeKey = 'locale'
export const fallbackLocale = 'en_US'

if (!localStorage.getItem(localeKey)) {
  localStorage.setItem(localeKey, fallbackLocale)
}

export const getTranslations = () => {
  let messages = window.translations

  if (typeof messages === 'undefined') {
    return fallbackMessages
  }

  return _.merge({}, fallbackMessages, messages)
}

export const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale,
  messages: getTranslations(),
  silentTranslationWarn: true,
  postTranslation: (value, key) => {
    if (value && value.match(/\[.*\]/i)) {
      return value
    }

    if (window.location.search.includes('lang')) {
      return `${value} [${key}]`
    }

    return value
  },
})

export function setLocale(locale) {
  i18n.locale = locale
  localStorage.setItem('locale', locale)
}

export function getLocale() {
  return localStorage.getItem(localeKey) || fallbackLocale
}
