<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="navbar-toggler-persistent"
      :class="{ collapsed: !expanded }"
    >
      <button
        v-if="expanded"
        size="sm"
        class="nav-toggle-button"
        @click="
          () => {
            onToggle({ collapse: true })
          }
        "
      >
        <Icon class="grey" icon="angle-left" />
      </button>
      <button
        v-else
        size="sm"
        class="nav-toggle-button"
        @click="
          () => {
            onToggle({ collapse: false })
          }
        "
      >
        <Icon class="grey" icon="angle-right" />
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CoverholderNavigationToggle',

  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.navbar-toggler-persistent {
  transition: all 0.4s;
  position: fixed;
  z-index: 1049;
  left: 250px;
  top: 20px;
  transform: translateX(-50%);
  display: none;

  &.collapsed {
    left: 11px;
    transform: translateX(0);
  }
}
.nav-toggle-button {
  align-items: center;
  display: flex;
  justify-content: center;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  color: white;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  transition-duration: 100ms, 100ms, 350ms;
  transition-timing-function: linear, linear, cubic-bezier(0.2, 0, 0, 1);
  transition-property: background-color, color, opacity;
  &:hover {
    background-color: #2c7be5;
    i {
      color: white;
    }
  }
}
@media (min-width: 992px) {
  .navbar-toggler-persistent {
    display: block;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>