<template>
  <div v-if="visible" :id="id" :class="{ 'form-group': true }">
    <div class="row">
      <div class="col">
        <TraceLabel
          :node="quoteNode"
          :label="label"
          :required="required"
          :name="name"
        />
      </div>

      <div class="col-auto">
        <button
          v-if="canReset"
          class="btn btn-link text-danger p-0 mb-2"
          @click="reset"
        >
          <Icon icon="times" class="text-danger" size="14" />
          <small>{{ __('buttons.reset') }}</small>
        </button>
      </div>
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div :class="{ 'd-none': hideUploadCanvas }" class="input-group">
      <b-form-file
        :ref="`fileField_${name}`"
        v-model="model"
        :name="name"
        class="big-file-input"
        :multiple="multiple"
        :disabled="readonly"
        :class="{
          readonly: readonly,
          'is-invalid': hasErrors,
          [`is-${highlightClass}`]: !!highlightClass,
        }"
        :accept="accept"
        @input="onChange"
      >
        <template #file-name="{ names }">
          <b-button
            v-for="(name, key) in names"
            :key="key"
            class="mr-2"
            variant="outline-secondary"
          >
            <img
              width="24"
              :src="getFileTypeIcon(name)"
              class="mr-2"
              :alt="name"
            />
            {{ name }}
          </b-button>
        </template>

        <template #placeholder>
          <div>
            <Icon icon="cloud-upload" size="40" class="text-primary" />
            <br />
            {{ __('fields.file-field-placeholder') }}
          </div>
        </template>
      </b-form-file>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>
  </div>
</template>

<script>
import FileManager from '@/General/FileManager.js'
import InputComponent from '@/General/Form/Mixins/InputComponent.js'

export default {
  name: 'FileField',

  mixins: [InputComponent],

  props: {
    hideUploadCanvas: {
      type: Boolean,
      default: false,
    },
    maxUploadSize: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      model: _.isString(this.model) ? [this.model] : this.model || null,
    }
  },

  computed: {
    canReset: function () {
      return !_.isEmpty(this.model)
    },
  },

  methods: {
    reset() {
      this.$refs[`fileField_${this.name}`].reset()
      this.$emit('reset')
    },

    getFileTypeIcon(name) {
      return FileManager.getFileTypeIcon(name)
    },

    onChange(file) {
      const isFileValid = this.validateFile(file)

      if (!isFileValid) {
        this.reset()
        return
      }

      this.$emit('input', file)
      this.$emit('changeDynamicFieldValue', file)
    },

    validateFile(file) {
      if (!file) {
        return true
      }

      const allowedExtensions = this.accept.replace(/\s/g, '').split(',')

      if (this.accept !== '' && !allowedExtensions.includes(file.type)) {
        this.errors.add({
          field: this.name,
          msg: this.__('common.file_extension_error', {
            label: this.accept,
          }),
        })
        return false
      }

      if (this.maxUploadSize > -1 && file.size > this.maxUploadSize) {
        this.errors.add({
          field: this.name,
          msg: this.__('common.file_max_upload_error', {
            label: this.maxUploadSize / 1000000,
          }),
        })
        return false
      }

      this.errors.clear()
      return true
    },
  },
}
</script>

<style scoped lang="scss">
.big-file-input.readonly {
  opacity: 0.4;
}
</style>
