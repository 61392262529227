<template>
  <currency-field
    v-model="model"
    :show-error="showError"
    :precision="precision"
    v-bind="$props"
    @input=";[emitInput($event), $emit('onChange', $event)]"
  >
    <template slot="append">
      <select
        v-model="currencyModel"
        name="currency_code"
        data-testid="currency-code"
        :class="{
          'form-control': true,
          readonly: readonly,
          'is-invalid': hasErrors || currencyHasError,
        }"
        :disabled="readonly"
        style="max-width: 70px; padding-left: 5px; padding-right: 0px"
      >
        <option
          v-for="(option, key) in currencies"
          :key="key"
          :value="option.value"
        >
          {{ option.label.toUpperCase() }}
        </option>
      </select>
    </template>
  </currency-field>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import {
  CURRENCIES_GETTER,
  PRIMARY_CURRENCY_GETTER,
} from '@/Modules/Base/SettingModule.js'
import { parse } from 'vue-currency-input'

export default {
  name: 'MoneyField',

  mixins: [InputComponent],

  props: {
    currency: {
      type: String,
      default: null,
    },
    precision: {
      type: Number,
      default: 2,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    currenciesData: {
      type: Array,
      default: null,
    },
    currencyHasError: {
      type: Boolean,
      default: false,
    },
    fastInput: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currencyModel: null,
    }
  },

  computed: {
    moneyCurrency: function () {
      if (this.currency) {
        return this.currency
      }

      return this.$store.getters[`SettingModule/${PRIMARY_CURRENCY_GETTER}`]
    },

    currencies() {
      if (this.currenciesData) {
        return this.currenciesData
      }

      return this.$store.getters[`SettingModule/${CURRENCIES_GETTER}`]
    },
  },

  created() {
    this.currencyModel = this.moneyCurrency

    if (!this.currency) {
      this.$emit('currencyUpdated', this.currencyModel)
    }

    this.$watch(
      'currencyModel',
      function (newCurrency) {
        this.$emit('currencyUpdated', newCurrency)
      },
      { immediate: true }
    )
  },

  methods: {
    emitInput(event) {
      this.$emit('input', event)
      this.$emit('update:value', parse(String(event)))
    },
  },
}
</script>
