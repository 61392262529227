import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'
import moment from 'moment'
import { DATE_FORMAT_GETTER } from '@/Modules/Base/SettingModule.js'
import store from '@/Setup/SetupStore.js'
import PaymentAccountModel from '@/Modules/Payment/PaymentAccount/PaymentAccountModel.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import CurrencyModel from '@/Modules/Currency/CurrencyModel.js'
import SelectOptionModel from '@/Modules/Dataset/SelectOptionModel.js'
import * as math from 'mathjs'

export const PaymentLocking = [
  'agent',
  'paymentDate',
  'paymentAmount',
  'isConverted',
  'settlementAmount',
  'settlementRate',
  'direction',
]

export const PaymentStatus = Object.freeze({
  open: 'open',
  partial: 'partial',
  reconciled: 'reconciled',
  fixed: 'fixed',
})

export const PaymentDirection = Object.freeze({
  incoming: 'in',
  outgoing: 'out',
})

export default class PaymentModel {
  id = null
  transactionId = null
  description = null
  status = PaymentStatus.open
  direction = PaymentDirection.incoming
  isConverted = false
  mappedTransactions = null
  mappedAmount = null
  mappedAmountWithCurrency = null
  entryDate = new Date()

  paymentAccount = null
  paymentDate = new Date()
  paymentAmount = null
  paymentAmountWithCurrency = null
  paymentCurrency = null
  paymentReference = null

  payerBankAccount = null
  payerName = null
  payerCode = null

  settlementAmount = null
  settlementAmountWithCurrency = null
  settlementCurrency = null
  settlementRate = 1

  entity = null
  isLocked = false
  canDelete = false

  static ROUNDING_DECIMALS() {
    return 3
  }

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.paymentAccount =
      this.paymentAccount && new PaymentAccountModel(this.paymentAccount)

    this.paymentAccountSelect =
      this.paymentAccount &&
      new SelectOptionModel({
        value: this.paymentAccount.id,
        label: this.paymentAccount.name,
      })

    this.entity = this.entity && new EntityModel(this.entity)
    this.entitySelect =
      this.entity &&
      new SelectOptionModel({
        value: this.entity.id,
        label: this.entity.name,
      })

    this.paymentCurrency = new CurrencyModel(this.paymentCurrency)
    this.settlementCurrency = new CurrencyModel(this.settlementCurrency)

    this.isLocked = this.mappedTransactions > 0 || data?.lockPayment
  }

  static statuses() {
    return Object.values(PaymentStatus).map((value) => ({
      label: _.startCase(value),
      value,
    }))
  }

  static directions() {
    return [
      {
        value: PaymentDirection.incoming,
        label: i18n.tc('payments.direction-incoming'),
      },
      {
        value: PaymentDirection.outgoing,
        label: i18n.tc('payments.direction-outgoing'),
      },
    ]
  }

  formatPaymentDate() {
    if (moment(this.paymentDate).isValid()) {
      return moment(this.paymentDate).format(
        store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
      )
    }
    return null
  }

  /**
   *
   * @param {number} mappedAmount new mapped amount
   * @returns {string}
   */
  remainingAmountError(mappedAmount) {
    const remainingAmount = math.round(
      Number(this.settlementAmount) - Number(mappedAmount),
      PaymentModel.ROUNDING_DECIMALS()
    )

    if (remainingAmount === 0) {
      return ''
    }

    if (this.isNegative && remainingAmount > 0) {
      return i18n.tc('payments.remaining-amount-error-1')
    }

    if (!this.isNegative && remainingAmount < 0) {
      return i18n.tc('payments.remaining-amount-error-2')
    }

    return ''
  }

  isFieldLocked(fieldName) {
    return this.isLocked && PaymentLocking.includes(fieldName)
  }

  get badgeVariant() {
    switch (this.status) {
      case PaymentStatus.open:
        return 'soft-secondary'
      default:
        return 'soft-primary'
    }
  }

  get localizedStatus() {
    if (!this.status) {
      return ''
    }
    return i18n.tc(`statuses.${this.status}`)
  }

  get isNegative() {
    return Number(this.settlementAmount) < 0
  }

  /**
   *
   * @param {number} amount amount
   * @returns {number|null}
   */
  calculateCurrentMappedAmount(amount) {
    if (this.mappedAmount === null) {
      return amount
    }
    return math.round(
      Number(this.mappedAmount) + Number(amount),
      PaymentModel.ROUNDING_DECIMALS()
    )
  }

  /**
   *
   * @param {number} mappedAmount mappedAmount
   * @returns {number|null}
   */
  calculateCurrentRemainingAmount(mappedAmount) {
    if (isNaN(mappedAmount)) {
      return null
    }
    return math.round(
      Number(this.settlementAmount) - Number(mappedAmount),
      PaymentModel.ROUNDING_DECIMALS()
    )
  }

  setMappedTransactions(value = 0) {
    this.mappedTransactions = value

    if (this.mappedTransactions > 0) {
      this.isLocked = true
      return
    }

    this.isLocked = false
  }
}
