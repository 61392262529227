
<div v-if="showMore">
  <div class="d-inline" v-html="expanded ? html : truncateText" />
  <b-button
    variant="link"
    size="sm"
    class="ml-2"
    @click="handleShowMoreClick()"
  >
    {{ expanded ? __('common.show-less') : __('common.show-more') }}
  </b-button>
</div>
<div v-else>
  <div v-html="html" />
</div>
