export default {
  methods: {
    getPrefixedRoute(suffix) {
      if (this.$route.name.includes('quotes')) {
        return 'quotes.' + suffix
      }

      return 'policies.' + suffix
    },
  },
}
