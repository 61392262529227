import HelpPageModel from '@/Modules/HelpPage/HelpPageModel.js'
import ReleaseModel from '@/Modules/Release/ReleaseModel.js'
import axios from 'axios'

export default class CDNService {
  static releaseNotes = () =>
    axios
      .get(`cdn/release-notes`, {
        headers: { 'Cache-Control': 'max-age=0' },
      })
      .then((response) =>
        response.data.data.map((item) => new ReleaseModel(item))
      )

  static getHelpPage = (route) =>
    axios
      .get(`cdn/help-pages/${route}`, {
        headers: { 'Cache-Control': 'max-age=0' },
      })
      .then((response) => new HelpPageModel(response.data))
}
