import ClientModel from '@/Modules/Client/ClientModel.js'
import ClientService from '@/Modules/Client/ClientService.js'

// Getters
export const CLIENT_GETTER = 'client'
export const TAB_COUNTS_GETTER = 'tabCounts'
export const RESPONSE_ERROR = 'response_error'

// Mutations
export const SET_CLIENT_MUTATION = 'setClient'
export const RESET_CLIENT_MUTATION = 'resetClient'
export const SET_TAB_COUNTS = 'setTabCounts'

// Actions
export const GET_CLIENT_ACTION = 'getClient'
export const INCREASE_TAB_COUNT = 'increaseTabCount'
export const DECREASE_TAB_COUNT = 'decreaseTabCount'

export const ClientModule = {
  namespaced: true,

  state: {
    client: new ClientModel({
      addresses: [],
      contacts: [],
      manager: {},
      occupation: {},
    }),
    tabCounts: {},
    error: null,
  },

  mutations: {
    [SET_CLIENT_MUTATION]: (state, client) => {
      state.client = client
    },

    [RESET_CLIENT_MUTATION]: (state) => {
      state.client = new ClientModel({
        addresses: [],
        contacts: [],
        manager: {},
        occupation: {},
      })
      state.tabCounts = {}
      state.versions = []
    },

    [SET_TAB_COUNTS]: (state, tabCounts) => {
      state.tabCounts = tabCounts
    },
    [RESPONSE_ERROR]: (state, payload) => {
      state.error = payload
    },
  },

  getters: {
    [CLIENT_GETTER]: (state) => {
      return state.client
    },

    [TAB_COUNTS_GETTER]: (state) => {
      return state.tabCounts
    },
    [RESPONSE_ERROR]: (state) => {
      return state.error
    },
  },

  actions: {
    [GET_CLIENT_ACTION]: ({ commit }, clientId) => {
      ClientService.show(clientId).then(
        (clientData) => {
          commit(SET_CLIENT_MUTATION, new ClientModel(clientData.client))
          commit(SET_TAB_COUNTS, clientData.tabCounts)
          commit(RESPONSE_ERROR, false)
        },
        (error) => {
          commit(RESPONSE_ERROR, error.response)
        }
      )
    },

    [INCREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) + 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [DECREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) - 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },
  },
}
