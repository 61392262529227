import { SET_BASE_NODE_PROPERTY } from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import { mapMutations } from 'vuex'

export default {
  created () {
    // If node has optionsFromMultiSection set.
    if (! _.isEmpty(this.node.optionsFromMultiSection)) {
      let { keys, section, useSectionKeyAsValue} = this.node.optionsFromMultiSection

      // If we do not have any keys or section set, we have nothing to do here.
      if (! keys || ! section) {
        return
      }

      // Convert section to nodePath.
      section = section.replace(/\./g, 'properties')

      // If we have all the options needed, we need to watch section rows, to change the items when needed.
      this.$watch(`baseNode.properties.${ section }.rows`, async function (newRows) {
        let newOptions = []

        // We go through every row, and add the item according to keys map to new select options array
        await _.each(newRows, (row, index) => {
          const valueLabel = _.filter(_.map(keys, key => _.get(row, `${ key }.value`))).join(' ')

          if (! _.isEmpty(valueLabel)) {
            newOptions.push({
              value: useSectionKeyAsValue ? index : valueLabel,
              label: valueLabel
            })
          }
        })

        let nodePath = this.node.nodePath

        // If index is set, it means, that we are in a multi section row
        if (this.index) {
          // We replace the first dot with an index to get the correct path
          nodePath = nodePath.replace('.', `.rows.${this.index}.`)
        }

        // Update the node with new options.
        this.setBaseNodeProperty({
          property: `${ nodePath }.options`,
          value: newOptions
        })

        if (_.isEmpty(newRows)) {
          this.node.value = null
        }
      }, { deep: true, immediate: true })
    }
  },

  methods: {
    ...mapMutations('QuoteFormModule', {
      setBaseNodeProperty: SET_BASE_NODE_PROPERTY
    }),
  }
}
