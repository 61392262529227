<template>
  <div :class="{ 'form-group': true, [customClass]: !!customClass }">
    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div
      class="custom-control custom-checkbox"
      :class="{ 'custom-control-inline': inline }"
    >
      <input
        :id="id || dotKey"
        v-validate="validation"
        :value="value"
        :data-vv-name="dotKey"
        type="checkbox"
        class="custom-control-input"
        :name="name"
        :checked="checked || !!model"
        :disabled="disabled"
        @input="emitChange"
      />
      <label class="custom-control-label" :for="id || dotKey">
        <slot name="label">
          {{ label }}
          <RequiredNotation :required="required"></RequiredNotation>
        </slot>
      </label>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import RequiredNotation from '@/General/RequiredNotation.vue'

export default {
  name: 'Checkbox',

  components: { RequiredNotation },

  mixins: [InputComponent],

  props: {
    inline: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    emitChange(event) {
      this.$emit('input', event.target.checked)
      this.$emit('onChange', event.target.checked)
    },
  },
}
</script>
