<template>
  <i v-bind="$attrs" :class="`uil uil-${icon}`" :style="`font-size: ${size}px`"></i>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    icon: {
      type: String,
      default: null
    },

    size: {
      type: String | Number,
      default: 18,
    }
  }
}
</script>
