import axios from 'axios'
import EndorsementGroupModel from '@/Modules/EndorsementLibrary/EndorsementGroup/EndorsementGroupModel.js'

export default class EndorsementGroupService {
  static list = ({ parameters, filters }) =>
    axios.get(`endorsement-groups${filters}`, {
      params: parameters,
      excludeLoader: true,
    })
  static create = (payload) => axios.post('endorsement-groups', payload)
  static update = (id, payload) =>
    axios.put(`endorsement-groups/${id}`, payload)
  static delete = (id) => axios.delete(`endorsement-groups/${id}`)
  static show = (id) =>
    axios
      .get(`endorsement-groups/${id}`)
      .then((response) => new EndorsementGroupModel(response.data.data))
  static getEndorsements = (groupTag) =>
    axios.get(`endorsement-groups/${groupTag}/endorsements`)
}
