
<div v-if="help.visible" v-can:system.help class="help-sidebar">
  <div
    class="d-flex justify-content-between align-items-center help-header p-3"
  >
    <h4 class="m-0 p-0">
      {{ __('schemas.help') }}
    </h4>
    <b-button variant="light" size="sm" @click="handleCloseButtonClick">
      <Icon icon="times" size="14" />
    </b-button>
  </div>
  <small v-if="traceMode" class="p-3"
    >Route name: <span class="text-muted">{{ $route.name }}</span></small
  >
  <div
    v-if="helpPageContent"
    class="help-content p-3"
    v-html="helpPageContent"
  />
</div>
