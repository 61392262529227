import _ from 'lodash'
import UiTypes from '@/Modules/Quote/Components/QuoteForm/UiTypes.js'
import UiTypeMultipleSection, {
  IS_ROWS_INIT,
} from '@/Modules/Quote/Components/QuoteForm/Elements/UiTypeMultipleSection/UiTypeMultipleSection.js'
import { MONEY_FIELD_PROPERTIES } from '@/Modules/Quote/Components/QuoteForm/helpers'

export const MODE = Object.freeze({
  EXECUTE_RULES: 'executeRules',
  SAVE: 'save',
})

export default class QuoteBaseNodeRowBuilder {
  baseNode = null
  manualExecution = false
  sections = []
  locale = 'en-US'
  formattedValueRegex = new RegExp(/FormattedValue$/)
  mode = MODE.EXECUTE_RULES
  #sectionKey = 'nodePath'

  constructor(baseNode) {
    this.baseNode = baseNode
    this.sections = this.getSections()
  }

  traverseNodeAndCleanUp(node = this.baseNode) {
    if (
      Object.prototype.hasOwnProperty.call(node, 'properties') &&
      !_.isEmpty(node.properties)
    ) {
      for (const key in node.properties) {
        if (node.properties.hasOwnProperty(key)) {
          const property = node.properties[key]

          delete property.valuePath

          delete property.rowKey

          this.traverseNodeAndCleanUp(property)
        }
      }
    }
  }

  getPropertyValue(property) {
    if (!property) return

    let value = property.value

    if (_.isObject(property.value)) {
      value = property.value.value
    }

    if (_.isArray(property.value)) {
      value = property.value.map((item) => item?.value ?? item)
    }

    if (this.mode === MODE.SAVE) {
      const shouldSaveValue =
        [UiTypes.hidden, UiTypes.multipleSectionIndex].includes(
          property.uiType
        ) || property.uiOptions?.visible

      return shouldSaveValue ? value : undefined
    }

    return value
  }

  traverseNodeAndSetValues(node = this.baseNode) {
    if (
      Object.prototype.hasOwnProperty.call(node, 'properties') &&
      !_.isEmpty(node.properties)
    ) {
      for (const key in node.properties) {
        if (node.properties.hasOwnProperty(key)) {
          const property = node.properties[key]

          if (
            !property?.valuePath ||
            [UiTypes.moneyCurrency].includes(property.uiType) ||
            property.valuePath.match(this.formattedValueRegex)
          )
            continue

          if ([UiTypes.money, UiTypes.moneySelect].includes(property.uiType)) {
            const currency = _.get(property, `properties.currency.value`)

            _.set(
              this.baseNode.properties,
              property.valuePath + MONEY_FIELD_PROPERTIES.currency,
              currency
            )

            const formattedValue = _.get(
              property,
              `properties.formattedValue.value`
            )

            _.set(
              this.baseNode.properties,
              property.valuePath + MONEY_FIELD_PROPERTIES.formattedValue,
              formattedValue
            )
          }

          const value = this.getPropertyValue(property)

          _.set(this.baseNode.properties, property.valuePath, value)

          this.traverseNodeAndSetValues(property)
        }
      }
    }
  }

  traverseNodeAndSetValuePath(node = this.baseNode, section, rowKey) {
    if (
      Object.prototype.hasOwnProperty.call(node, 'properties') &&
      !_.isEmpty(node.properties)
    ) {
      for (const key in node.properties) {
        if (node.properties.hasOwnProperty(key)) {
          const property = node.properties[key]

          if (!property.valuePath) {
            const valuePath = QuoteBaseNodeRowBuilder.getValuePath(
              property,
              section,
              rowKey
            )
            property.valuePath = valuePath
          }

          this.traverseNodeAndSetValuePath(property, section, rowKey)
        }
      }
    }
  }

  static getValuePath(property, sectionKey, rowKey) {
    if (!property) return

    const propertyKeyInSection = property.nodePath?.replace(
      `${sectionKey}.`,
      ''
    )

    return `${sectionKey.replace(
      /\./,
      '.properties.'
    )}.value.${rowKey}.${propertyKeyInSection}`
  }

  static getMultipleSectionRows(section) {
    if (_.isEmpty(section)) {
      return
    }

    let rows = {}

    _.each(section.value, (item, key) => {
      let properties = _.cloneDeep(section.properties)

      properties = QuoteBaseNodeRowBuilder.parseNodeSubValues(
        item,
        properties,
        key,
        section.key
      )

      rows[key] = properties
    })

    return rows
  }

  /**
   *
   * @param {object} item multipleSection row
   * @param {object} properties multipleSection properties
   * @param {string} rowKey
   * @param {string} sectionKey multipleSection key
   * @returns {object}
   */
  static parseNodeSubValues(item, properties, rowKey, sectionKey) {
    _.each(properties, (prop, propertyKey) => {
      const value = item[propertyKey]
      if (prop && !_.isString(prop)) {
        if (value && typeof value === 'object') {
          prop.properties = QuoteBaseNodeRowBuilder.parseNodeSubValues(
            value,
            prop.properties,
            rowKey,
            sectionKey
          )
        }

        if ([UiTypes.money, UiTypes.moneySelect].includes(prop.uiType)) {
          const currency = _.get(
            item,
            prop.key + MONEY_FIELD_PROPERTIES.currency
          )

          _.set(prop, 'properties.currency.value', currency)
        }

        // Assign row key to all properties so uiRuleExecutor can make multipleSection target/source path
        prop.rowKey = rowKey
        prop.value = undefined === value ? null : value

        if (!sectionKey) {
          return
        }

        // Assign valuePath to all properties to set values from multipleSection rows by path
        const valuePath = QuoteBaseNodeRowBuilder.getValuePath(
          prop,
          sectionKey,
          rowKey
        )
        properties[propertyKey].valuePath = valuePath
      }
    })

    return properties
  }

  cleanUpSectionRows() {
    for (const key in this.baseNode.properties) {
      const property = this.baseNode.properties[key]

      if (this.sections?.includes(property.key)) {
        for (const rowKey in property.rows) {
          const properties = property.rows[rowKey]
          this.traverseNodeAndCleanUp({ properties })
        }
      }
    }
  }

  /**
   * @param {object} node
   * @returns {undefined}
   */
  buildMultipleSectionRows(node = this.baseNode) {
    this.sections.forEach((item) => {
      const key = item.replace(/\./, '.properties.')

      const property = _.get(node.properties, key)

      const multipleSection = new UiTypeMultipleSection(property)

      if (this.manualExecution && !_.isEmpty(property.rows)) {
        // rows are already set from property value with NEW VALUES in multipleSection during component mount, no need to generate anything
        for (const rowKey in property.rows) {
          let properties = property.rows[rowKey]

          const isRowInitialized = multipleSection.isRowInitialized(rowKey)

          if (!isRowInitialized) {
            let newProperties = _.cloneDeep(property.properties)

            properties = QuoteBaseNodeRowBuilder.parseNodeSubValues(
              property.value[rowKey],
              newProperties,
              rowKey,
              property[this.#sectionKey]
            )

            property.rows[rowKey] = newProperties
            property[IS_ROWS_INIT] = true
          }

          this.traverseNodeAndSetValuePath(
            { properties },
            property[this.#sectionKey],
            rowKey
          )

          if (
            this.mode === MODE.EXECUTE_RULES ||
            multipleSection.hasToExecuteRowsBeforeSave
          )
            this.traverseNodeAndExecuteUiRules({ properties }, rowKey)
        }

        return
      }

      const rows = QuoteBaseNodeRowBuilder.getMultipleSectionRows(property)
      property.rows = rows

      if (_.isEmpty(property.rows)) {
        return
      }

      for (const rowKey in property.rows) {
        const properties = property.rows[rowKey]
        if (this.mode === MODE.EXECUTE_RULES)
          this.traverseNodeAndExecuteUiRules({ properties })
      }
    })
  }

  /**
   *
   * @param {object} node
   * @returns {undefined}
   */
  setMultipleSectionValues(node = this.baseNode) {
    this.sections.forEach((item) => {
      const key = item.replace(/\./, '.properties.')

      const property = _.get(node.properties, key)

      if (
        property.uiType === UiTypes.multipleSection &&
        !_.isEmpty(property.rows)
      ) {
        for (const rowKey in property.rows) {
          const properties = property.rows[rowKey]
          this.traverseNodeAndSetValues({ properties })
        }
      }
    })
  }

  traverseNodeAndFindMultipleSections(node = this.baseNode, sections = []) {
    if (
      Object.prototype.hasOwnProperty.call(node, 'properties') &&
      !_.isEmpty(node.properties)
    ) {
      for (const key in node.properties) {
        if (node.properties.hasOwnProperty(key)) {
          const property = node.properties[key]

          if (
            property.uiType === UiTypes.multipleSection &&
            property.uiProperties?.displayItemsAsTabs
          )
            sections.push(property.nodePath)

          this.traverseNodeAndFindMultipleSections(property, sections)
        }
      }
    }
  }

  getSections() {
    if (!this.baseNode.properties) return []

    return Object.values(this.baseNode.properties)
      .filter(Boolean)
      .reduce((acc, property) => {
        if (
          property.uiType === UiTypes.multipleSection &&
          property.uiProperties?.displayItemsAsTabs &&
          property.uiProperties?.manualUIRulesExecution !== true
        ) {
          acc.push(property.key)
        }

        return acc
      }, [])
  }

  loadRows() {
    try {
      this.manualExecution = true
      this.traverseNodeAndFindMultipleSections(this.baseNode, this.sections)
      this.mode = MODE.SAVE

      this.buildMultipleSectionRows()
      this.setMultipleSectionValues()
      this.cleanUpSectionRows()
    } catch (error) {
      throw new Error(error)
    }
  }
}
