import { i18n } from '@/Setup/SetupI18n.js'
import _ from 'lodash'
import PermissionModel from '@/Modules/Permission/PermissionModel.js'

export const UserEmailAccountStatus = Object.freeze({
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
})

export const UserEmailAccountTypes = Object.freeze({
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
})

export default class UserEmailAccountModel {
  id = null
  status = null
  type = null
  host = null
  port = null
  encryption = null
  securePasswordAuthentication = false
  name = null
  accountName = null
  index = null
  inbox = null
  inboxOptions = null
  permission = null
  permissionId = null
  permissions = []

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
    this.permission = data.permission && new PermissionModel(data.permission)
  }

  static encryptions() {
    return [
      {
        label: i18n.tc('user-email-account.encryption-none'),
        value: 'none',
      },
      {
        label: i18n.tc('user-email-account.encryption-auto'),
        value: 'auto',
      },
      {
        label: i18n.tc('user-email-account.encryption-ssl'),
        value: 'ssl',
      },
      {
        label: i18n.tc('user-email-account.encryption-tls'),
        value: 'tls',
      },
      {
        label: i18n.tc('user-email-account.encryption-starttls'),
        value: 'starttls',
      },
    ]
  }

  statusData() {
    switch (this.status) {
      case 'active':
        return {
          label: i18n.tc(`statuses.active`),
          badge: 'soft-success',
        }
      case 'suspended':
        return {
          label: i18n.tc(`statuses.suspended`),
          badge: 'soft-danger',
        }
    }
  }

  encryptionData() {
    switch (this.encryption) {
      case 'none':
        return i18n.tc('user-email-account.encryption-none')
      case 'auto':
        return i18n.tc('user-email-account.encryption-auto')
      case 'ssl':
        return i18n.tc('user-email-account.encryption-ssl')
      case 'tls':
        return i18n.tc('user-email-account.encryption-tls')
      case 'starttls':
        return i18n.tc('user-email-account.encryption-starttls')
    }
  }

  humanReadableSPA() {
    return this.securePasswordAuthentication ? i18n.tc('Yes') : i18n.tc('No')
  }
}
