
<div class="custom-control custom-switch" :data-testid="dataTestId" @click="$emit('toggle')">
  <input
    :id="id"
    value="0"
    type="checkbox"
    class="custom-control-input"
    :disabled="readonly"
  />
  <label class="custom-control-label" :for="id" />
</div>
