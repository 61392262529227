import { PRIMARY_CURRENCY_GETTER } from '@/Modules/Base/SettingModule.js'
import CurrencyModel from '@/Modules/Currency/CurrencyModel.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import UserModel from '@/Modules/User/UserModel.js'
import store from '@/Setup/SetupStore.js'
import _ from 'lodash'
import moment from 'moment'
import { i18n } from '@/Setup/SetupI18n.js'

export const StatementModelType = Object.freeze({
  COVERHOLDER: 'coverholder',
  AGENT: 'agent',
  INSURER: 'insurer',
})

export const StatementModelStatus = Object.freeze({
  OPEN: 'open',
  SENT: 'sent',
  PAID: 'paid',
  RECONCILED: 'reconciled',
})

export default class StatementModel {
  id = null
  name = null
  type = StatementModelType.AGENT
  currency = null
  canEdit = true
  currencyCode = store.getters[`SettingModule/${PRIMARY_CURRENCY_GETTER}`]
  description = null
  periodFrom = moment().startOf('month').toDate()
  periodTo = moment().endOf('month').toDate()
  period = null
  includedPrior = true
  status = null
  transactionsCount = null
  data = null
  confirmedAt = null

  entityId = null
  entity = null

  templateReference = null
  template = null

  creatorId = null
  creator = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additional_data
    this.entity = data.entity && new EntityModel(data.entity)
    this.creator = data.creator && new UserModel(data.creator)
    this.currency = data.currency && new CurrencyModel(data.currency)
  }

  isType(type) {
    return this.type === type
  }

  static getStatusDataByKey(key) {
    switch (key) {
      case StatementModelStatus.OPEN:
        return {
          title: i18n.tc(`statuses.open`),
          icon: 'clipboard',
          variant: 'outline-secondary',
        }
      case StatementModelStatus.SENT:
        return {
          title: i18n.tc(`statements.sent`),
          icon: 'envelope-check',
          variant: 'outline-primary',
        }
      case StatementModelStatus.PAID:
        return {
          title: i18n.tc(`statements.paid`),
          icon: 'transaction',
          variant: 'outline-success',
        }
      case StatementModelStatus.RECONCILED:
        return {
          title: i18n.tc(`statements.reconciled`),
          icon: 'arrow-random',
          variant: 'outline-success',
        }
    }
  }

  static types() {
    return [
      {
        label: i18n.tc(`common.agent`),
        value: StatementModelType.AGENT,
      },
      {
        label: i18n.tc(`entities.insurer`),
        value: StatementModelType.INSURER,
      },
    ]
  }

  static statuses() {
    return [
      {
        label: i18n.tc('statements.status-open'),
        value: StatementModelStatus.OPEN,
      },
      {
        label: i18n.tc('statements.status-sent'),
        value: StatementModelStatus.SENT,
      },
      {
        label: i18n.tc('statements.status-paid'),
        value: StatementModelStatus.PAID,
      },
      {
        label: i18n.tc('statements.status-reconciled'),
        value: StatementModelStatus.RECONCILED,
      },
    ]
  }

  getPriorObject() {
    switch (this.includedPrior) {
      case true:
        return {
          label: i18n.tc(`common.yes`),
          badge: 'soft-primary',
        }
      case false:
        return {
          label: i18n.tc(`common.no`),
          badge: 'soft-secondary',
        }
    }
  }

  getStatus() {
    switch (this.status.key) {
      case 'open':
        return {
          badge: 'soft-secondary',
          icon: 'check-circle-fill',
        }
      case 'sent':
        return {
          badge: 'soft-primary',
          icon: 'check-circle-fill',
        }
      case 'paid':
        return {
          badge: 'soft-info',
          icon: 'check-circle-fill',
        }
      case 'reconciled':
        return {
          badge: 'soft-success',
          icon: 'check-circle-fill',
        }
    }
  }

  typeData() {
    switch (this.type) {
      case 'coverholder':
        return {
          badge: 'soft-secondary',
          short: 'CH',
        }
      case 'agent':
        return {
          badge: 'soft-primary',
          short: 'AG',
        }
      case 'insurer':
        return {
          badge: 'soft-info',
          short: 'IN',
        }
    }
  }

  get localizedStatementRelatedLabel() {
    if (!this.type) {
      return ''
    }
    return i18n.tc('statements.related', undefined, {
      type: i18n.tc(`common.${this.type}`),
    })
  }
}
