
<div>
  <div v-b-toggle="collapseId" class="text-danger cursor-pointer">
    <small>{{ message }}</small>
    <Icon icon="angle-down" size="14" />
  </div>
  <b-collapse :id="collapseId">
    <pre v-html="prettyPrintJson.toHtml(rule)" />
  </b-collapse>
</div>
