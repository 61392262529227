import auth from '@/Middlewares/auth.js'
import driveDocuments from '@/Middlewares/driveDocuments.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const clientRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('clients', {
      show: () => import(/* webpackChunkName: "clients" */ './Pages/Show.vue'),
    })
      .only(['show'])
      .permissions({
        show: 'clients.view',
      })
      .resolve(),

    new Route(
      'clients.list',
      '/clients/:tab?',
      () => import(/* webpackChunkName: "clients" */ './Pages/List.vue'),
      undefined,
      true
    )
      .permission('clients.view')
      .resolve(),
    new Route('clients.documents.list', '/clients/:clientId/documents', () =>
      import(
        /* webpackChunkName: "clients" */ './ClientDocument/Pages/List.vue'
      )
    )
      .permission('clients.view')
      .resolve(),
    new Route(
      'clients.driveDocuments.list',
      '/clients/:clientId/drive-documents',
      () =>
        import(
          /* webpackChunkName: "clients" */ './ClientDocument/Pages/OneDrive/List.vue'
        ),
      driveDocuments
    )
      .permission('clients.view')
      .resolve(),
    new Route(
      'clients.documents.list.folder',
      '/clients/:clientId/documents/:folderId',
      () =>
        import(
          /* webpackChunkName: "clients" */ './ClientDocument/Pages/List.vue'
        )
    )
      .permission('clients.view')
      .resolve(),

    new Route('clients.quotes.list', '/clients/:clientId/quotes/:tab?', () =>
      import(/* webpackChunkName: "clients" */ './ClientQuote/Pages/List.vue')
    )
      .permission('clients.view')
      .resolve(),

    new Route(
      'clients.policies.list',
      '/clients/:clientId/policies/:tab?',
      () =>
        import(
          /* webpackChunkName: "clients" */ './ClientPolicy/Pages/List.vue'
        )
    )
      .permission('clients.view')
      .resolve(),

    new Route('clients.invoices.list', '/clients/:clientId/invoices', () =>
      import(/* webpackChunkName: "clients" */ './ClientInvoice/Pages/List.vue')
    )
      .permission('clients.view')
      .resolve(),

    new Route('clients.messages.list', '/clients/:clientId/messages', () =>
      import(/* webpackChunkName: "clients" */ './ClientMessage/Pages/List.vue')
    )
      .permission('clients.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()

export const persistableClientRoutes = [
  'clients.show',

  'clients.documents.list',
  'clients.documents.list.folder',
  'clients.driveDocuments.list',
  'clients.quotes.list',
  'clients.policies.list',
  'clients.invoices.list',
  'clients.messages.list',
]
