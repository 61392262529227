<template>
  <b-row align-content="end" class="align-items-end">
    <b-col>
      <h6 v-if="preTitle" class="header-pretitle">
        {{ preTitle }}
      </h6>

      <h1 class="header-title">
        {{ title }} {{ extraTitle }}
        <CopyButton
          v-if="showCopyIcon"
          :id="`item-key-${extraTitle}`"
          class="copy-button"
          :content="`${extraTitle}`"
        ></CopyButton>
        <span
          style="font-size: 0.9375rem; vertical-align: middle"
          data-testid="page-title-badge"
          class="ml-0"
        >
          <slot name="afterTitle" />
        </span>
      </h1>
      <small v-show="underTitle" class="text-muted pt-2" v-html="underTitle" />
      <div v-if="$scopedSlots.meta">
        <slot name="meta" />
      </div>
    </b-col>

    <b-col cols="auto">
      <slot />
    </b-col>

    <b-col v-if="$scopedSlots.actions" cols="auto" class="ml-n3 actions-height">
      <slot name="actions"></slot>
    </b-col>
  </b-row>
</template>

<script>
import CopyButton from '@/General/CopyButton.vue'
export default {
  name: 'PageTitle',
  components: {
    CopyButton,
  },
  props: {
    preTitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    underTitle: {
      type: String,
      default: null,
    },
    extraTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    showCopyIcon() {
      return [
        'policies.show',
        'policies.objects.list',
        'policies.documents.list',
        'policies.messages.list',
        'policies.activities.list',
        'policies.api-logs.list',
        'policies.changelogs.list',
        'quotes.show',
        'quotes.objects.list',
        'quotes.documents.list',
        'quotes.messages.list',
        'quotes.activities.list',
        'quotes.api-logs.list',
        'quotes.changelogs.list',
      ].includes(this.$route.name)
    },
  },
}
</script>
<style scope lang="scss">
.align-items-end {
  align-items: flex-end;
}
.copy-button {
  opacity: 0;
}

.header-title:hover .copy-button {
  opacity: 1;
}
</style>
