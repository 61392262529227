import SelectOptionModel from '@/Modules/Dataset/SelectOptionModel.js'
import SettingModel from '@/Modules/Setting/SettingModel.js'
import SettingService from '@/Modules/Setting/SettingService.js'
import { Loader } from '@googlemaps/js-api-loader'

export const SET_PRIMARY_CURRENCY_MUTATION = 'setPrimaryCurrency'
export const SET_DATE_FORMAT_MUTATION = 'setDateFormat'
export const SET_API_DATE_FORMAT_MUTATION = 'setApiDateFormat'
export const SET_FRONTEND_TIME_FORMAT_MUTATION = 'setFrontendTimeFormat'
export const SET_SIMPLE_PAGINATION_MUTATION = 'setSimplePagination'
export const SET_TABLE_PER_PAGE_MUTATION = 'setTablePerPage'
export const SET_DATE_TIME_FORMAT_MUTATION = 'setDateTimeFormat'
export const SET_API_DATE_TIME_FORMAT_MUTATION = 'setApiDateTimeFormat'
export const SET_TIME_ZONE_MUTATION = 'setTimeZone'
export const SET_CURRENCIES_MUTATION = 'setCurrencies'
export const SET_IS_ONE_DRIVE_ENABLED_MUTATION = 'setIsOneDriveEnabled'
export const SET_GOOGLE_PLACES_API_KEY_MUTATION = 'setGooglePlacesApiKey'
export const SET_GOOGLE_PLACES_API_LOADER_MUTATION = 'setGooglePlacesApiLoader'
export const SET_COUNTRIES_MUTATION = 'setCountries'
export const SET_DEFAULT_COUNTRY_MUTATION = 'setDefaultCountry'
export const SET_FROM_EMAIL_ADDRESS_MUTATION = 'setEmailFromAddress'
export const SET_FROM_EMAIL_NAME_MUTATION = 'setEmailFromName'
export const SET_UI_DEFAULT_LOCALE_MUTATION = 'setUiDefaultLocale'
export const SET_UI_LAZY_LOAD_QUOTE_FORM_FIELDS_MUTATION =
  'setUiLazyLoadQuoteFormFields'

export const PRIMARY_CURRENCY_GETTER = 'getPrimaryCurrency'
export const API_DATE_FORMAT_GETTER = 'getApiDateFormat'
export const SIMPLE_PAGINATION_GETTER = 'getSimplePagination'
export const TABLE_PER_PAGE_GETTER = 'getTablePerPage'
export const DATE_FORMAT_GETTER = 'getDateFormat'
export const DATE_TIME_FORMAT_GETTER = 'getDateTimeFormat'
export const API_DATE_TIME_FORMAT_GETTER = 'getApiDateTimeFormat'
export const FRONTEND_TIME_FORMAT_GETTER = 'getFrontendTimeFormat'
export const TIME_ZONE_GETTER = 'getTimeZone'
export const CURRENCIES_GETTER = 'getCurrencies'
export const GOOGLE_PLACES_API_KEY_GETTER = 'getGooglePlacesApiKey'
export const IS_ONE_DRIVE_ENABLED_GETTER = 'getIsOneDriveEnabled'
export const GOOGLE_PLACES_API_LOADER_GETTER = 'getGooglePlacesApiLoader'
export const COUNTRIES_GETTER = 'getCountries'
export const DEFAULT_COUNTRY_GETTER = 'getDefaultCountry'

export const SET_SETTINGS_MUTATION = 'setSettings'
export const SETTINGS_GETTER = 'settings'
export const GET_SETTINGS_ACTION = 'getSettings'
export const GET_GUEST_SETTINGS = 'getGuestSetings'
export const CREATE_GOOGLE_PLACES_API_LOADER = 'createGooglePlacesApiLoader'
export const EMAIL_FROM_ADDRESS = 'emailFromAddress'
export const EMAIL_FROM_NAME = 'emailFromName'
export const UI_DEFAULT_LOCALE = 'uiDefaultLocale'
export const UI_LAZY_LOAD_QUOTE_FORM_FIELDS = 'uiLazyLoadQuoteFormFields'

export const SettingModule = {
  namespaced: true,

  state: {
    primaryCurrency: null,
    dateFormat: 'YYYY-MM-DD',
    apiDateFormat: 'Y-M-D',
    dateTimeFormat: 'Y-M-D H:i:s',
    apiDateTimeFormat: 'Y-m-d h:i:s',
    frontendTimeFormat: 'h:i:s',
    timeZone: 'Europe/London',
    tablePerPage: 25,
    simplePagination: false,
    googlePlacesApiKey: '',
    googlePlaceApiLoader: null,
    isOneDriveEnabled: false,
    emailFromAddress: null,
    emailFromName: null,

    settings: [],
    currencies: [],
    countries: [],
    defaultCountry: null,
    uiDefaultLocale: 'en_US',
  },

  mutations: {
    [SET_PRIMARY_CURRENCY_MUTATION]: (state, primaryCurrency) => {
      state.primaryCurrency = primaryCurrency
    },

    [SET_DATE_FORMAT_MUTATION]: (state, dateFormat) => {
      state.dateFormat = dateFormat
    },

    [SET_DATE_TIME_FORMAT_MUTATION]: (state, dateTimeFormat) => {
      state.dateTimeFormat = dateTimeFormat
    },

    [SET_API_DATE_TIME_FORMAT_MUTATION]: (state, dateTimeFormat) => {
      state.apiDateTimeFormat = dateTimeFormat
    },

    [SET_TIME_ZONE_MUTATION]: (state, timeZone) => {
      state.timeZone = timeZone
    },

    [SET_API_DATE_FORMAT_MUTATION]: (state, dateFormat) => {
      state.apiDateFormat = dateFormat
    },

    [SET_FRONTEND_TIME_FORMAT_MUTATION]: (state, dateFormat) => {
      state.frontendTimeFormat = dateFormat
    },

    [SET_TABLE_PER_PAGE_MUTATION]: (state, tablePerPage) => {
      state.tablePerPage = tablePerPage
    },

    [SET_SIMPLE_PAGINATION_MUTATION]: (state, simplePagination) => {
      state.simplePagination = simplePagination
    },

    [SET_SETTINGS_MUTATION]: (state, settings) => {
      state.settings = settings
    },

    [SET_CURRENCIES_MUTATION]: (state, currencies) => {
      state.currencies = currencies
    },

    [SET_IS_ONE_DRIVE_ENABLED_MUTATION]: (state, isOneDriveEnabled) => {
      state.isOneDriveEnabled = isOneDriveEnabled
    },

    [SET_GOOGLE_PLACES_API_KEY_MUTATION]: (state, apiKey) => {
      state.googlePlacesApiKey = apiKey
    },

    [SET_GOOGLE_PLACES_API_LOADER_MUTATION]: (state, loaderInstance) => {
      state.googlePlacesApiLoader = loaderInstance
    },

    [SET_COUNTRIES_MUTATION]: (state, countries) => {
      state.countries = countries
    },

    [SET_DEFAULT_COUNTRY_MUTATION]: (state, defaultCountry) => {
      state.defaultCountry = defaultCountry
    },

    [SET_FROM_EMAIL_ADDRESS_MUTATION]: (state, emailFromAddress) => {
      state.emailFromAddress = emailFromAddress
    },

    [SET_FROM_EMAIL_NAME_MUTATION]: (state, emailFromName) => {
      state.emailFromName = emailFromName
    },
    [SET_UI_DEFAULT_LOCALE_MUTATION]: (state, uiDefaultLocale) => {
      state.uiDefaultLocale = uiDefaultLocale
    },
    [SET_UI_LAZY_LOAD_QUOTE_FORM_FIELDS_MUTATION]: (
      state,
      lazyLoadQuoteFormFields
    ) => {
      state.lazyLoadQuoteFormFields = lazyLoadQuoteFormFields
    },
  },

  getters: {
    [PRIMARY_CURRENCY_GETTER]: (state) => {
      return state.primaryCurrency
    },

    [DATE_FORMAT_GETTER]: (state) => {
      return state.dateFormat
    },

    [TIME_ZONE_GETTER]: (state) => {
      return state.timeZone
    },

    [DATE_TIME_FORMAT_GETTER]: (state) => {
      return state.dateTimeFormat
    },

    [API_DATE_TIME_FORMAT_GETTER]: (state) => {
      return state.apiDateTimeFormat
    },

    [FRONTEND_TIME_FORMAT_GETTER]: (state) => {
      return state.frontendTimeFormat
    },

    [API_DATE_FORMAT_GETTER]: (state) => {
      return state.apiDateFormat
    },

    [TABLE_PER_PAGE_GETTER]: (state) => {
      return state.tablePerPage
    },

    [SIMPLE_PAGINATION_GETTER]: (state) => {
      return state.simplePagination
    },

    [SETTINGS_GETTER]: (state) => {
      return state.settings
    },

    [CURRENCIES_GETTER]: (state) => {
      return state.currencies
    },

    [GOOGLE_PLACES_API_KEY_GETTER]: (state) => {
      return state.googlePlacesApiKey
    },

    [IS_ONE_DRIVE_ENABLED_GETTER]: (state) => {
      return state.isOneDriveEnabled
    },

    [GOOGLE_PLACES_API_LOADER_GETTER]: (state) => {
      return state.googlePlaceApiLoader
    },

    [COUNTRIES_GETTER]: (state) => {
      return state.countries
    },

    [DEFAULT_COUNTRY_GETTER]: (state) => {
      return state.defaultCountry
    },

    [EMAIL_FROM_ADDRESS]: (state) => {
      return state.emailFromAddress
    },

    [EMAIL_FROM_NAME]: (state) => {
      return state.emailFromName
    },
    [UI_DEFAULT_LOCALE]: (state) => {
      return state.uiDefaultLocale
    },
    [UI_LAZY_LOAD_QUOTE_FORM_FIELDS]: (state) => {
      return state.lazyLoadQuoteFormFields
    },
  },

  actions: {
    [GET_SETTINGS_ACTION]: ({ commit }) => {
      return SettingService.list().then((response) => {
        commit(
          SET_SETTINGS_MUTATION,
          _.map(response.data.data, (item) => new SettingModel(item))
        )

        const meta = response.data.meta

        if (
          undefined !== response.data.data.ui_default_locale &&
          undefined !== response.data.data.ui_default_locale.value
        ) {
          commit(
            SET_UI_DEFAULT_LOCALE_MUTATION,
            response.data.data.ui_default_locale.value
          )
        }

        if (
          undefined !== response.data.data.ui_lazy_load_quote_form_fields &&
          undefined !== response.data.data.ui_lazy_load_quote_form_fields.value
        ) {
          commit(
            SET_UI_LAZY_LOAD_QUOTE_FORM_FIELDS_MUTATION,
            response.data.data.ui_lazy_load_quote_form_fields.value
          )
        }

        if (typeof meta !== 'undefined') {
          commit(SET_PRIMARY_CURRENCY_MUTATION, meta.primaryCurrency)
          commit(SET_API_DATE_FORMAT_MUTATION, meta.apiDateFormat)
          commit(SET_FRONTEND_TIME_FORMAT_MUTATION, meta.frontendTimeFormat)
          commit(SET_TABLE_PER_PAGE_MUTATION, meta.tablePerPage)
          commit(SET_SIMPLE_PAGINATION_MUTATION, meta.simplePagination)
          commit(SET_DATE_FORMAT_MUTATION, meta.frontendDateFormat) // for moment.js date format
          if (localStorage) {
            localStorage.setItem('dateFormat', meta.frontendDateFormat)
          }
          commit(SET_DATE_TIME_FORMAT_MUTATION, meta.dateTimeFormat)
          commit(SET_API_DATE_TIME_FORMAT_MUTATION, meta.apiDateTimeFormat)
          commit(SET_TIME_ZONE_MUTATION, meta.timeZone)
          commit(SET_GOOGLE_PLACES_API_KEY_MUTATION, meta.googlePlacesApiKey)
          commit(SET_IS_ONE_DRIVE_ENABLED_MUTATION, meta.isOneDriveEnabled)
          commit(
            SET_CURRENCIES_MUTATION,
            _.map(meta.currencies, (currency) => {
              currency.label = currency.label.toUpperCase()

              return currency
            })
          )

          commit(
            SET_COUNTRIES_MUTATION,
            _.map(meta.countries, (item) => new SelectOptionModel(item))
          )
          commit(
            SET_DEFAULT_COUNTRY_MUTATION,
            _.first(
              _.filter(meta.countries, (item) => item.meta.default).map(
                (item) => new SelectOptionModel(item)
              )
            )
          )
          commit(SET_FROM_EMAIL_ADDRESS_MUTATION, meta.emailFromAddress)
          commit(SET_FROM_EMAIL_NAME_MUTATION, meta.emailFromName)
        }
      })
    },
    [GET_GUEST_SETTINGS]: ({ commit }) => {
      return SettingService.guestList().then((response) => {
        commit(
          SET_SETTINGS_MUTATION,
          _.map(response.data.data, (item) => new SettingModel(item))
        )

        const meta = response.data.meta

        if (typeof meta !== 'undefined') {
          commit(SET_API_DATE_FORMAT_MUTATION, meta.apiDateFormat)
          commit(SET_FRONTEND_TIME_FORMAT_MUTATION, meta.frontendTimeFormat)
          commit(SET_DATE_FORMAT_MUTATION, meta.frontendDateFormat) // for moment.js date format
          if (localStorage) {
            localStorage.setItem('dateFormat', meta.frontendDateFormat)
          }
          commit(SET_DATE_TIME_FORMAT_MUTATION, meta.dateTimeFormat)
          commit(SET_API_DATE_TIME_FORMAT_MUTATION, meta.apiDateTimeFormat)
          commit(SET_TIME_ZONE_MUTATION, meta.timeZone)
        }
      })
    },
    [CREATE_GOOGLE_PLACES_API_LOADER]: ({ commit, state }) => {
      if (!state.googlePlaceApiLoader) {
        const loader = new Loader({
          apiKey: state.googlePlacesApiKey,
          version: 'weekly',
          libraries: ['places'],
          language: 'en',
        })

        commit(SET_GOOGLE_PLACES_API_LOADER_MUTATION, loader)

        return loader
      }

      return state.googlePlaceApiLoader
    },
  },
}
