
<div>
  <stack-modal
    :show="dialog.show"
    :save-button="{ visible: false }"
    :cancel-button="{ visible: false }"
    :modal-class="{
      [dialog.class]: !!dialog.class,
      [modalClass]: !!modalClass,
    }"
    :style="`max-width: ${dialog.width}`"
  >
    <template slot="modal-header">
      <div class="modal-header align-items-center justify-space-between">
        <PageTitle
          v-if="dialog.title"
          :pre-title="dialog.preTitle"
          :title="dialog.title"
        />
        <div v-else>
          <Icon
            v-if="dialog.icon"
            class="mr-2 text-muted"
            :icon="dialog.icon"
          ></Icon>

          {{ dialog.label }}
        </div>
        <div>
          <template v-if="dialog.showExpand">
            <a
              v-if="expand"
              class="modal-close mr-2"
              href="#"
              @click.prevent="compressModal"
            >
              <Icon icon="compress-alt" size="18" />
            </a>
            <a
              v-else
              class="modal-close mr-2"
              href="#"
              @click.prevent="expandModal"
            >
              <Icon icon="expand-alt" size="18" />
            </a>
          </template>
          <a class="modal-close" href="#" @click.prevent="closeModal">
            <Icon icon="times" size="18" />
          </a>
        </div>
      </div>
    </template>

    <component
      :is="dialog.component"
      :meta="dialog.meta"
      :on-success="dialog.onSuccess"
      :on-close="dialog.onClose"
    ></component>

    <div slot="modal-footer"></div>
  </stack-modal>
</div>
