
<b-button
  :id="id"
  v-b-tooltip.manual
  :title="__('badges.copy_number')"
  :class="customClass"
  variant="link"
  size="small"
  @click="copyToClipboard(content, id)"
>
  <Icon icon="copy" class="mr-2" size="22" style="color: #6e84a3" />
</b-button>
