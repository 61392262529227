import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const schemaRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('schemas', {
      show: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Show.vue'),
      edit: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Edit.vue'),
      create: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Create.vue'),
    })
      .permissions({
        create: 'schemas.create',
        edit: 'schemas.update',
        show: 'schemas.view',
      })
      .meta({
        create: { back: 'schemas.list' },
        edit: { back: 'schemas.list' },
      })
      .except(['list'])
      .resolve(),

    new Route('schemas.list', '/schemas/:tab?', () =>
      import(/* webpackChunkName: "underwriting" */ './Pages/List.vue')
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.parts.list', '/schemas/:schemaId/parts', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaPart/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.binders.list', '/schemas/:schemaId/binders', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaBinder/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.workflows.list', '/schemas/:schemaId/workflows', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaWorkflow/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.logs.list', '/schemas/:schemaId/logs', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaPartVersion/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.documents.list', '/schemas/:schemaId/documents', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaDocument/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route('schemas.counters.list', '/schemas/:schemaId/counters', () =>
      import(
        /* webpackChunkName: "underwriting" */ './SchemaCounter/Pages/List.vue'
      )
    )
      .permission('schemas.view')
      .resolve(),

    new Route(
      'schemas.schema-designer.show',
      '/schemas/:schemaId/schema-designer',
      () =>
        import(
          /* webpackChunkName: "underwriting" */ './SchemaDesigner/Pages/Show.vue'
        )
    )
      .permission('schemas.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()

export const persistableSchemaRoutes = [
  'schemas.show',
  'schemas.edit',

  'schemas.parts.list',
  'schemas.binders.list',
  'schemas.workflows.list',
  'schemas.logs.list',
  'schemas.documents.list',
  'schemas.counters.list',
  'schemas.schema-designer.show',
]
