
<div v-if="isEnabled">
  <img
    id="language-popover-button"
    :alt="locale"
    :src="getLanguageIcon(locale)"
    width="20"
  />

  <b-popover
    custom-class="language-popover"
    placement="bottom"
    target="language-popover-button"
    triggers="hover"
    variant="info"
  >
    <b-list-group class="my-n3" flush>
      <b-list-group-item>
        <b-row
          v-for="language in languages"
          :key="language.name"
          class="align-items-center mb-2"
        >
          <b-col class="d-flex align-items-center">
            <img
              :alt="language.languageCode"
              :src="getLanguageIcon(language.languageCode)"
              class="mr-2"
              width="16"
            />

            <span
              :class="`cursor-pointer language ${
                locale === language.languageCode ? 'active' : ''
              }`"
              @click="changeLocale(language)"
              v-text="language.name"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-popover>
</div>
