import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export default class PermissionModel {
  id = null
  name = null
  guardName = null
  description = null
  isCustom = false

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  get badge() {
    return this.isCustom
      ? { variant: 'soft-success', label: i18n.tc(`permissions.custom`) }
      : { variant: 'soft-danger', label: i18n.tc(`permissions.system`) }
  }
}
