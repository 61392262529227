import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const emailTemplateRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('email-templates', {
      list: () => import(/* webpackChunkName: "admin" */ './Pages/List.vue'),
      create: () =>
        import(/* webpackChunkName: "admin" */ './Pages/Create.vue'),
      edit: () => import(/* webpackChunkName: "admin" */ './Pages/Edit.vue'),
    })
      .permissions({
        list: 'email-templates.view',
        create: 'email-templates.create',
        edit: 'email-templates.update',
      })
      .meta({
        list: { back: 'email-templates.list' },
        create: { back: 'email-templates.list' },
        edit: { back: 'email-templates.list' },
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
