import UiTypes from '@/Modules/Quote/Components/QuoteForm/UiTypes.js'
import applyNumberFormatting from '@/General/applyNumberFormatting.js'

export const MONEY_FIELD_PROPERTIES = Object.freeze({
  currency: 'Currency',
  formattedValue: 'FormattedValue',
  formattedCurrency: 'FormattedCurrency',
})

export const parseNodeSubValues = (
  item,
  properties,
  primaryCurrency,
  locale = 'en-US'
) => {
  _.each(item, (value, propertyKey) => {
    if (
      properties[propertyKey] &&
      typeof properties[propertyKey] !== 'string'
    ) {
      if (value && typeof value === 'object') {
        properties[propertyKey].properties = parseNodeSubValues(
          value,
          properties[propertyKey].properties,
          primaryCurrency,
          locale
        )
      }

      properties[propertyKey].value = value

      // Money fields are handled differently
      // Currency value is basically a child of a money field, but the
      // value is saved on the same data level to simplify data structure.
      if (
        [UiTypes.money, UiTypes.moneySelect].includes(
          properties[propertyKey].uiType
        )
      ) {
        const currency =
          item[propertyKey + MONEY_FIELD_PROPERTIES.currency] ?? primaryCurrency
        const formattedValue =
          item[propertyKey + MONEY_FIELD_PROPERTIES.formattedValue] ??
          applyNumberFormatting(value, locale)

        properties[propertyKey].properties.currency.value = currency
        properties[propertyKey].properties.formattedValue.value = formattedValue
      }
    }
  })

  return properties
}
