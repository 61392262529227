import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const customCSSRoutes = new RouteGroup(() => {
  return [
    new Route(
      'custom-css.show',
      '/custom-css',
      () => import('./Pages/Show.vue'),
    )
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
