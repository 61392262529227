import EntityModel from '@/Modules/Entity/EntityModel.js'
import NotificationModel from '@/Modules/Notification/NotificationModel.js'
import RoleModel from '@/Modules/Role/RoleModel.js'
import UserEmailAccountModel from '@/Modules/User/UserEmailAccount/UserEmailAccountModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const UserStatus = Object.freeze({
  inactive: 'inactive',
  pending: 'pending',
  active: 'active',
})

export const NOTIFICATION_CHANNELS = Object.freeze({
  MAIL: 'mail',
  DATABASE: 'database',
})

export const SPECIALITIES = Object.freeze({
  BROKER: 'broker',
  UNDERWRITER: 'underwriter',
})

export default class UserModel {
  id = null
  email = null
  status = null
  phone = null
  mobile = null
  address = null
  addressHouse = null
  addressUnit = null
  addressStreet = null
  addressTown = null
  addressPostCode = null
  addressCounty = null
  addressCountry = null
  notes = null
  isSupervisor = null
  notificationChannels = []
  specialities = []
  unreadNotifications = []
  unreadNotificationsCount = 0
  overdueActivitiesCount = 0
  permissions = []
  data = null
  photo = null
  position = null

  entityId = null
  entity = null
  defaultEntityId = null
  defaultEntity = null
  relatedEntities = []

  name = null
  language = null
  locale = 'en_US'
  roles = []
  tfaEnabled = false
  tfaSecret = null
  emailSignature = null
  emailAccounts = []
  position = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additional_data
    this.entity = data && data.entity && new EntityModel(data.entity)
    this.defaultEntity =
      data && data.defaultEntity && new EntityModel(data.defaultEntity)
    this.emailAccounts = _.map(
      data.emailAccounts,
      (account) => new UserEmailAccountModel(account)
    )

    this.unreadNotifications = _.map(
      this.unreadNotifications,
      (notification) => new NotificationModel(notification)
    )
    this.relatedEntities = _.map(
      this.relatedEntities,
      (entity) => new NotificationModel(entity)
    )
    this.roles = _.map(this.roles, (role) => new RoleModel(role))
  }

  statusData() {
    switch (this.status) {
      case 'pending':
        return {
          label: i18n.tc(`statuses.pending`),
          badge: 'soft-warning',
        }
      case 'active':
        return {
          label: i18n.tc(`statuses.active`),
          badge: 'soft-success',
        }
      case 'inactive':
        return {
          label: i18n.tc(`statuses.inactive`),
          badge: 'soft-danger',
        }
      default:
        return {
          label: i18n.tc(`statuses.n-a`),
          badge: 'soft-secondary',
        }
    }
  }

  transitionStatusNext() {
    switch (this.status) {
      case UserStatus.inactive:
        return UserStatus.pending
      case UserStatus.pending:
        return UserStatus.active
      default:
        return UserStatus.inactive
    }
  }

  static notificationChannels() {
    return [
      {
        value: NOTIFICATION_CHANNELS.MAIL,
        label: i18n.t('users.emails'),
      },
      {
        value: NOTIFICATION_CHANNELS.DATABASE,
        label: i18n.t('users.application'),
      },
    ]
  }

  static specialities() {
    return [
      {
        value: SPECIALITIES.BROKER,
        label: i18n.t('common.broker'),
      },
      {
        value: SPECIALITIES.UNDERWRITER,
        label: i18n.t('common.underwriter'),
      },
    ]
  }

  static specialitiesData(speciality) {
    switch (speciality) {
      case SPECIALITIES.BROKER:
        return {
          value: SPECIALITIES.BROKER,
          label: i18n.t('common.broker'),
        }
      case SPECIALITIES.UNDERWRITER:
        return {
          value: SPECIALITIES.UNDERWRITER,
          label: i18n.t('common.underwriter'),
        }
    }
  }

  static notificationChannelData(channel) {
    switch (channel) {
      case NOTIFICATION_CHANNELS.DATABASE:
        return {
          name: i18n.t('users.application'),
          badge: 'soft-success',
        }
      case NOTIFICATION_CHANNELS.MAIL:
        return {
          name: i18n.t('users.emails'),
          badge: 'soft-warning',
        }
    }
  }

  notificationChannelsImploded() {
    return (
      this.notificationChannels &&
      this.notificationChannels
        .map((channel) => {
          if (
            !_.isUndefined(UserModel.notificationChannelData(channel?.value)) &&
            UserModel.notificationChannelData(channel?.value)
          ) {
            return UserModel.notificationChannelData(channel.value).name
          }
        })
        .join(', ')
    )
  }

  specialitiesImplode() {
    return (
      this.specialities &&
      this.specialities
        .map((channel) => {
          if (
            !_.isUndefined(UserModel.specialitiesData(channel?.value)) &&
            UserModel.specialitiesData(channel?.value)
          ) {
            return UserModel.specialitiesData(channel.value).label
          }
        })
        .join(', ')
    )
  }

  getRoleNames() {
    return _.map(this.roles, (role) => role.name)
  }

  getRoleNamesImploded() {
    return this.getRoleNames().join(', ')
  }

  isCoverholder() {
    if (!_.isEmpty(this.entity)) {
      return this.entity.type === 'coverholder'
    }

    return false
  }
}
