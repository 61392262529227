import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import auth from '@/Middlewares/auth.js'

export const paymentTermRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('payment-terms', {
      list: () => import('./Pages/List.vue'),
    })
      .only(['list'])
      .permissions({
        list: 'payment-terms.view',
      })
      .resolve()
  ]
})
  .middlewares([auth])
  .resolve()
