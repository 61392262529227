import ErrorModel from '@/General/Form/ErrorModel.js'
import GeneralComponent from '@/General/Form/Mixins/GeneralComponent.js'
import TraceModeMixin from '@/General/Form/Mixins/TraceModeMixin.js'

export default {
  inject: ['$validator'],

  mixins: [GeneralComponent, TraceModeMixin],

  computed: {
    error: function () {
      let error = _.find(
        _.values(this.errors.items),
        (item) => item.field === this.dotKey
      )

      return new ErrorModel(error, this.dotKey, this.label)
    },

    highlightClass: function () {
      if (this.error instanceof ErrorModel && this.error.hasError) {
        return this.error.level
      }

      return this.highlight
    },

    validation: function () {
      let validation = {}

      if (this.required) {
        validation.required = true
      }

      if (this.regex) {
        validation.regex = this.regex
      }

      return validation
    },
  },

  props: {
    step: {
      type: String,
      default() {
        if (this.type === 'number') {
          return '1'
        }
        return null
      },
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    dropPlaceholder: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxUploadSize: {
      type: Number,
      default: -1,
    },
    labelRight: {
      type: String,
      default: null,
    },
    datamask: {
      type: String,
      default: null,
    },
    regex: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: null,
    },
    options: {
      type: Array,
    },
    type: {
      type: String,
      default: 'text',
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    noCalendar: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    autosize: {
      type: Boolean,
      default: null,
    },
    classColumn: {
      type: String,
      default: null,
    },
    highlight: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipClass: {
      type: String,
      default: null,
    },
    dataTestId: {
      type: String,
      default: null,
    },
    precision: {
      type: Number,
      default() {
        if (this.type === 'number') {
          return 2
        }
        return null
      },
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    uiRulesError: {
      type: Object,
      default: null,
    },
    fastInput: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    applyMinMax(value) {
      switch (true) {
        case parseFloat(value) >= parseFloat(this.min) &&
          parseFloat(value) <= parseFloat(this.max):
          return value
        case parseFloat(value) < parseFloat(this.min):
          return this.min
        case parseFloat(value) > parseFloat(this.max):
          return this.max
        default:
          return value
      }
    },
  },
}
