import DatasetService from '@/Modules/Dataset/DatasetService.js'
import { UPDATE_BASE_NODE } from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import { mapActions } from 'vuex'

export default {
  computed: {
    canApplyInitialUiMapping: function () {
      return (
        this.$route.name === 'quotes.create' &&
        Object.keys(this.node.uiMapping).length > 0
      )
    },

    allowEmpty() {
      if (!this.node.uiProperties) {
        return true
      }
      if (this.node.uiProperties.allowEmpty !== undefined) {
        return this.node.uiProperties.allowEmpty
      }
      return true
    },
  },

  created() {
    if (
      this.index &&
      this.baseNode &&
      typeof this.getNodePath === 'function' &&
      _.has(this.baseNode.dependables, this.getNodePath())
    ) {
      // Only apply options filtering to dependent nodes in multipleSection
      this.filterNodeOptions()
    }

    if (this.canApplyInitialUiMapping) {
      this.applyUiMappingWithDefaultValues()
    }
  },

  watch: {
    'node.value': {
      async handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          await this.selectChanged(newValue)
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('QuoteFormModule', {
      updateBaseNode: UPDATE_BASE_NODE,
    }),

    filterNodeOptions() {
      if (!this.node.value) {
        this.node.options = []
        return
      }

      const value = _.isArray(this.node.value)
        ? this.node.value
        : [
            _.has(this.node.value, 'value')
              ? this.node.value.value
              : this.node.value,
          ]

      this.node.options = this.node.options.filter((option) =>
        value.includes(option?.value)
      )
    },

    applyUiMappingWithDefaultValues() {
      const found = this.node.options.find(
        (item) => item.value === this.node.value
      )

      if (!found?.meta) {
        return
      }

      let baseNode = _.cloneDeep(this.baseNode)

      _.each(this.node.uiMapping, (value, key) => {
        const nodePath = key.replace(/\./g, '.properties.')
        const valueKey = value.split('.').join('.')
        const clientValue = _.get(found.meta, valueKey)
        const actualValue = _.get(baseNode.properties, `${nodePath}.value`)

        if (!actualValue) {
          _.set(baseNode.properties, `${nodePath}.value`, clientValue)
        }
      })

      this.updateBaseNode(baseNode)
    },

    async selectChanged(value) {
      if (!_.isEmpty(this.node.dependable)) {
        await DatasetService.listOptionsByTagAndValue(
          this.node.dependable.options,
          this.node.dependable.parameter,
          value?.value || value
        )
          .then((selectOptions) => {
            this.setDependableOptions(selectOptions)
          })
          .catch((error) => {
            console.error(error)
          })
      }

      this.$emit('onChange', value)

      if (this.node?.datasetTag && !this.node.options.length) {
        this.loadOptionsIfNotLoaded(this.node.datasetTag)
      }

      await this.updateBaseNodeFromMeta(value && value.meta)
    },

    setDependableOptions(selectOptions) {
      if (!selectOptions) {
        return
      }

      let baseNode = _.cloneDeep(this.baseNode)

      let pathToDependentNode = this.node.dependable.target.replace(
        /\./g,
        '.properties.'
      )

      /**
       * Set same node index to target path in multiple section row field
       */
      if (this.index) {
        pathToDependentNode = pathToDependentNode.replace(
          '.properties.{0}.properties',
          `.rows.${this.index}`
        )
      }

      _.set(
        baseNode,
        `properties.${pathToDependentNode}.options`,
        selectOptions
      )

      const dependableNodeValue = _.get(
        baseNode,
        `properties.${pathToDependentNode}.value`
      )
      const hasValueSelected = !!selectOptions.filter(
        (item) => item.value === dependableNodeValue
      ).length

      /**
       * If node to set has value set and it already matches dataset options, dont set it to null
       */
      if (!hasValueSelected) {
        _.set(baseNode, `properties.${pathToDependentNode}.value`, null)
      }

      if (selectOptions.length === 1) {
        _.set(
          baseNode,
          `properties.${pathToDependentNode}.value`,
          selectOptions[0]
        )
      }

      /**
       * To access selected broker entity in uiTypeUser really
       */
      _.set(
        baseNode,
        `properties.${pathToDependentNode}.optionsSourceNodeValue`,
        {
          [this.node.dependable.parameter]: _.isObject(this.node.value)
            ? this.node.value.value
            : this.node.value,
        }
      )

      if (
        baseNode.dependables &&
        baseNode.dependables[this.node.dependable.target]
      ) {
        baseNode.dependables[this.node.dependable.target].value = _.isObject(
          this.node.value
        )
          ? this.node.value.value
          : this.node.value
      }

      this.updateBaseNode(baseNode)
    },
  },
}
