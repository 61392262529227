import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const productRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('products', {
      list: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/List.vue'),
    })
      .permissions({
        list: 'products.view',
      })
      .only(['list'])
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
