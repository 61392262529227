<template>
  <div v-if="visible" :id="name" class="form-group" :class="classColumn">
    <div class="d-flex align-items-center justify-content-between">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
      <FieldHint v-if="hint" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <textarea
      :id="id || dotKey"
      v-validate="validation"
      v-b-tooltip.hover="{
        html: true,
        customClass: tooltipClass,
        title: tooltip,
      }"
      :value="value"
      :name="name"
      :data-vv-name="dotKey"
      :rows="rows"
      :readonly="readonly"
      :placeholder="placeholder"
      :class="{
        'form-control': true,
        [customClass]: !!customClass,
        readonly: readonly,
        'is-invalid': hasErrors,
        [`is-${highlightClass}`]: !!highlightClass,
      }"
      :style="{ 'min-height': minHeight }"
      @input="emitChange"
    ></textarea>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'

export default {
  name: 'TextAreaField',

  mixins: [InputComponent],

  props: {
    minHeight: {
      type: String,
      default: '85px',
    },
  },

  methods: {
    emitChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('onChange', event.target.value)
    },
  },
}
</script>
<style scoped>
.form-control {
  max-height: 600px;
}
</style>
