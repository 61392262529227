<template>
  <div
    v-if="visible"
    :id="id"
    :class="{ 'form-group': true, [customClass]: !!customClass }"
  >
    <div class="row">
      <div class="col">
        <TraceLabel
          :node="quoteNode"
          :label="label"
          :required="required"
          :name="name"
        />
      </div>

      <div class="col-auto">
        <slot name="labelRight"></slot>
        <FieldHint v-if="hint" :text="hint" />
      </div>
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div class="input-group" :class="{ 'input-group-merge': !!icon }">
      <input
        :id="id || dotKey"
        ref="lookUpField"
        v-model="model"
        v-validate="validation"
        autocomplete="off"
        :data-vv-name="dotKey"
        :type="type"
        v-bind="$attrs"
        :name="name"
        :placeholder="placeholder"
        :disabled="readonly"
        :class="{
          'form-control': true,
          'form-control-appended': !!icon,
          readonly: readonly,
          'is-invalid': hasErrors,
          [inputClass]: !!inputClass,
        }"
      />

      <div v-if="icon" class="input-group-append">
        <span :class="{ 'input-group-text': icon, readonly: readonly }">
          <i :class="icon"></i>
        </span>
      </div>

      <slot name="append"></slot>
    </div>

    <div v-if="apiError" class="invalid-feedback mb-1">
      {{ apiError }}
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import { mapGetters, mapActions } from 'vuex'
import {
  CREATE_GOOGLE_PLACES_API_LOADER,
  GOOGLE_PLACES_API_LOADER_GETTER,
} from '@/Modules/Base/SettingModule.js'

export default {
  name: 'LookUpField',

  mixins: [InputComponent],

  props: {
    options: Array,
  },

  data() {
    return {
      autoComplete: null,
      apiError: '',
      lookUpMap: {
        building: 'street_number',
        street: 'route',
        town: ['locality', 'postal_town'],
        county: ['administrative_area_level_1', 'administrative_area_level_2'],
        country: 'country',
        postCode: 'postal_code',
      },
      lookUpResult: {},
    }
  },

  computed: {
    ...mapGetters('SettingModule', {
      googlePlacesApiLoader: GOOGLE_PLACES_API_LOADER_GETTER,
    }),
  },

  created() {
    this.createGooglePlacesApiLoader().then((loader) => {
      loader.load().then(() => {
        this.autoComplete = new google.maps.places.Autocomplete(
          this.$refs['lookUpField'],
          this.options
        )
        this.autoComplete.addListener('place_changed', this.fillAddress)
      })
    })
  },

  methods: {
    ...mapActions('SettingModule', {
      createGooglePlacesApiLoader: CREATE_GOOGLE_PLACES_API_LOADER,
    }),

    fillAddress() {
      const place = this.autoComplete.getPlace()

      this.model = place.formatted_address

      if (
        place &&
        _.isArray(place.address_components) &&
        place.address_components.length > 0
      ) {
        this.lookUpResult = {}
        this.iterate(Object.entries(this.lookUpMap), place.address_components)

        this.emitLookUpObject(this.lookUpResult)
        return
      }

      this.apiError = this.__('fields.lookup-field-api-error')
    },

    emitLookUpObject(object) {
      this.$emit('locationSelected', object)
    },

    iterate(iterable, addressComponents) {
      for (const [key, value] of iterable) {
        const configObject = this.lookUpMap[key]

        if (Array.isArray(configObject)) {
          this.iterateArray(configObject, key, addressComponents)
          continue
        }

        const found = addressComponents.find((addressComponent) =>
          addressComponent.types.includes(value)
        )

        if (found && found.long_name) {
          this.lookUpResult[key] = found.long_name
        }
      }
    },

    iterateArray(iterable, key, addressComponents) {
      iterable.forEach((element) => {
        const found = addressComponents.find((addressComponent) =>
          addressComponent.types.includes(element)
        )

        if (!found) {
          return
        }

        if (key === 'county') {
          if (!this.lookUpResult[key]) {
            this.lookUpResult[key] = {}
          }

          this.lookUpResult[key][element] = found
          return
        }

        this.lookUpResult[key] = found.long_name
      })
    },
  },
}
</script>

<style scoped>
</style>
