import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { i18n } from '@/Setup/SetupI18n.js'

export default class WorkflowModel {
  id = null
  name = null
  reference = uuidv4()
  version = 1
  status = 'draft'
  isDefault = false
  editAction = false
  newVersionAction = false
  publishAction = false
  cancelAction = false
  structure = null
  canDelete = false
  createdAt = null
  updatedAt = null

  versions = []

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.versions =
      data.versions &&
      _.map(data.versions, version => new WorkflowModel(version))
    this.previous = data.previous && new WorkflowModel(data.previous)
  }

  isActive = () => this.status === 'published'
  isDraft = () => this.status === 'draft'

  statusData() {
    switch (this.status) {
      case 'draft':
        return {
          label: i18n.tc(`statuses.draft`),
          badge: 'soft-secondary',
        }
      case 'published':
        return {
          label: i18n.tc(`statuses.active`),
          badge: 'soft-success',
        }
      case 'canceled':
        return {
          label: i18n.tc(`statuses.canceled`),
          badge: 'soft-danger',
        }
    }
  }

  static getStructureNodeColor(className = '') {
    switch (className) {
      case 'badge-soft-primary':
        return '#d5e5fa'
      case 'badge-soft-success':
        return '#ccf7e5'
      case 'badge-soft-info':
        return '#d7eff6'
      case 'badge-soft-warning':
        return '#fdf3d9'
      case 'badge-soft-danger':
        return '#fdf3d9'
      case 'badge-soft-dark':
        return '#d0d4d9'
      default:
        return '#e2e6ed'
    }
  }

  get diagramData() {
    const nodeDataArray = _.keys(this.structure).reduce((acc, key) => {
      const node = {
        key,
        text: this.structure[key].name,
        color: WorkflowModel.getStructureNodeColor(this.structure[key].class),
      }
      acc.push(node)
      return acc
    }, [])

    const linkDataArray = _.keys(this.structure).reduce((acc, key) => {
      const { next } = this.structure[key] || {}
      if (_.isArray(next)) {
        next.forEach(item => {
          const link = { from: key, to: item }
          acc.push(link)
        })
      }

      return acc
    }, [])

    return {
      nodeDataArray,
      linkDataArray,
    }
  }
}
