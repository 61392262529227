<template>
  <div
    v-if="visible"
    :id="id"
    :class="{ 'form-group': true, [customClass]: !!customClass }"
  >
    <div class="d-flex align-items-center justify-content-between">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
      <FieldHint v-if="hint" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <input v-model="numberValue" type="hidden" :name="name" />

    <div class="input-group" :class="{ 'input-group-merge': !!icon }">
      <currency-input
        :id="id || dotKey"
        ref="currencyInput"
        v-model="model"
        v-validate="validation"
        v-b-tooltip.hover="{
          html: true,
          customClass: tooltipClass,
          title: tooltip,
        }"
        :locale="getCanonicalLocale()"
        :value-range="inputMinMax"
        :precision="precision"
        :currency="null"
        :data-vv-name="dotKey"
        :type="type"
        v-bind="$attrs"
        :placeholder="placeholder"
        :disabled="readonly"
        :class="{
          'form-control': true,
          'form-control-appended': !!icon,
          readonly: readonly,
          'is-invalid': hasErrors,
          [inputClass]: !!inputClass,
          [`is-${highlight}`]: !!highlight,
        }"
        @keydown="onKeyDown"
        @input="emitChange"
        @blur="$emit('change', $event)"
      />

      <div v-if="icon" class="input-group-append">
        <span :class="{ 'input-group-text': icon, readonly: readonly }">
          <i :class="icon"></i>
        </span>
      </div>

      <slot name="append"></slot>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import { CurrencyInput, getValue, setValue } from 'vue-currency-input'
import applyFastNumber, {
  isFastNumberKeyInput,
} from '@/General/applyFastNumber.js'

export const MIN_PRECISION = 0
export const MAX_PRECISION = 15

export default {
  name: 'CurrencyField',

  components: { CurrencyInput },

  mixins: [InputComponent],

  props: {
    precision: {
      type: Number,
      default: 2,
      validator(value) {
        return value >= MIN_PRECISION && value <= MAX_PRECISION
      },
    },
    fastInput: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    inputMinMax() {
      const min = this.min
      const max = this.max

      return {
        min: min ? min : undefined,
        max: max ? max : undefined,
      }
    },

    numberValue() {
      if (!isNaN(this.model)) {
        return this.model
      }

      if (!this.$refs.currencyInput) {
        return ''
      }

      return getValue(this.$refs.currencyInput)
    },
  },

  methods: {
    emitChange(event) {
      this.$emit('input', event)
      this.$emit('onChange', event)
    },

    onKeyDown(event) {
      if (!this.$refs.currencyInput || !this.fastInput) {
        return
      }

      const key = event.key?.toLowerCase()

      if (!isFastNumberKeyInput(key)) {
        return
      }

      const value = getValue(this.$refs.currencyInput)

      const newValue = applyFastNumber(value, key)

      if (isNaN(newValue)) {
        return
      }

      setValue(this.$refs.currencyInput, newValue)
    },
  },
}
</script>
