import { CLOSE_DIALOG_ACTION } from '@/Modules/Base/BaseModule.js'

export default {
  props: {
    meta: {
      type: Object,
    },
    onSuccess: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    preventModalClose: {
      type: Boolean,
      default: false,
    },
  },

  inject: ['$validator'],

  created() {
    this.$nextTick().then(() => this.errors.clear())
  },

  destroyed() {
    this.errors.clear()
  },

  methods: {
    closeModal(successMessage = null) {
      this.$store.dispatch(`BaseModule/${CLOSE_DIALOG_ACTION}`).then(() => {
        if (successMessage && typeof successMessage === 'string') {
          this.$successToast(successMessage)
        }

        this.onClose && this.onClose()
      })
    },

    /**
     * calculate max height based on window innerHeight
     * @returns {null|string}
     */
    getModalMaxHeight(offset = 200) {
      if (window) {
        const height = window.innerHeight - offset

        return `${Math.floor(height)}px`
      }
      return null
    },
  },
}
