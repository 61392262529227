import auth from '@/Middlewares/auth.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import Route from '@/Setup/Routes/Route.js'

export const paymentRoutes = new RouteGroup(() => {
  return [
    new Route('payments.list', '/payments/:tab?', () =>
      import(/* webpackChunkName: "payments" */ './Pages/List.vue')
    )
      .permission('payments.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
