import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'

export const dataModelRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('data-models', {
      list: () => import(/* webpackChunkName: "admin" */ './Pages/List.vue'),
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .permissions({
        list: 'data-models.view',
        show: 'data-models.view',
      })
      .only(['list', 'show'])
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
