export const NUMBER_MULTIPLIER = Object.freeze({
  kilo: {
    key: 'k',
    value: 1000,
  },
  mega: {
    key: 'm',
    value: 1000000,
  },
  giga: {
    key: 'b',
    value: 1000000000,
  },
})

export const isFastNumberKeyInput = (key = '') => {
  if (
    [
      NUMBER_MULTIPLIER.kilo.key,
      NUMBER_MULTIPLIER.mega.key,
      NUMBER_MULTIPLIER.giga.key,
    ].includes(key)
  ) {
    return true
  }

  return false
}

export default function applyFastNumber(value, multiplier = '') {
  if (isNaN(value) || !multiplier) {
    return
  }

  switch (multiplier.toLowerCase()) {
    case NUMBER_MULTIPLIER.kilo.key:
      return value * NUMBER_MULTIPLIER.kilo.value
    case NUMBER_MULTIPLIER.mega.key:
      return value * NUMBER_MULTIPLIER.mega.value
    case NUMBER_MULTIPLIER.giga.key:
      return value * NUMBER_MULTIPLIER.giga.value
    default:
      return value
  }
}
