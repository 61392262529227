import { AuthModule } from '@/Modules/Auth/AuthModule.js'
import { BaseModule } from '@/Modules/Base/BaseModule.js'
import { SettingModule } from '@/Modules/Base/SettingModule.js'
import { InvoiceModule } from '@/Modules/Invoice/InvoiceModule.js'
import { QuoteFormModule } from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import { SchemaModule } from '@/Modules/Schema/SchemaModule.js'
import { ClientModule } from '@/Modules/Client/ClientModule.js'
import { StatementModule } from '@/Modules/Statement/StatementModule.js'
import { PaymentMappingModule } from '@/Modules/Payment/PaymentMappingModule.js'
import { SchemaDesignerModule } from '@/Modules/Schema/SchemaDesigner/SchemaDesignerModule.js'
import { TranslationLibraryModule } from '@/Modules/TranslationLibrary/TranslationLibraryModule.js'
import Vue from 'vue'
import Vuex from 'vuex'
import { UserModule } from '@/Modules/User/UserModule.js'

Vue.use(Vuex)

let store = {}

try {
  store = new Vuex.Store({
    modules: {
      AuthModule,
      SettingModule,
      BaseModule,
      SchemaModule,
      QuoteFormModule,
      ClientModule,
      InvoiceModule,
      PaymentMappingModule,
      SchemaDesignerModule,
      TranslationLibraryModule,
      StatementModule,
      UserModule,
    },
  })
} catch (error) {
  if (import.meta.env.NODE_ENV !== 'test') {
    console.error(error)
  }
}

export default store
