import UserModel from '@/Modules/User/UserModel.js'
import axios from 'axios'

export default class UserService {
  static list = ({ parameters, filters }) =>
    axios.get(`/users${filters}`, { params: parameters, excludeLoader: true })

  static create = payload =>
    axios
      .post(`/users`, payload)
      .then(response => new UserModel(response.data?.data))

  static changeStatus = (userId, payload) =>
    axios.post(`/users/${userId}/change-status`, payload)

  static update = (userId, payload) =>
    axios
      .put(`/users/${userId}`, payload)
      .then(response => new UserModel(response.data?.data))

  static toggleActivation = userId =>
    axios.post(`/users/${userId}/toggle-activation`)

  static delete = userId => axios.delete(`/users/${userId}`)

  static show = userId =>
    axios.get(`users/${userId}`).then(response => ({
      user: new UserModel(response.data.user),
      tabCounts: response.data.tabCounts,
    }))

  static updatePassword = (userId, payload) =>
    axios.post(`users/${userId}/update-password`, payload)

  static getUnrelatedEntities = userId =>
    axios.get(`/user/${userId}/entities/unattached`)

  static attachRelatedEntity = (userId, payload) =>
    axios.post(`/user/${userId}/entities `, payload)

  static getRelatedEntities = (userId, { parameters }) =>
    axios.get(`/user/${userId}/entities`, {
      params: parameters,
      excludeLoader: true,
    })

  static detachRelatedEntity = (userId, payload) =>
    axios.delete(`/user/${userId}/entities/${payload?.entityId}`, payload)

  static changeSelectedEntity = (userId, entityId) =>
    axios.put(`user/${userId}/entities/${entityId}`)

  static getBySpecificPermissions = permissions =>
    axios
      .get(`users/by-permissions?permissions=${permissions}`)
      .then(response => _.map(response.data.data, item => new UserModel(item)))

  static sendRegistrationEmail = userId =>
    axios.post(`users/${userId}/send-registration-email`)

  static sendRegistrationEmails = userIds =>
    axios.post(`users/send-registration-emails`, userIds)

  static updateData = (userId, data) =>
    axios.put(`users/${userId}/update-data`, data)

  static uploadPhoto = (userId, payload) =>
    axios.post(`users/${userId}/photo`, payload)
}
