import PermissionModel from '@/Modules/Permission/PermissionModel.js'
import camelcase from 'camelcase'
import _ from 'lodash'

export default class RoleModel {
  id = null
  name = null
  permissionsCount = null
  permissionId = null

  permissions = []

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })

    this.permissions =
      data.permissions &&
      _.map(data.permissions, (permission) => new PermissionModel(permission))
  }

  getPermissions() {
    return {
      permissions: this.permissions
        .slice(0, 20)
        .map((permission) => permission.name)
        .join(', '),
      leftCount:
        this.permissions.length > 20 ? this.permissions.length - 20 : 0,
    }
  }
}
