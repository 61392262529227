import SchemaModel from '@/Modules/Schema/SchemaModel.js'
import SchemaService from '@/Modules/Schema/SchemaService.js'

// Getters
export const AVAILABLE_SCHEMAS_GETTER = 'availableSchemas'
export const SCHEMA_GETTER = 'schema'
export const TAB_COUNTS_GETTER = 'tabCounts'
export const VERSIONS_GETTER = 'versions'
export const META_GETTER = 'meta'

// Mutations
export const SET_AVAILABLE_SCHEMAS_MUTATION = 'setAvailableSchemas'
export const RESET_AVAILABLE_SCHEMAS_MUTATION = 'resetAvailableSchemas'
export const SET_SCHEMA_MUTATION = 'setSchema'
export const RESET_SCHEMA_MUTATION = 'resetSchema'
export const SET_TAB_COUNTS = 'setTabCounts'
export const SET_VERSIONS = 'setVersions'
export const SET_META = 'setMeta'

// Actions
export const GET_AVAILABLE_SCHEMAS_ACTION = 'getAvailableSchemas'
export const GET_SCHEMA_ACTION = 'getSchema'
export const INCREASE_TAB_COUNT = 'increaseTabCount'
export const DECREASE_TAB_COUNT = 'decreaseTabCount'

export const SchemaModule = {
  namespaced: true,

  state: {
    availableSchemas: null,
    schema: new SchemaModel({
      product: {},
      previousSchema: {},
      workflow: {},
      coverholder: {},
    }),
    tabCounts: {},
    versions: [],
    meta: null,
  },

  mutations: {
    [SET_AVAILABLE_SCHEMAS_MUTATION]: (state, availableSchemas) => {
      state.availableSchemas = availableSchemas
    },

    [RESET_AVAILABLE_SCHEMAS_MUTATION]: (state) => {
      state.availableSchemas = null
    },

    [SET_SCHEMA_MUTATION]: (state, schema) => {
      state.schema = schema
    },

    [RESET_SCHEMA_MUTATION]: (state) => {
      state.schema = new SchemaModel({
        product: {},
        previousSchema: {},
        workflow: {},
        coverholder: {},
      })
      state.tabCounts = {}
      state.versions = []
    },

    [SET_TAB_COUNTS]: (state, tabCounts) => {
      state.tabCounts = tabCounts
    },

    [SET_VERSIONS]: (state, versions) => {
      state.versions = versions
    },

    [SET_META]: (state, meta) => {
      state.meta = meta
    },
  },

  getters: {
    [AVAILABLE_SCHEMAS_GETTER]: (state) => {
      return state.availableSchemas
    },

    [SCHEMA_GETTER]: (state) => {
      return state.schema
    },

    [TAB_COUNTS_GETTER]: (state) => {
      return state.tabCounts
    },

    [VERSIONS_GETTER]: (state) => {
      return state.versions
    },

    [META_GETTER]: (state) => {
      return state.meta
    },
  },

  actions: {
    [GET_AVAILABLE_SCHEMAS_ACTION]: ({ commit }) => {
      SchemaService.publishedAvailable({ filters: '', parameters: {} }).then(
        (response) =>
          commit(
            SET_AVAILABLE_SCHEMAS_MUTATION,
            _.map(response.data.data, (schema) => new SchemaModel(schema))
          )
      )
    },

    [GET_SCHEMA_ACTION]: ({ commit }, schemaId) => {
      SchemaService.show(schemaId).then((schemaData) => {
        commit(SET_SCHEMA_MUTATION, new SchemaModel(schemaData.schema))
        commit(SET_TAB_COUNTS, schemaData.tabCounts)
        commit(SET_META, schemaData.meta)
        commit(
          SET_VERSIONS,
          _.map(schemaData.versions, (version) => new SchemaModel(version))
        )
      })
    },

    [INCREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) + 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [DECREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) - 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },
  },
}
