<template>
  <div
    v-if="!localStorage.getItem('expired_session')"
    class="
      d-flex
      align-items-center
      bg-auth
      border-top border-top-2 border-primary
    "
    style="height: 100vh"
  >
    <b-container>
      <div class="row align-items-center">
        <div
          class="
            col-12 col-md-6
            offset-xl-2 offset-md-1
            order-md-2
            mb-5 mb-md-0
          "
        >
          <div class="text-center">
            <img
              src="@/Assets/images/illustrations/error.svg"
              alt="..."
              class="img-fluid"
            />
          </div>
        </div>

        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <div class="text-center">
            <h6 class="text-uppercase text-muted mb-4">
              {{ __('error.server-title') }}
            </h6>

            <h1 class="display-4 mb-3">
              {{ __('error.server-issues') }}
            </h1>

            <p class="text-muted mb-4">
              {{ __('error.server-again') }}
            </p>

            <a href="/" class="btn btn-lg btn-primary">
              {{ __('error.server-button-again') }}
            </a>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',

  data() {
    return {
      localStorage: localStorage,
    }
  },
}
</script>
