import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import Route from '@/Setup/Routes/Route.js'

export const binderRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('binders', {
      list: () =>
        import(
          /* webpackChunkName: "underwriting" */ './MasterBinder/Pages/List.vue'
        ),
      create: () =>
        import(
          /* webpackChunkName: "underwriting" */ './MasterBinder/Pages/Create.vue'
        ),
      edit: () =>
        import(
          /* webpackChunkName: "underwriting" */ './MasterBinder/Pages/Edit.vue'
        ),
      show: () =>
        import(
          /* webpackChunkName: "underwriting" */ './MasterBinder/Pages/Show.vue'
        ),
    })
      .permissions({
        create: 'binders.create',
        edit: 'binders.update',
        show: 'binders.view',
        list: 'binders.view',
      })
      .meta({
        create: { back: 'binders.list' },
        edit: { back: 'binders.list' },
      })
      .resolve(),

    ...new ResourceRoute('binders.childs', {
      create: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/Pages/Create.vue'
        ),
      edit: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/Pages/Edit.vue'
        ),
      show: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/Pages/Show.vue'
        ),
    })
      .permissions({
        create: 'binders.create',
        edit: 'binders.update',
        show: 'binders.view',
      })
      .meta({
        create: { back: 'binders.show' },
        edit: { back: 'binders.show' },
      })
      .except(['list'])
      .resolve(),

    ...new ResourceRoute('binders.childs.documents', {
      list: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/BinderDocument/Pages/List.vue'
        ),
    })
      .permissions({
        create: 'binders.documents.view',
      })
      .only(['list'])
      .resolve(),

    new Route(
      'binders.childs.documents.list.folder',
      '/binders/:binderId/childs/:childId/documents/:folderId',
      () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/BinderDocument/Pages/List.vue'
        )
    )
      .permission('binders.documents.view')
      .resolve(),

    ...new ResourceRoute('binders.childs.sections', {
      create: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/BinderSection/Pages/Create.vue'
        ),
      edit: () =>
        import(
          /* webpackChunkName: "underwriting" */ './ChildBinder/BinderSection/Pages/Edit.vue'
        ),
    })
      .permissions({
        create: 'binders.create',
        edit: 'binders.update',
      })
      .meta({
        create: { back: 'binders.childs.show' },
        edit: { back: 'binders.childs.show' },
      })
      .except(['list', 'show'])
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
