import camelcase from 'camelcase'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export default class DatasetModel {
  id = null
  type = null
  version = 1
  description = null
  data = false
  integration = false
  groupDatasetId = null
  createdAt = null
  updatedAt = null

  versions = []
  groupDataset = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })

    this.versions =
      data.versions &&
      _.map(data.versions, (version) => new DatasetModel(version))
    this.groupDataset =
      data.group_dataset && new DatasetModel(data.group_dataset)
  }

  static types() {
    return [
      {
        label: i18n.tc('datasets.key-value'),
        value: 'by_key_value',
      },
      {
        label: i18n.tc('datasets.value'),
        value: 'by_values',
      },
    ]
  }

  typeData() {
    switch (this.type) {
      case 'by_key_value':
        return {
          label: i18n.tc('datasets.key-value'),
          badge: 'soft-secondary',
        }
      case 'by_values':
        return {
          label: i18n.tc('datasets.value'),
          badge: 'soft-primary',
        }
    }
  }
}
