
<div
  v-if="visible"
  :id="id || dotKey"
  :class="{
    'form-group': true,
    [customClass]: !!customClass,
  }"
>
  <div class="d-flex justify-content-between align-items-center w-100">
    <TraceLabel
      :node="quoteNode"
      :label="label"
      :required="required"
      :name="id"
    />
    <small v-if="annotation" class="text-muted">
      {{ annotation }}
    </small>
    <FieldHint v-if="hint" :text="hint" />
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <div
    class="d-flex"
    :class="{
      'multiselect-appended': !!$slots['append'] && !disableAppend,
    }"
  >
    <b-btn
      v-if="allowSelectAll"
      class="mr-2 nowrap"
      variant="primary"
      @click="toggleAll()"
    >
      Toggle All
    </b-btn>
    <multiselect
      :id="id"
      ref="multiselect"
      v-model="model"
      v-validate="validation"
      v-b-tooltip.hover="{
        html: true,
        customClass: tooltipClass,
        title: tooltip,
      }"
      :open-direction="openDirection"
      :is-money-select="isMoneySelect"
      :is-pagination-select="isPaginationSelect"
      :precision="precision"
      :data-vv-name="dotKey"
      :value="model"
      :disabled="readonly"
      :name="name"
      :class="{
        readonly: readonly,
        'is-invalid': hasErrors,
        'fixed-height': !!multiple,
        [`is-${highlightClass}`]: !!highlightClass,
      }"
      :multiple="multiple"
      :label="itemLabel"
      :track-by="trackBy"
      :options="options"
      :placeholder="placeholder"
      select-label=""
      deselect-label=""
      selected-label=""
      :preserve-search="preserveSearch"
      :close-on-select="closeOnSelect"
      :allow-empty="allowEmpty"
      :custom-label="customLabel"
      :preselect-first="firstByDefault"
      :taggable="taggable"
      :loading="isLoading"
      @input="emitChange"
      @search-change="$emit('searchChange', $event)"
      @tag="addTag"
      @open="$emit('onOpen', $event)"
    />

    <slot name="append" />
  </div>

  <input v-if="!multiple" :value="selectValue" type="hidden" :name="name" />

  <template v-for="(item, iteration) in selectValue" v-else>
    <input :key="iteration" :value="item" type="hidden" :name="name + '[]'" />
  </template>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>

  <UiRulesError
    v-if="uiRulesError && traceMode"
    :id="id || dotKey"
    :message="uiRulesError.message"
    :rule="uiRulesError.rule"
  />
</div>
