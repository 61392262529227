<template>
  <div>
    <stack-modal
      :show="dialog.show"
      :save-button="{ visible: false }"
      :cancel-button="{ visible: false }"
      :modal-class="{
        [dialog.class]: !!dialog.class,
        [modalClass]: !!modalClass,
      }"
      :style="`max-width: ${dialog.width}`"
    >
      <template slot="modal-header">
        <div class="modal-header align-items-center justify-space-between">
          <PageTitle
            v-if="dialog.title"
            :pre-title="dialog.preTitle"
            :title="dialog.title"
          />
          <div v-else>
            <Icon
              v-if="dialog.icon"
              class="mr-2 text-muted"
              :icon="dialog.icon"
            ></Icon>

            {{ dialog.label }}
          </div>
          <div>
            <template v-if="dialog.showExpand">
              <a
                v-if="expand"
                class="modal-close mr-2"
                href="#"
                @click.prevent="compressModal"
              >
                <Icon icon="compress-alt" size="18" />
              </a>
              <a
                v-else
                class="modal-close mr-2"
                href="#"
                @click.prevent="expandModal"
              >
                <Icon icon="expand-alt" size="18" />
              </a>
            </template>
            <a class="modal-close" href="#" @click.prevent="closeModal">
              <Icon icon="times" size="18" />
            </a>
          </div>
        </div>
      </template>

      <component
        :is="dialog.component"
        :meta="dialog.meta"
        :on-success="dialog.onSuccess"
        :on-close="dialog.onClose"
      ></component>

      <div slot="modal-footer"></div>
    </stack-modal>
  </div>
</template>

<script>
import { DIALOG_GETTER, CLOSE_DIALOG_ACTION } from '@/Modules/Base/BaseModule.js'
import { mapGetters } from 'vuex'
import StackModal from '@innologica/vue-stackable-modal'

export default {
  name: 'Modal',

  components: { StackModal },

  inject: ['$validator'],

  data() {
    return {
      modalClass: '',
      expand: false,
    }
  },

  computed: {
    ...mapGetters('BaseModule', {
      dialog: DIALOG_GETTER,
    }),
  },

  methods: {
    async closeModal() {
      ;(await this.dialog.onClose) && this.dialog.onClose()
      await this.$store.dispatch(`BaseModule/${CLOSE_DIALOG_ACTION}`)
      this.compressModal()
    },

    expandModal() {
      this.expand = true
      this.modalClass = 'modal-fs'
    },

    compressModal() {
      this.expand = false
      this.modalClass = ''
    },
  },
}
</script>

<style lang="scss">
.modal-close {
  color: #95aac9;
  text-shadow: none;
  opacity: 0.5;
  &:hover {
    opacity: 0.75;
    i {
      color: #95aac9;
    }
  }
}
.modal-md {
  max-width: 1000px;
}

.modal-sm {
  max-width: 500px;
}

.modal-lg {
  max-width: 1300px;
}

.modal-fs {
  max-width: calc(100% - 50px);

  .modal-content {
    height: 95vh; //fallback
    height: calc(100vh - 20px * 2);
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header {
    height: 60px;
  }
}

.modal-minimal-light {
  &.modal-fs {
    .modal-header {
      height: auto;
    }
  }

  .modal-content {
    overflow: hidden;
  }

  .modal-body {
    background-color: #f9fbfd;
    padding-top: 0;
  }

  .modal-header {
    background-color: #f9fbfd;
    border: 0;
    padding-top: 24px;
    padding-bottom: 0;

    .modal-close {
      position: absolute;
      z-index: 1;
      right: 20px;
      top: 15px;
    }
  }
}

.modal-body {
  .table-responsive {
    max-height: calc(100vh - 500px);
    overflow-y: scroll;
  }
}

.modal-dialog {
  margin: 20px 50px 0 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%) !important;
}

.modal.fade {
  .modal-dialog {
    transition: width 0.3s, max-width 0.3s, transform 0.3s ease-out;
  }
}

@media (min-width: 1537px) {
  .modal-dialog {
    margin: 0 50px 0 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
