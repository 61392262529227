import _ from 'lodash'

import pluralize from 'pluralize'

const RESOURCE_ROUTE_SHOW = 'show'
const RESOURCE_ROUTE_CREATE = 'create'
const RESOURCE_ROUTE_EDIT = 'edit'

export default class {
  middleware = []
  name = ''
  path = ''
  components = {}
  levels = []
  exceptAllowed = []
  onlyAllowed = []
  customParameters = {}
  permissionWildcards = {}
  metaWildcards = {}

  constructor (name, components) {
    this.name = name
    this.components = components

    this.parseLevels()
  }

  resolve () {
    let resolvedRoutes = this.resolveAllResourceRoutes()

    if (this.onlyAllowed.length) {
      return this.applyOnlyAllowed(resolvedRoutes)
    }

    if (this.exceptAllowed.length) {
      return this.applyExceptAllowed(resolvedRoutes)
    }

    return resolvedRoutes
  }

  resolveAllResourceRoutes () {
    const parameters = this.resolveParameters()

    return [
      {
        path: this.getNotFullyQualifiedRoute(parameters),
        name: this.name + '.list',
        component: this.resolveComponent('list'),
        meta: {
          middleware: this.middleware,
          permission: this.permissionWildcards.list
        }
      },
      {
        path: this.getNotFullyQualifiedRoute(parameters) + '/' + RESOURCE_ROUTE_CREATE,
        name: this.name + '.create',
        component: this.resolveComponent('create'),
        meta: {
          middleware: this.middleware,
          permission: this.permissionWildcards.create,
          back: this.metaWildcards?.create?.back
        }
      },
      {
        path: this.getFullyQualifiedRoute(parameters) + '/' + RESOURCE_ROUTE_EDIT,
        name: this.name + '.edit',
        component: this.resolveComponent('edit'),
        meta: {
          middleware: this.middleware,
          permission: this.permissionWildcards.edit,
          back: this.metaWildcards?.edit?.back
        }
      },
      {
        path: this.getFullyQualifiedRoute(parameters) + '/' + RESOURCE_ROUTE_SHOW,
        name: this.name + '.show',
        component: this.resolveComponent('show'),
        meta: {
          middleware: this.middleware,
          permission: this.permissionWildcards.show
        }
      },
    ]
  }

  parseLevels () {
   this.levels = this.name.split('.')
  }

  middlewares(middlewares) {
    this.middleware = middlewares

    return this
  }

  permissions (permissions) {
    this.permissionWildcards = permissions

    return this
  }

  only (only) {
    this.onlyAllowed = only

    return this
  }

  except (except) {
    this.exceptAllowed = except

    return this
  }

  parameters (parameters) {
    this.customParameters = parameters

    return this
  }

  resolveParameters () {
    return _.map(this.levels, level => `${pluralize.singular(_.camelCase(level))}Id`)
  }

  getCustomParameter(parameter) {
    return _.get(this.customParameters, parameter) || parameter
  }

  getFullyQualifiedRoute (parameters) {
    let path = ''

    for (let i = 0; i < parameters.length; i++) {
      path += `/${this.levels[i]}/:${this.getCustomParameter(parameters[i])}`
    }

    return path
  }

  getNotFullyQualifiedRoute (parameters) {
    let path = ''

    for (let i = 0; i < parameters.length; i++) {
      if (parameters.length - 1 === i) {
        path += `/${this.levels[i]}`

        break;
      }

      path += `/${this.levels[i]}/:${this.getCustomParameter(parameters[i])}`
    }

    return path
  }

  applyOnlyAllowed (resolvedRoutes) {
    return _.filter(resolvedRoutes, route => {
      return this.onlyAllowed.includes(_.last(route.name.split('.')))
    })
  }

  applyExceptAllowed (resolvedRoutes) {
    return _.filter(resolvedRoutes, route => {
      return ! this.exceptAllowed.includes(_.last(route.name.split('.')))
    })
  }

  meta(meta) {
    if (_.isObject(meta)) {
      this.metaWildcards = { ...meta }
    }
    return this
  }

  resolveComponent(key) {
    if (!this.components[key]) {
      return
    }
    return _.isFunction(this.components[key])
      ? this.components[key]
      : this.components[key].default
  }
}
