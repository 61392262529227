import camelcase from 'camelcase'
import _ from 'lodash'

export default class NotificationModel {
  id = null
  data = null
  readAt = null
  createdAt = null
  humanReadable = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })
  }
}
