import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import _ from 'lodash'
export default class ShowFieldByIntersection extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)
    let conditionMet = false
    if (!uiRule.source || _.isEmpty(uiRule.parameters)) {
      return this.baseNode
    }

    const path = _.first(uiRule.source.split('.{a}'))

    const node = _.get(
      this.baseNode,
      `properties.${this.resolver.getPathToNode(path)}`
    )
    if (!node || node.uiType !== 'multipleSection') {
      return this.baseNode
    }

    const values = _.keys(node.rows).reduce((acc, key) => {
      const rowPath = uiRule.source.replace('{a}.', `rows_${key}_`)
      if (!rowPath) {
        return acc
      }
      const value = this.resolver.getValue(rowPath)
      acc.push(value)
      return acc
    }, [])

    const intersection = _.intersection(values, uiRule.parameters)
    conditionMet = !_.isEmpty(intersection)

    this.resolver.setVisibility(uiRule.target, conditionMet)
    return this.baseNode
  }
}
