import auth from '@/Middlewares/auth.js'
import driveDocuments from '@/Middlewares/driveDocuments.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const entityRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('entities', {
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .except(['list'])
      .permissions({
        show: 'entities.view',
      })
      .meta({
        create: { back: 'entities.list' },
        edit: { back: 'entities.list' },
      })
      .resolve(),

    new Route('entities.list', '/entities/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('entities.view')
      .resolve(),

    new Route('entities.documents.list', '/entities/:entityId/documents', () =>
      import(/* webpackChunkName: "admin" */ './EntityDocument/Pages/List.vue')
    )
      .permission('entities.documents.view')
      .resolve(),

    new Route(
      'entities.documents.list.folder',
      '/entities/:entityId/documents/:folderId',
      () =>
        import(
          /* webpackChunkName: "admin" */ './EntityDocument/Pages/List.vue'
        )
    )
      .permission('entities.documents.view')
      .resolve(),

    new Route('entities.products.list', '/entities/:entityId/products', () =>
      import(/* webpackChunkName: "admin" */ './EntityProduct/Pages/List.vue')
    )
      .permission('entities.products.view')
      .resolve(),

    new Route(
      'entities.driveDocuments.list',
      '/entities/:entityId/drive-documents',
      () =>
        import(
          /* webpackChunkName: "admin" */ './EntityDocument/Pages/OneDrive/List.vue'
        ),
      driveDocuments
    )
      .permission('entities.documents.view')
      .resolve(),

    new Route('entities.users.list', '/entities/:entityId/users/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './EntityUser/Pages/List.vue')
    )
      .permission('users.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
