import EndorsementModel from '@/Modules/EndorsementLibrary/Endorsement/EndorsementModel.js'
import QuoteEndorsementModel from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementModel.js'
import axios from 'axios'

export default class QuoteEndorsementService {
  static list = (quoteId, section) =>
    axios
      .get(`/quotes/${quoteId}/endorsements`, {
        params: { section, sort: 'index' },
        excludeLoader: true,
      })
      .then((response) =>
        _.map(response.data.data, (item) => new QuoteEndorsementModel(item))
      )

  static unlinkedEndorsementsByQuote = (quoteId, section, libraries, groups) =>
    axios
      .get(`/quotes/${quoteId}/endorsements/unlinked`, {
        params: {
          section,
          libraries,
          groups,
          sort: 'tag',
        },
      })
      .then((response) => {
        return {
          endorsements: _.map(
            response.data.data,
            (item) => new EndorsementModel(item)
          ),
          libraries: response.data.meta.libraries,
          groups: response.data.meta.groups,
        }
      })

  static create = (quoteId, payload) =>
    axios.post(`/quotes/${quoteId}/endorsements`, payload)
  static update = (quoteId, endorsementId, payload) =>
    axios.put(`/quotes/${quoteId}/endorsements/${endorsementId}`, payload)
  static linkEndorsements = (quoteId, section, endorsements) =>
    axios.post(`/quotes/${quoteId}/endorsements/link`, {
      endorsements,
      section,
    })
  static delete = (quoteId, endorsementId) =>
    axios.delete(`/quotes/${quoteId}/endorsements/${endorsementId}`)

  static linkEndorsementsGroup = (quoteId, section = '', groups = []) =>
    axios.post(`/quotes/${quoteId}/endorsements/link-group`, {
      groups,
      section,
    })

  static replaceEndorsementsGroup = (quoteId, section = '', groups = []) =>
    axios.post(`/quotes/${quoteId}/endorsements/replace-group`, {
      groups,
      section,
    })
}
