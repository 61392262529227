
<button
  :class="{
    'btn mt-1 mb-1 nowrap': true,
    [customClass]: !!customClass,
    lift: lift,
  }"
  :disabled="isDisabled"
  :name="name"
  :type="type"
  @click="
    $emit('click')
    $emit('onClick')
  "
>
  <i v-if="icon" :class="{ [icon]: !!icon, 'mr-2': true }"></i>
  {{ label }}
</button>
