import GeneralComponent from '@/General/Form/Mixins/GeneralComponent.js'

export default {
  mixins: [
    GeneralComponent
  ],

  props: {
    lift: {
      type: Boolean,
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    href: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: 'btn-primary'
    }
  }
}
