import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import _ from 'lodash'

const DEFAULT_SEPARATOR = ''

export default class CombineText extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }
    const parameters = this.resolver.parseParameters(uiRule.parameters)

    const separator = _.isArray(parameters) ? parameters[0] : parameters
    const replaceValue = _.isString(separator) ? separator : DEFAULT_SEPARATOR

    const value = this.resolver
      .getMultipleSourceValue(uiRule.source)
      .filter(Boolean)

    const result = value.toString().replace(/,/g, replaceValue)

    this.resolver.setValueToNode(uiRule.target, result, uiRule.trigger)

    return this.baseNode
  }
}
