
<div
  v-if="visible"
  :id="id"
  :class="{ 'form-group': true, [customClass]: !!customClass }"
>
  <div class="d-flex align-items-center justify-content-between">
    <TraceLabel
      :node="quoteNode"
      :label="label"
      :required="required"
      :name="name"
    />
    <FieldHint v-if="hint" :text="hint" />
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <input v-model="numberValue" type="hidden" :name="name" />

  <div class="input-group" :class="{ 'input-group-merge': !!icon }">
    <currency-input
      :id="id || dotKey"
      ref="currencyInput"
      v-model="model"
      v-validate="validation"
      v-b-tooltip.hover="{
        html: true,
        customClass: tooltipClass,
        title: tooltip,
      }"
      :locale="getCanonicalLocale()"
      :value-range="inputMinMax"
      :precision="precision"
      :currency="null"
      :data-vv-name="dotKey"
      :type="type"
      v-bind="$attrs"
      :placeholder="placeholder"
      :disabled="readonly"
      :class="{
        'form-control': true,
        'form-control-appended': !!icon,
        readonly: readonly,
        'is-invalid': hasErrors,
        [inputClass]: !!inputClass,
        [`is-${highlight}`]: !!highlight,
      }"
      @keydown="onKeyDown"
      @input="emitChange"
      @blur="$emit('change', $event)"
    />

    <div v-if="icon" class="input-group-append">
      <span :class="{ 'input-group-text': icon, readonly: readonly }">
        <i :class="icon"></i>
      </span>
    </div>

    <slot name="append"></slot>
  </div>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>

  <UiRulesError
    v-if="uiRulesError && traceMode"
    :id="id || dotKey"
    :message="uiRulesError.message"
    :rule="uiRulesError.rule"
  />
</div>
