import TranslationLibraryService from '@/Modules/TranslationLibrary/TranslationLibraryService.js'
import TranslationLibraryModel from '@/Modules/TranslationLibrary/TranslationLibraryModel.js'

// Getters
export const TRANSLATION_LIBRARY_GETTER = 'translationLibrary'

// Mutations
export const SET_TRANSLATION_LIBRARY_MUTATION = 'setTranslationLibrary'
export const RESET_TRANSLATION_LIBRARY_MUTATION = 'resetTranslationLibrary'

// Actions
export const GET_TRANSLATION_LIBRARY_ACTION = 'getTranslationLibrary'

export const TranslationLibraryModule = {
  namespaced: true,

  state: {
    translationLibrary: new TranslationLibraryModel(),
  },

  mutations: {
    [SET_TRANSLATION_LIBRARY_MUTATION]: (state, translationLibrary) => {
      state.translationLibrary = translationLibrary
    },

    [RESET_TRANSLATION_LIBRARY_MUTATION]: (state) => {
      state.translationLibrary = new TranslationLibraryModel()
      state.versions = []
    },
  },

  getters: {
    [TRANSLATION_LIBRARY_GETTER]: (state) => {
      return state.translationLibrary
    },
  },

  actions: {
    [GET_TRANSLATION_LIBRARY_ACTION]: ({ commit }, translationLibraryId) => {
      TranslationLibraryService
        .show(translationLibraryId)
        .then(translationLibraryData => {
          commit(SET_TRANSLATION_LIBRARY_MUTATION, translationLibraryData)
        })
    },
  }
}
