<template>
  <PageHeader>
    <PageTitle pre-title="404" :title="title" />
  </PageHeader>
</template>
<script>
export default {
  name: 'PageNotFound',

  computed: {
    title() {
      if (this.$route.path?.match(/quotes\/.*\/\w*/i)) {
        return this.__('quotes.not-found')
      }

      if (this.$route.path?.match(/policies\/.*\/\w*/i)) {
        return this.__('policies.not-found')
      }

      return this.__('common.page-not-found')
    },
  },
}
</script>
