import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const datasetRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('datasets', {
      create: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Create.vue'),
      edit: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Edit.vue'),
      show: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/Show.vue'),
      list: () =>
        import(/* webpackChunkName: "underwriting" */ './Pages/List.vue'),
    })
      .permissions({
        create: 'datasets.create',
        edit: 'datasets.update',
        show: 'datasets.view',
        list: 'datasets.view',
      })
      .meta({
        create: { back: 'datasets.list' },
        edit: { back: 'datasets.list' },
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
