<template>
  <div class="alert alert-light" role="alert">
    <span class="text-danger">
      {{ __('quotes.element-not-implemented', { uiType: node.uiType }) }}
    </span>
    <br />

    <span class="text-muted">
      <small>{{ __('quotes.properties') }} {{ JSON.stringify(node) }} </small>
    </span>
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeMissing',

  mixins: [UiTypeMixin],
}
</script>
