
<div
  v-if="visible"
  :id="id || dotKey"
  :class="{ 'form-group': true, [customClass]: !!customClass }"
>
  <div class="d-flex align-items-start">
    <TraceLabel
      :node="quoteNode"
      :label="label"
      :required="required"
      :name="name"
    />
    <FieldHint v-if="hint" class-name="mt-n1 ml-3" :text="hint" />
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <div
    v-for="(option, key) in options"
    :key="key"
    class="custom-control custom-checkbox mt-2"
    :class="{ 'custom-control-inline': inlineControl }"
  >
    <input
      :id="`${name}_${key}`"
      :value="option.value"
      :checked="isChecked(option)"
      type="checkbox"
      :disabled="readonly"
      class="custom-control-input"
      :name="name + '[]'"
      @input="emitChange"
    />

    <label
      v-if="isSelected(option)"
      class="custom-control-label label-semi-select"
      :for="`${name}_${key}`"
    >
      {{ option.label }}
    </label>
    <label v-else class="custom-control-label" :for="`${name}_${key}`">
      {{ option.label }}
    </label>
    <slot name="badge" :option="option" />
  </div>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>

  <UiRulesError
    v-if="uiRulesError && traceMode"
    :id="id || dotKey"
    :message="uiRulesError.message"
    :rule="uiRulesError.rule"
  />

  <input v-if="showEmptyValueInput" :value="[]" :name="name" type="hidden" />
</div>
