import BaseNodeResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/BaseNodeResolver.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import _ from 'lodash'

export default class ShowByValue extends UiRuleAction {
  constructor(baseNode, options = {}) {
    super(baseNode, new BaseNodeResolver(baseNode))

    _.forEach(options, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const value = this.resolver.getValueByRuleOrSource(rule)
    this.resolver.setVisibilityByParameters(rule, value, this.shouldInclude)

    return this.baseNode
  }
}
