import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import moment from 'moment'
import _ from 'lodash'

export default class SetCustomDate extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }
    const parameters = this.resolver.parseParameters(uiRule.parameters)

    let year = this.getDateValue('year', parameters)
    const month = this.getDateValue('month', parameters)
    const day = this.getDateValue('day', parameters)

    const monthStart = parameters.monthBegin
    const monthEnd = parameters.monthEnd
    const date = moment(uiRule.value)

    let sourceDate = moment().format('Y-MM-DD')
    const dateFromSource = this.resolver.getValueFromRuleSource(uiRule.source)
    if (!_.isUndefined(dateFromSource) && !_.isNull(dateFromSource)) {
      sourceDate = moment(dateFromSource).format('Y-MM-DD')
      if (!moment(sourceDate).isValid()) {
        sourceDate = moment(dateFromSource, 'DD-MM-Y').format('Y-MM-DD')
      }
    }

    if (!date.isValid()) {
      throw new Error('Date is invalid!')
    }

    date
      .set('year', year)
      .set('month', month - 1)
      .set('date', day)

    let sourceDataYear = moment(sourceDate).get('year')
    let sourceDataDay = moment(sourceDate).get('day')

    if (!_.isUndefined(sourceDataYear) && !_.isNull(sourceDataYear)) {
      year = sourceDataYear
    }

    if (dateFromSource) {
      let newDate = moment()

      newDate
        .set('year', Number(sourceDataYear))
        .set('month', month - 1)
        .set('date', sourceDataDay - 1)

      if (!_.isUndefined(monthStart) && monthStart) {
        newDate.startOf('month')
      }
      if (!_.isUndefined(monthEnd) && monthEnd) {
        newDate.endOf('month')
      }

      if (moment(newDate, 'DD-MM-Y').format('Y-MM-DD') < sourceDate) {
        year = sourceDataYear + 1
      }

      date
        .set('year', Number(year) + 1)
        .set('month', month - 1)
        .set('date', day)
    }

    if (
      !_.isUndefined(monthStart) &&
      monthStart &&
      _.isUndefined(parameters.year)
    ) {
      date.startOf('month')
    }

    if (!_.isUndefined(monthEnd) && monthEnd) {
      date
        .set('year', Number(year))
        .set('month', month - 1)
        .endOf('month')
    }

    if (date.toDate() < sourceDate && _.isUndefined(parameters.year)) {
      date
        .set('year', Number(year) + 1)
        .set('month', month - 1)
        .set('date', day)

      if (!_.isUndefined(monthStart) && monthStart) {
        date.startOf('month')
      }
      if (!_.isUndefined(monthEnd) && monthEnd) {
        date.endOf('month')
      }
    }

    this.resolver.setValueToNode(
      uiRule.target,
      date.toISOString(),
      uiRule.trigger
    )

    return this.baseNode
  }

  getDateValue(item, parameters) {
    const filters = {
      year: {
        parameter: parameters.year,
        format: 'Y',
      },
      month: {
        parameter: parameters.month,
        format: 'MM',
      },
      day: {
        parameter: parameters.day,
        format: 'DD',
      },
    }
    const filterItem = filters[item]
    if (_.isString(filterItem.parameter)) {
      const pathToNode = this.resolver.getPathToNode(filterItem.parameter)
      return (
        _.get(this.baseNode, `properties.${pathToNode}.value.value`) ||
        _.get(this.baseNode, `properties.${pathToNode}.value`)
      )
    }

    return filterItem.parameter ?? moment().format(filterItem.format)
  }
}
