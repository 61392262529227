
<label
  v-if="label"
  v-b-popover.hover.html.top="
    traceMode ? `<div style='width: 500px'>${traceValue}</div>` : false
  "
  :for="name"
>
  {{ label }}
  <RequiredNotation class="ml-2" :required="required" />
</label>
