import camelcase from 'camelcase'
import _ from 'lodash'

export default class ProductModel {
  id = null
  name = null
  tag = null
  description = null
  commission = 0
  parentId = null
  data = null
  index = null
  deletedAt = null

  childs = []

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })

    this.childs =
      data.childs && _.map(data.childs, (child) => new ProductModel(child))
  }
}
