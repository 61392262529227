import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const transactionCategoryRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('transaction-categories', {
      list: () => import(/* webpackChunkName: "payments" */ './Pages/List.vue'),
    })
      .except(['create', 'edit'])
      .permissions({
        list: 'transaction-categories.view',
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
