import { LS_ROUTE_KEY } from '@/Setup/Routes/SetupRouter.js'

export default function guest({ next, router }) {
  if (localStorage.getItem('auth_token')) {
    let lastRoutePath

    try {
      lastRoutePath = JSON.parse(sessionStorage.getItem(LS_ROUTE_KEY))
    } catch (error) {
      console.error(error)
    }

    if (lastRoutePath) {
      return router.push({ path: lastRoutePath })
    }

    return router.push({ name: 'home' })
  }

  return next()
}
