import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const CounterType = Object.freeze({
  entity: 'entity',
  client: 'client',
  invoice: 'invoice',
  quote: 'quote',
  policy: 'policy',
})

export const CounterUsageType = Object.freeze({
  manual: 'manual',
  auto: 'auto',
  none: 'none',
})

export default class CounterModel {
  reference = null
  type = CounterType.entity
  count = 0
  year = null
  isActive = false
  usageType = CounterUsageType.none
  canOnlyChangeTemplateAndYear = false
  useQuoteNumberForPolicy = false
  template = null
  description = null

  static usageTypes() {
    return [
      {
        label: i18n.tc('counters.manual'),
        value: CounterUsageType.manual,
      },
      {
        label: i18n.tc('counters.automatic'),
        value: CounterUsageType.auto,
      },
      {
        label: i18n.tc('counters.none'),
        value: CounterUsageType.none,
      },
    ]
  }

  static getUsageType(usageType) {
    return _.find(CounterModel.usageTypes(), (item) => item.value === usageType)
      ?.label
  }

  get counterType() {
    switch (this.type) {
      case CounterType.entity:
        return i18n.tc('common.entity')
      case CounterType.client:
        return i18n.tc('common.client')
      case CounterType.invoice:
        return i18n.tc('common.invoice')
      case CounterType.policy:
        return i18n.tc('common.policy')
      case CounterType.quote:
        return i18n.tc('common.quote')
      default:
        return ''
    }
  }

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }
}
