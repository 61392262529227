import _ from 'lodash'
import ClientModel from '@/Modules/Client/ClientModel.js'

export default class ClientRelationshipModel {
  id = null
  fromClientId = null
  fromClient = null
  toClientId = null
  toClient = null
  relationship = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.fromClient = this.fromClient && new ClientModel(this.fromClient)
    this.toClient = this.toClient && new ClientModel(this.toClient)
  }
}
