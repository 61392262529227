import axios from 'axios'

export default class ProfileService {
  static update = (payload) => axios.put(`/profile`, payload)

  static updatePassword = (payload) =>
    axios.post(`profile/update-password`, payload)

  static enableTfa = (payload) => axios.post(`profile/enable-tfa`, payload)

  static verifyTfaToken = (payload) =>
    axios.post(`two-fa/verify-tfa-token`, payload)

  static generateQrAndSecret = () => axios.post(`two-fa/generate-qr-and-secret`)

  static disableTfa = () => axios.post(`profile/disable-tfa`)

  static uploadPhoto = (payload) => axios.post(`profile/photo`, payload)

  static changeLocale = (locale) =>
    axios.post('profile/change-locale', {
      locale,
    })
}
