export default function applyNumberFormatting(
  value,
  locale = 'en-US',
  currency = null,
  softFloor = false,
  precision
) {
  if (isNaN(value)) {
    return null
  }

  let options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

  if (currency) {
    options = { style: 'currency', currency: currency }
  }

  let num = value

  if (!isNaN(precision)) {
    options.minimumFractionDigits = precision
    options.maximumFractionDigits = precision
  }

  if (softFloor && num % 1 === 0) {
    num = Math.floor(num)
    options.minimumFractionDigits = 0
  }

  return new Intl.NumberFormat(`${locale}`, options).format(num)
}

export function applyCompactNotation(value, locale = 'en-US') {
  if (isNaN(value)) {
    return null
  }

  let options = { notation: 'compact' }

  return new Intl.NumberFormat(`${locale}`, options).format(value)
}
