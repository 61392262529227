export default class {
  middleware = []
  name = ''
  path = ''
  component = null
  permissionWildcard = null
  beforeEnter = null

  constructor(name, path, component, beforeEnter) {
    this.name = name
    this.path = path
    this.component = component
    this.beforeEnter = beforeEnter
  }

  resolve() {
    return {
      path: this.path,
      name: this.name,
      component: this.resolveComponent(),
      beforeEnter: this.beforeEnter,
      meta: {
        middleware: this.middleware,
        permission: this.permissionWildcard,
      },
    }
  }

  middlewares(middlewares) {
    this.middleware = middlewares

    return this
  }

  permission(permission) {
    this.permissionWildcard = permission

    return this
  }

  resolveComponent() {
    if (!this.component) {
      return
    }
    return _.isFunction(this.component)
      ? this.component
      : this.component.default
  }
}
