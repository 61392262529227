<template>
  <div>
    <div v-if="loading">
      <slot/>
    </div>
  </div>
</template>

<script>
import { LOCAL_LOADING_GETTER } from '@/Modules/Base/BaseModule.js'

export default {
  name: 'OnLoading',

  computed: {
    loading: function () {
      return this.$store.getters['BaseModule/' + LOCAL_LOADING_GETTER]
    }
  }
}
</script>
