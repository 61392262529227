<template>
  <div
    v-if="visible"
    :id="id || dotKey"
    :class="{ 'form-group': true, [customClass]: !!customClass }"
  >
    <div class="d-flex align-items-start">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="name"
      />
      <FieldHint v-if="hint" class-name="mt-n1 ml-3" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div
      v-for="(option, key) in options"
      :key="key"
      class="custom-control custom-checkbox mt-2"
      :class="{ 'custom-control-inline': inlineControl }"
    >
      <input
        :id="`${name}_${key}`"
        :value="option.value"
        :checked="isChecked(option)"
        type="checkbox"
        :disabled="readonly"
        class="custom-control-input"
        :name="name + '[]'"
        @input="emitChange"
      />

      <label
        v-if="isSelected(option)"
        class="custom-control-label label-semi-select"
        :for="`${name}_${key}`"
      >
        {{ option.label }}
      </label>
      <label v-else class="custom-control-label" :for="`${name}_${key}`">
        {{ option.label }}
      </label>
      <slot name="badge" :option="option" />
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />

    <input v-if="showEmptyValueInput" :value="[]" :name="name" type="hidden" />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'
import CheckboxOptionModel from '@/General/Form/Fields/Checkboxes/CheckboxOptionModel.js'

export default {
  name: 'Checkboxes',

  mixins: [InputComponent],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    inlineControl: {
      type: Boolean,
      default: true,
    },
    semiSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: this.value || [],
    }
  },

  computed: {
    showEmptyValueInput: function () {
      return (
        this.options.filter((option) => this.isChecked(option)).length === 0
      )
    },
  },

  methods: {
    emitChange(event) {
      if (event.target.checked) {
        this.model = this.model.concat([event.target.value])
      }

      if (!event.target.checked) {
        this.model = Array.isArray(this.model)
          ? this.model.filter((item) => item !== event.target.value)
          : []

        if (this.quoteNode?.uiOptions?.required) {
          this.model = Array.isArray(this.model)
            ? this.model.filter((item) => item !== event.target.value)
            : ''
        }
      }

      this.$emit('input', this.model, event.target.value)
      this.$emit('onChange', this.model, event.target.value)
      this.$emit('changeDynamicFieldValue', this.model, event.target.value)
    },

    isChecked(option) {
      if (this.semiSelect) {
        const selectedOption = CheckboxOptionModel.findOption(
          this.model,
          option
        )
        if (_.isObject(selectedOption)) {
          return selectedOption.checked
        }
        return false
      }
      return this.model.includes(option.value.toString())
    },

    isSelected(option) {
      if (this.semiSelect) {
        const selectedOption = CheckboxOptionModel.findOption(
          this.model,
          option
        )
        if (_.isObject(selectedOption)) {
          return selectedOption.selected
        }
        return false
      }
      return false
    },
  },
}
</script>
