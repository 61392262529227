import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import Route from '@/Setup/Routes/Route.js'
import auth from '@/Middlewares/auth.js'

export const notificationRoutes = new RouteGroup(() => {
  return [
    new Route('notifications.list', '/notifications', () =>
      import('./Pages/List.vue')
    )
      .permission('notifications.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
