import _ from 'lodash'
import UiTypes from '@/Modules/Quote/Components/QuoteForm/UiTypes.js'

const VIRTUAL_NODES = Object.freeze({
  index: 1,
  noIndex: 0,
})

export const UI_PROPERTIES = Object.freeze({
  manualTabsLoading: 'manualTabsLoading',
  manualUIRulesExecution: 'manualUIRulesExecution',
  useValueAsTitle: 'useValueAsTitle',
  refreshButton: 'refreshButton',
  executeUiRulesBeforeSave: 'executeUiRulesBeforeSave',
})

export const IS_ROWS_INIT = '__IS_ROWS_INIT'

export default class UiTypeMultipleSection {
  props = {}
  rows = null
  uiProperties = {}
  value = {}
  properties = {}
  __tabIndexKey = null

  constructor(data = {}) {
    _.entries(data).forEach(([key, value]) => {
      this[key] = value
    })

    this.__tabIndexKey = this.getTabIndexKey()
  }

  getTabLabelKey() {
    return _.get(this.uiProperties, `${UI_PROPERTIES.useValueAsTitle}[0]`)
  }

  isRowInitialized(rowKey = '') {
    if (!this.rows) return

    const hasTitleNode = this.uiProperties[UI_PROPERTIES.useValueAsTitle]

    let nodes = this.__tabIndexKey ? VIRTUAL_NODES.index : VIRTUAL_NODES.noIndex

    if (hasTitleNode) nodes += 1

    return _.keys(this.rows[rowKey]).length > nodes
  }

  getTabIndexKey() {
    return _.values(this.properties).find(
      (property) => property.uiType === UiTypes.multipleSectionIndex
    )?.key
  }

  static isRowInitialized(rows = {}, rowKey = '') {
    return _.keys(rows[rowKey]).length > 1
  }

  get hasToExecuteRowsBeforeSave() {
    return this.uiProperties[UI_PROPERTIES.executeUiRulesBeforeSave]
  }
}
