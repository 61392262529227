import _ from 'lodash'

export default class TranslationLibraryModel {
  id = null
  name = null
  slug = null
  libraryId = null
  languageCode = null
  isPrimary = null
  translated = null

  createdAt = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }
}
