import axios from 'axios'

export default class InvoiceService {
  static create = (payload) => axios.post(`/invoices`, payload)

  static update = (invoiceId, payload) =>
    axios.put(`/invoices/${invoiceId}`, payload)

  static show = (invoiceId) =>
    axios.get(`/invoices/${invoiceId}`).then((response) => response.data)

  static list = ({ parameters, filters }) =>
    axios.get(`/invoices${filters}`, {
      params: parameters,
      excludeLoader: true,
    })

  static delete = (invoiceId) => axios.delete(`/invoices/${invoiceId}`)

  static changeStatus = (invoiceId, status) =>
    axios.put(`invoices/${invoiceId}/changes-status`, { status: status })

  static updateData = (invoiceId, data) =>
    axios.put(`invoices/${invoiceId}/update-data`, data)

  static cancel = (invoiceId) => axios.post(`invoices/${invoiceId}/cancel`)

  static executeIntegration = (invoiceId, integrationId) =>
    axios.post(`invoices/${invoiceId}/integrations/${integrationId}/execute`)
}
