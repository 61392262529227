import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import moment from 'moment'

export default class SetDateDiff extends UiRuleAction {
  constructor(baseNode, dateFormat) {
    super(baseNode, new ConditionResolver(baseNode))
    this.dateFormat = dateFormat
  }

  static common() {
    return {
      yearRegexp: new RegExp(/^(19\d\d|20\d\d)$/),
      yearDateFormat: 'Y',
    }
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    const [unit] = this.resolver.parseParameters(uiRule.parameters)
    const [valueA, valueB] = this.resolver.getMultipleSourceValue(uiRule.source)

    const formatterA = valueA?.match(SetDateDiff.common().yearRegexp)
      ? SetDateDiff.common().yearDateFormat
      : this.dateFormat
    const formatterB = valueB?.match(SetDateDiff.common().yearRegexp)
      ? SetDateDiff.common().yearDateFormat
      : this.dateFormat
    const dateA = moment(valueA, formatterA)
    const dateB = moment(valueB, formatterB)

    if (dateA?.isValid() && dateB?.isValid()) {
      this.resolver.setValueToNode(
        uiRule.target,
        moment(dateB, this.dateFormat).diff(dateA, unit),
        uiRule.trigger
      )
    }

    return this.baseNode
  }
}
