export const MAPPING_AMOUNT_GETTER = 'mappingAmount'

export const SET_MAPPING_AMOUNT_MUTATION = 'setMappingAmount'

export const SET_MAPPING_AMOUNT = 'setMappingAmountAction'

export const PaymentMappingModule = {
  namespaced: true,

  state: {
    mappingAmount: 0,
  },

  mutations: {
    [SET_MAPPING_AMOUNT_MUTATION]: (state, newValue) => {
      state.mappingAmount = newValue
    },
  },

  getters: {
    [MAPPING_AMOUNT_GETTER]: state => {
      return state.mappingAmount
    },
  },

  actions: {
    [SET_MAPPING_AMOUNT]: ({ commit, state }, amount) => {
      commit(SET_MAPPING_AMOUNT_MUTATION, amount)
    },
  },
}
