import InvoiceModel from '@/Modules/Invoice/InvoiceModel.js'
import InvoiceService from '@/Modules/Invoice/InvoiceService.js'
import _ from 'lodash'

// Getters
export const INVOICE_GETTER = 'invoice'
export const TAB_COUNTS_GETTER = 'tabCounts'
export const INTEGRATIONS_GETTER = 'integrations'

// Mutations
export const SET_INVOICE_MUTATION = 'setInvoice'
export const RESET_INVOICE_MUTATION = 'resetInvoice'
export const SET_TAB_COUNTS = 'setTabCounts'
export const SET_INTEGRATIONS = 'setIntegrations'

// Actions
export const GET_INVOICE_ACTION = 'getInvoice'
export const INCREASE_TAB_COUNT = 'increaseTabCount'
export const DECREASE_TAB_COUNT = 'decreaseTabCount'
export const RESET_INVOICE = _.camelCase('RESET_INVOICE')

export const InvoiceModule = {
  namespaced: true,

  state: {
    /**
     * some issue with import in InoiceModel.spec
     */
    invoice: InvoiceModel && new InvoiceModel({}),
    tabCounts: {},
    integrations: [],
  },

  mutations: {
    [SET_INVOICE_MUTATION]: (state, invoice) => {
      state.invoice = invoice
    },

    [RESET_INVOICE_MUTATION]: (state) => {
      state.invoice =
        InvoiceModel &&
        new InvoiceModel({
          addresses: [],
          contacts: [],
          manager: {},
          occupation: {},
        })
      state.tabCounts = {}
      state.versions = []
    },

    [SET_TAB_COUNTS]: (state, tabCounts) => {
      state.tabCounts = tabCounts
    },

    [SET_INTEGRATIONS]: (state, integrations) => {
      state.integrations = integrations
    },
  },

  getters: {
    [INVOICE_GETTER]: (state) => {
      return state.invoice
    },

    [TAB_COUNTS_GETTER]: (state) => {
      return state.tabCounts
    },
    [INTEGRATIONS_GETTER]: (state) => {
      return state.integrations
    },
  },

  actions: {
    [GET_INVOICE_ACTION]: ({ commit }, invoiceId) => {
      return InvoiceService.show(invoiceId).then((invoiceData) => {
        commit(SET_INVOICE_MUTATION, new InvoiceModel(invoiceData.data))
        commit(SET_TAB_COUNTS, invoiceData.tabCounts)
        commit(SET_INTEGRATIONS, invoiceData.integrations)
        return new InvoiceModel(invoiceData.data)
      })
    },

    [INCREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) + 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [DECREASE_TAB_COUNT]: ({ commit, state }, tab) => {
      let tabCounts = _.cloneDeep(state.tabCounts)
      _.set(tabCounts, tab, parseInt(tabCounts[tab]) - 1)
      commit(SET_TAB_COUNTS, tabCounts)
    },

    [RESET_INVOICE]: ({ commit }) => {
      commit(RESET_INVOICE_MUTATION)
    },
  },
}
