
<div
  v-if="html"
  v-b-popover.html.hover="`<pre style='width: 500px'>${html}</pre>`"
  :class="className"
>
  <Icon icon="question-circle" size="18" />
</div>
<div v-else v-b-popover.hover="text" :class="className">
  <Icon icon="question-circle" size="18" />
</div>
