import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import * as math from 'mathjs'
import _ from 'lodash'

export default class SumEndorsements extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    const currentValue = _.get(
      this.baseNode,
      `properties.${this.resolver.getPathToNode(
        uiRule.source
      )}.properties.endorsements`
    )
    const targetNode = _.get(
      this.baseNode,
      `properties.${this.resolver.getPathToNode(uiRule.target)}`
    )
    let targetNodeCurrency
    if (_.isObject(targetNode) && targetNode.properties?.currency) {
      targetNodeCurrency = targetNode.properties?.currency.value
    }
    if (_.isArray(currentValue) && _.isObject(uiRule.parameters)) {
      const { amount, currency, product } = uiRule.parameters
      const amountCurrency = currency || targetNodeCurrency
      const amountCurrencyCode = `${amount}CurrencyCode`
      const newValue = this.sumValues(
        currentValue,
        uiRule.parameters.onlyCurrentVersion,
        amount,
        amountCurrency,
        amountCurrencyCode,
        product
      )

      this.resolver.setValueToNode(uiRule.target, newValue, uiRule.trigger)
      return this.baseNode
    }

    return this.baseNode
  }

  sumValues(
    currentValue = [],
    onlyCurrentVersion,
    amount,
    amountCurrency,
    amountCurrencyCode,
    product
  ) {
    const values = currentValue
      .filter((item) => !(onlyCurrentVersion && item.frozen))
      .filter((item) => {
        if (!item || item[amount] === null) return false

        if (!item[amountCurrencyCode]) {
          return true
        }

        if (amountCurrency) {
          return (
            item[amountCurrencyCode].toUpperCase() ===
            amountCurrency.toUpperCase()
          )
        }

        return false
      })
      .filter((item) => {
        if (product && item.product) {
          return item.product.tag === product
        }

        if (product === null) return !item.product

        return true
      })
      .map((item) => {
        if (!product) return item[amount]

        return product === item.product?.tag && item[amount] ? item[amount] : 0
      })

    return math.sum(values)
  }
}
