import CurrencyModel from '@/Modules/Currency/CurrencyModel.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import TransactionModel from '@/Modules/Transaction/TransactionModel.js'
import UserModel from '@/Modules/User/UserModel.js'
import { i18n } from '@/Setup/SetupI18n.js'
import _ from 'lodash'

export const invoiceStatus = Object.freeze({
  prepared: 'prepared',
  sent: 'sent',
  paid: 'paid',
  cancelled: 'cancelled',
})

export default class InvoiceModel {
  id = null
  number = null
  issueDate = null
  dueDate = null
  defaultDueDate = null
  totalAmount = null
  payableAmount = null
  client = null
  clientLegalName = null
  clientCode = null
  clientTaxId = null
  clientBuilding = null
  clientUnit = null
  clientStreet = null
  clientTown = null
  canCancel = false
  canDelete = false
  clientCountry = null
  clientCounty = null
  clientPostCode = null
  totalWithCurrency = null
  currencyCode = null
  note = null
  underwriterId = null
  brokerContactId = null
  entityId = null
  clientId = null
  data = {}

  subTransactions = []
  totals = []
  entity = null
  underwriter = null
  brokerContact = null
  currency = null
  status = invoiceStatus.prepared

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.entity = data.entity && new EntityModel(data.entity)
    this.underwriter = data.underwriter && new UserModel(data.underwriter)
    this.brokerContact = this.brokerContact && new UserModel(this.brokerContact)
    this.currency = data.currency && new CurrencyModel(data.currency)
    this.subTransactions = _.map(
      this.subTransactions,
      (transaction) => new TransactionModel(transaction)
    )
  }

  fullAddress() {
    return _.filter([
      this.clientBuilding,
      this.clientStreet,
      this.clientTown,
      this.clientCounty,
      this.clientCountry,
    ]).join(', ')
  }

  mgaUnderline(subTransaction) {
    if (
      !_.isObject(subTransaction) ||
      subTransaction.inBalance !== 1 ||
      subTransaction.type !== 'premium' ||
      _.isEmpty(subTransaction.dependentChildren) ||
      !_.isArray(subTransaction.dependentChildren)
    ) {
      return null
    }

    return subTransaction.dependentChildren
      .map((item) => new TransactionModel(item))
      .filter((item) => item.inBalance === 0 && !!item?.childSummary)
      .map((item) => {
        return InvoiceModel.getInvoiceItemSummary(
          item.childSummary,
          item.creditWithCurrency
        )
      })
      .join('\n')
      .trim()
  }

  /**
   *
   * @param {string} summary
   * @param {*} value
   * @param {string} key
   * @returns {string}
   */
  static getInvoiceItemSummary(summary, value, key = 'mga_commission') {
    if (_.isString(summary)) {
      const hasPercentageValue = summary.match(/\d+\.\d+%/)
      return hasPercentageValue
        ? `${summary} ${value}`
        : summary.replace(`(${key})`, `(${key} ${value})`)
    }
    return ''
  }

  getSubTransactions(filter) {
    if (typeof filter === 'function' && _.isArray(this.subTransactions)) {
      return this.subTransactions.filter(filter)
    }
    return this.subTransactions
  }

  statusData() {
    return InvoiceModel.statusData(this.status)
  }

  static statusData(status) {
    switch (status) {
      case invoiceStatus.prepared:
        return {
          label: i18n.tc('statuses.prepared'),
          badge: 'soft-warning',
          value: invoiceStatus.prepared,
          permissions: ['invoices.update'],
        }
      case invoiceStatus.sent:
        return {
          label: i18n.tc('statuses.sent'),
          badge: 'soft-primary',
          value: invoiceStatus.sent,
          permissions: ['invoices.update'],
        }
      case invoiceStatus.paid:
        return {
          label: i18n.tc('statuses.paid'),
          badge: 'soft-success',
          value: invoiceStatus.paid,
          permissions: ['invoices.update'],
        }
      case invoiceStatus.cancelled:
        return {
          label: i18n.tc('statuses.cancelled'),
          badge: 'soft-danger',
          value: invoiceStatus.cancelled,
          permissions: ['invoices.cancel'],
        }
    }
  }

  get statusOptions() {
    return _.values(invoiceStatus)
      .filter((value) => {
        if (value === this.status) {
          return false
        }

        return true
      })
      .map((value) => InvoiceModel.statusData(value))
  }
}
