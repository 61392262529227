export default {
  created() {
    this.traceMode = this.$route.query.hasOwnProperty('trace')
  },

  computed: {
    traceLabel() {
      if (!this.traceMode) {
        return
      }
      if (this.name) {
        if (
          this.name === this.quoteNode?.uiOptions?.quoteField &&
          this.quoteNode?.nodePath
        ) {
          return `${this.quoteNode.nodePath} (${this.quoteNode.uiOptions.quoteField})`
        }
        return this.getDotName(this.name)
      }
    },
  },

  methods: {
    getTrace(node) {
      if (!this.traceMode) {
        return
      }
      let quoteNode = _.pick(node || this.quoteNode, [
        'uiType',
        'uiOptions',
        'uiProperties',
        'uiRules',
        'uiMapping',
      ])

      return this.clean(_.cloneDeep(quoteNode))
    },

    clean(object) {
      for (let key in object) {
        if (
          object.hasOwnProperty(key) &&
          ([undefined, null, ''].includes(object[key]) ||
            object[key].length === 0)
        ) {
          delete object[key]
        } else if (typeof object[key] === 'object') {
          this.clean(object[key])
        }
      }

      return object
    },

    getDotName(value = '') {
      return value
        .replace(/]\[/g, '.')
        .replace(/\[/g, '.')
        .replace(/]/g, '')
        .replace(/data\./, '')
    },
  },
}
