import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import moment from 'moment'
import _ from 'lodash'

export default class SetDate extends UiRuleAction {
  constructor(baseNode, dateFormat) {
    super(baseNode, new ConditionResolver(baseNode))
    this.dateFormat = dateFormat
  }

  static PARAMETER_YEARS() {
    return 'years'
  }

  static PARAMETER_DAYS() {
    return 'days'
  }

  static PARAMETER_MONTHS() {
    return 'months'
  }

  static PARAMETER_MONTH_BEGIN() {
    return 'monthBegin'
  }

  static PARAMETER_MONTH_END() {
    return 'monthEnd'
  }

  static PARAMETER_MIN_DATE() {
    return 'minDate'
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    let value = moment(
      this.resolver.getValueByRuleOrSource(uiRule),
      this.dateFormat
    )

    if (!value || !value._isValid) {
      value = moment()
    }

    const parameters = this.resolver.parseParameters(uiRule.parameters)

    if (_.isObject(parameters)) {
      let minDateParam = parameters[SetDate.PARAMETER_MIN_DATE()]
      if (minDateParam === true) {
        this.resolver.setMinDate(uiRule.target, value.toDate())
      }
      if (_.isString(minDateParam)) {
        let minValue = moment(minDateParam, this.dateFormat)
        if (minValue && minValue._isValid) {
          this.resolver.setMinDate(uiRule.target, minValue.toDate())
        }
      }
      _.each(parameters, (parameter, key) => {
        switch (key) {
          case SetDate.PARAMETER_YEARS(): {
            value.add(parameter, 'years')

            break
          }
          case SetDate.PARAMETER_MONTHS(): {
            value.add(parameter, 'months')

            break
          }
          case SetDate.PARAMETER_DAYS(): {
            value.add(parameter, 'days')

            break
          }
          case SetDate.PARAMETER_MONTH_BEGIN(): {
            value.add(parameter, 'months').startOf('month')

            break
          }
          case SetDate.PARAMETER_MONTH_END(): {
            value.add(parameter, 'months').endOf('month')

            break
          }
        }
      })
    }

    this.resolver.setValueToNode(
      uiRule.target,
      value.format(this.dateFormat),
      uiRule.trigger
    )

    return this.baseNode
  }

  setMinDate(target, state) {
    if (
      _.get(this.baseNode, `properties.${this.resolver.getPathToNode(target)}`)
    ) {
      _.set(
        this.baseNode,
        `properties.${this.resolver.getPathToNode(target)}.uiOptions.minDate`,
        state
      )
    }
  }
}
