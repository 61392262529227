import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import auth from '@/Middlewares/auth.js'

export const translationLibraryRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('translation-libraries', {
      list: () => import(/* webpackChunkName: "admin" */ './Pages/List.vue'),
      edit: () => import(/* webpackChunkName: "admin" */ './Pages/Edit.vue'),
    })
      .permissions({
        list: 'translation-libraries.view',
        edit: 'translation-libraries.update',
      })
      .resolve(),

    new Route(
      'translation-libraries.edit-custom',
      '/translation-libraries/:translationLibraryId?/edit-custom',
      () => import(/* webpackChunkName: "admin" */ './Pages/EditCustom.vue')
    )
      .permission('translation-libraries.update')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
