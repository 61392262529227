import _ from 'lodash'
import { UiRulesExecutor } from '@/Modules/Quote/Components/QuoteForm/UiRulesExecutor.js'
import QuoteBaseNodeRowBuilder from '@/Modules/Quote/QuoteBaseNodeRowBuilder.js'

export default class QuoteBaseNodeService extends QuoteBaseNodeRowBuilder {
  rulesExecutor = null

  constructor(baseNode) {
    super(baseNode)
    this.rulesExecutor = new UiRulesExecutor(this.baseNode)
  }

  executeUiRules(uiRules = [], rowKey = '') {
    uiRules
      .filter((uiRule) =>
        [
          UiRulesExecutor.TRIGGER_ONLOAD(),
          UiRulesExecutor.TRIGGER_ONCHANGE(),
        ].includes(uiRule?.trigger)
      )
      .forEach((uiRule) => {
        // Assume that having rowKey means that rules will operate in multipleSection
        const uiRuleCopy = _.cloneDeep(uiRule)

        if (rowKey) {
          const parsedUiRule = UiRulesExecutor.parseMultiRowTarget(
            uiRuleCopy,
            rowKey
          )

          if (typeof this.rulesExecutor[uiRuleCopy.action] === 'function') {
            this.rulesExecutor[uiRuleCopy.action](parsedUiRule)
          }

          return
        }

        if (typeof this.rulesExecutor[uiRuleCopy.action] === 'function') {
          this.rulesExecutor[uiRuleCopy.action](uiRuleCopy)
        }
      })
  }

  traverseNodeAndExecuteUiRules(node = this.baseNode, index) {
    if (
      Object.prototype.hasOwnProperty.call(node, 'properties') &&
      !_.isEmpty(node.properties)
    ) {
      for (const key in node.properties) {
        if (node.properties.hasOwnProperty(key)) {
          const property = node.properties[key]

          if (_.isArray(property.uiRules)) {
            this.executeUiRules(property.uiRules, property.rowKey || index)
          }

          this.traverseNodeAndExecuteUiRules(property, index)
        }
      }
    }
  }

  static getMultipleSectionPath(index, key) {
    const splitKey = key.split('.{0}.')
    const tag = splitKey.pop()
    const structurePart = splitKey[0]

    return `${structurePart.replace(
      /\./g,
      '.properties.'
    )}.rows.${index}.${tag}`
  }

  static getLookupValue(object, value) {
    let lookUpValue = _.get(object, value)

    if (typeof lookUpValue === 'object') {
      let newLookUp = _.map(
        Object.values(lookUpValue).filter((item) => !!item),
        (item) => {
          return item.long_name
        }
      )
      lookUpValue = newLookUp.reverse().join(', ').trimEnd()
    }

    return lookUpValue
  }

  /**
   *
   * @param {object} baseNode
   * @returns {object|undefined}
   */
  applyUiRules() {
    if (!_.isObject(this.baseNode)) {
      return
    }

    this.traverseNodeAndExecuteUiRules()

    return this.baseNode
  }

  /**
   *
   * @param {object} baseNode
   * @returns {object|undefined}
   */
  applyUiRulesInMultipleSections(sections = []) {
    if (!_.isObject(this.baseNode)) {
      return
    }

    if (!_.isEmpty(sections)) {
      this.sections = [...sections]
    }

    try {
      this.buildMultipleSectionRows()
      this.setMultipleSectionValues()
      this.cleanUpSectionRows()
    } catch (error) {
      throw new Error(error)
    }
  }
}
