import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import auth from '@/Middlewares/auth.js'

export const countryRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('countries', {
      list: () => import('./Pages/List.vue'),
      show: () => import('./Pages/Show.vue'),
    })
      .except(['create', 'edit'])
      .permissions({
        list: 'countries.view',
        show: 'countries.view',
      })
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
