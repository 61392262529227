import EntityModel, { EntityModelType } from '@/Modules/Entity/EntityModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'
import { PaymentPlanRoundingStyle } from '@/Modules/Payment/PaymentPlan/PaymentPlanModel.js'

export const InBalance = Object.freeze({
  POSITIVE: 1,
  NEGATIVE: -1,
  NOT_IN_BALANCE: 0,
})

export const TransactionCategoryType = Object.freeze({
  PREMIUM: 'premium',
  COMISSION: 'commission',
  AGENT_COMMISSION: 'agent_commission',
  FEE: 'fee',
  TAX: 'tax',
  MGA_COMMISSION: 'mga_commission',
})

export const TransactionCategoryRoundingStyle = Object.freeze({
  ceil: 'ceil',
  floor: 'floor',
  round: 'round',
})

export default class TransactionCategoryModel {
  id = null
  name = null
  summary = null
  type = TransactionCategoryType.PREMIUM
  defaultRate = null
  defaultAmount = null
  dependentOnProduct = 0
  inBalance = InBalance.NOT_IN_BALANCE
  ownerType = EntityModelType.COVERHOLDER
  collectorType = EntityModelType.COVERHOLDER
  tag = null
  index = null
  data = null
  roundingStyle = TransactionCategoryRoundingStyle.round

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additionalData
  }

  static ownerType(type) {
    return type && EntityModel.typeDataByKey(type)
  }

  static ownerTypes() {
    return EntityModel.types()
  }

  static types() {
    return [
      {
        label: i18n.tc(`common.premium`),
        value: TransactionCategoryType.PREMIUM,
      },
      {
        label: i18n.tc(`common.commission`),
        value: TransactionCategoryType.COMISSION,
      },
      {
        label: i18n.tc(`common.agent-commission`),
        value: TransactionCategoryType.AGENT_COMMISSION,
      },
      {
        label: i18n.tc(`transactions.fee`),
        value: TransactionCategoryType.FEE,
      },
      {
        label: i18n.tc(`transactions.tax`),
        value: TransactionCategoryType.TAX,
      },
      {
        label: i18n.tc(`common.mga-commission`),
        value: TransactionCategoryType.MGA_COMMISSION,
      },
    ]
  }

  static balance() {
    return [
      {
        label: i18n.tc(`transactions.positive-1`),
        value: InBalance.POSITIVE,
      },

      {
        label: i18n.tc(`transactions.balance-out`),
        value: InBalance.NOT_IN_BALANCE,
      },
      {
        label: i18n.tc(`transactions.negative-1`),
        value: InBalance.NEGATIVE,
      },
    ]
  }

  static roundingStyles() {
    return [
      {
        label: i18n.tc('transaction-categories.rounding-ceil'),
        value: PaymentPlanRoundingStyle.ceil,
      },
      {
        label: i18n.tc('transaction-categories.rounding-floor'),
        value: PaymentPlanRoundingStyle.floor,
      },
      {
        label: i18n.tc('transaction-categories.rounding-round'),
        value: PaymentPlanRoundingStyle.round,
      },
    ]
  }
}
