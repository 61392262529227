import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const quoteObjectGroupRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('quote-object-groups', {
      list: () => import(/* webpackChunkName: "admin" */ './Pages/List.vue'),
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .permissions({
        show: 'quotes.object-groups.view',
        list: 'quotes.object-groups.view',
      })
      .only(['list', 'show'])
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
