import store from '@/Setup/SetupStore.js'
import { IS_ONE_DRIVE_ENABLED_GETTER } from '@/Modules/Base/SettingModule.js'

export default function beforeRouteEnter(to, from, next) {
  if (store.getters[`SettingModule/${IS_ONE_DRIVE_ENABLED_GETTER}`]) {
    next()
  } else {
    next('/home')
  }
}
