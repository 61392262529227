import _ from 'lodash'

import { DATE_FORMAT_GETTER } from '@/Modules/Base/SettingModule.js'
import store from '@/Setup/SetupStore.js'
import ToggleVisibility from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ToggleVisibility.js'
import Calculate from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/Calculate.js'
import SetValue from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetValue.js'
import ShowField from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowField.js'
import ShowFieldByIntersection from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowFieldByIntersection.js'
import CalculateTable from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/CalculateTable.js'
import SetToday from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetToday.js'
import ShowByValueLt from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowByValueLt.js'
import ShowByValueGt from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowByValueGt.js'
import ShowByValueInRange from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowByValueInRange.js'
import ShowByValue from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ShowByValue.js'
import SetDate from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetDate.js'
import SetDateDiff from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetDateDiff.js'
import CombineText from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/CombineText.js'
import SumEndorsements from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SumEndorsements.js'
import ParsePersonalCodeLt from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/ParsePersonalCodeLt.js'
import CalculateDuration360 from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/CalculateDuration360.js'
import SetCustomDate from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetCustomDate.js'
import NumberToWords from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/NumberToWords.js'
import SetEndorsementGroup from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/SetEndorsementGroup.js'
import UpdateEndorsementGroup from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UpdateEndorsementGroup.js'
import BaseNodeResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/BaseNodeResolver.js'
import { i18n } from '@/Setup/SetupI18n.js'

export const QUOTE_META_PREVENT_UI_RULES_EXECUTION = 'preventUiRulesExecution'

export class UiRulesExecutor {
  static TRIGGER_ONROWADDED() {
    return 'onRowAdded'
  }

  static TRIGGER_ONROWREMOVED() {
    return 'onRowRemoved'
  }

  static TRIGGER_ONLOAD() {
    return 'onLoad'
  }

  static TRIGGER_ONCHANGE() {
    return 'onChange'
  }

  static AVAILABLE_ON_LOAD_ACTIONS() {
    return [
      'toggleVisibility',
      'showByValueLt',
      'showByValueLte',
      'showByValueGt',
      'showByValueGte',
      'showByValueInRange',
      'hideByValue',
      'showByValue',
      'calculate',
      'combineText',
      'numberToWords',
      'getDateDiff',
      'showField',
      'showFieldByIntersection',
      'setEndorsementGroup',
      'updateEndorsementGroup',
    ]
  }

  constructor(baseNode, quote) {
    this.baseNode = baseNode
    this.quote = quote
  }

  setRuleExecutionError(node, rule, error) {
    const resolver = new BaseNodeResolver(this.baseNode)
    if (!resolver || !node || !rule) {
      return
    }
    const rawPath = node.nodePath
      .split('.')
      .reduce((acc, curr) => {
        const property = _.get(this.baseNode.properties, curr, {})
        acc = acc.concat(curr).concat('.')
        if (property.uiType === 'multipleSection') {
          acc = acc.concat(`rows_${node.index}_`)
        }
        return acc
      }, '')
      .replace(/\.$/, '')
    const path = resolver.getPathToNode(rawPath)
    const newNode = _.cloneDeep(_.get(this.baseNode, `properties.${path}`))
    if (newNode) {
      _.set(newNode, 'uiRulesError', {
        message: error?.message,
        rule,
      })
      _.set(this.baseNode, `properties.${path}`, newNode)
    }
  }

  calculate(rule, node) {
    const action = new Calculate(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  toggleVisibility(rule) {
    const action = new ToggleVisibility(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  setValue(rule, node) {
    const action = new SetValue(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  showField(rule) {
    const action = new ShowField(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showFieldByIntersection(rule) {
    const action = new ShowFieldByIntersection(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  /**
   * map money table value to target money table with expression
   * @todo: use const for moneyTable ui type check
   * @param {object} rule
   * @returns {object} baseNode
   */
  calculateTable(rule) {
    const action = new CalculateTable(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  setToday(rule, node) {
    const action = new SetToday(
      this.baseNode,
      store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
    )
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  showByValueLt(rule) {
    const action = new ShowByValueLt(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showByValueLte(rule) {
    const action = new ShowByValueLt(this.baseNode, { orEqual: true })
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showByValueGt(rule) {
    const action = new ShowByValueGt(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showByValueGte(rule) {
    const action = new ShowByValueGt(this.baseNode, { orEqual: true })
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showByValueInRange(rule) {
    const action = new ShowByValueInRange(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  hideByValue(rule) {
    const action = new ShowByValue(this.baseNode, { shouldInclude: true })
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  showByValue(rule) {
    const action = new ShowByValue(this.baseNode, { shouldInclude: false })
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  setCustomDate(rule, node) {
    const action = new SetCustomDate(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  setDate(rule, node) {
    const action = new SetDate(
      this.baseNode,
      store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
    )
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  numberToWords(rule, node) {
    const action = new NumberToWords(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  getDateDiff(rule, node) {
    const action = new SetDateDiff(
      this.baseNode,
      store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
    )
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  combineText(rule, node) {
    const action = new CombineText(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  sumEndorsements(rule) {
    const action = new SumEndorsements(this.baseNode)
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        throw new Error(error)
      }
    }
    return this.baseNode
  }

  parsePersonalCodeLt(rule, node) {
    const action = new ParsePersonalCodeLt(
      this.baseNode,
      store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
    )
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  calculateDuration360(rule, node) {
    const action = new CalculateDuration360(
      this.baseNode,
      store.getters[`SettingModule/${DATE_FORMAT_GETTER}`]
    )
    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  setEndorsementGroup(rule, node) {
    const action = new SetEndorsementGroup(this.baseNode, this.quote, store)

    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  updateEndorsementGroup(rule, node) {
    const action = new UpdateEndorsementGroup(this.baseNode, this.quote, store)

    if (typeof action?.execute === 'function') {
      try {
        return action.execute(rule)
      } catch (error) {
        console.error(error)
        this.setRuleExecutionError(node, rule, error)
      }
    }
    return this.baseNode
  }

  static parseMultiRowTarget(rule, key) {
    rule.actualKey = _.clone(key)

    if (_.isArray(rule.target)) {
      rule.target = rule.target.map((item) =>
        item.replace('{0}.', `rows_${key}_`)
      )
    }
    if (typeof rule.target === 'string') {
      rule.target = rule.target.replace('{0}.', `rows_${key}_`)
    }

    if (
      rule.source &&
      typeof rule.source === 'string' &&
      rule.source.includes('{0}')
    ) {
      rule.source = rule.source.replace('{0}.', `rows_${key}_`)
    }
    if (rule.source && _.isArray(rule.source)) {
      rule.source = rule.source.map((item) =>
        item.replace('{0}.', `rows_${key}_`)
      )
    }
    if (
      rule.parameters &&
      typeof rule.parameters === 'string' &&
      rule.parameters.includes('{0}')
    ) {
      rule.parameters = rule.parameters.replace(/{0\}./g, `rows_${key}_`)
    }

    if (
      rule.condition &&
      typeof rule.condition === 'string' &&
      rule.condition.includes('{n}')
    ) {
      rule.condition = rule.condition.replace(/{n\}./g, `rows_${key}_`)
    }

    return rule
  }

  static uiRuleMustHaveTarget(rule) {
    console.warn(i18n.tc('quotes.uirule-target'), rule)
  }

  static uiRuleMustHaveAction(rule) {
    console.warn(i18n.tc('quotes.uirule-action'), rule)
  }

  static parseTableSectionUiRuleParam(param = '') {
    /**
     * @todo Do you really need '.node' in the path?
     */
    const regExp = new RegExp(/\.column\./i)
    if (!_.isString(param) || !param.match(regExp)) {
      return param
    }
    const newPath = param
      .replace(regExp, `.${BaseNodeResolver.SECTION_TABLE_KEY()}.`)
      .concat('.node')
    return newPath
  }

  static isMultipleActionRule(rule) {
    if (_.isObject(rule) && typeof rule.target === 'string') {
      return rule.target && !!rule.target.match('{n}')
    }
    if (_.isObject(rule) && _.isArray(rule.target)) {
      return (
        rule.target.filter(
          (item) => typeof item === 'string' && !!item.match('{n}')
        ).length > 0
      )
    }
    return false
  }

  generateRowIndexBasedRules(rule) {
    const path = _.first(rule.target.split('.{n}.')).replace(
      '.',
      '.properties.'
    )
    if (
      this.baseNode.properties[path] &&
      _.isObject(this.baseNode.properties[path].rows)
    ) {
      const rules = Object.keys(this.baseNode.properties[path].rows).map(
        (item, index) => ({
          ...rule,
          target: rule.target.replace('{n}', `{${index}}`),
        })
      )
      return { rules, error: null }
    }
    return { error: i18n.tc('quotes.target-node') }
  }
}
