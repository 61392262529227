<template>
  <label
    v-if="label"
    v-b-popover.hover.html.top="
      traceMode ? `<div style='width: 500px'>${traceValue}</div>` : false
    "
    :for="name"
  >
    {{ label }}
    <RequiredNotation class="ml-2" :required="required" />
  </label>
</template>

<script>
import TraceModeMixin from '@/General/Form/Mixins/TraceModeMixin.js'
import RequiredNotation from '@/General/RequiredNotation.vue'

export default {
  name: 'TraceLabel',

  components: { RequiredNotation },

  mixins: [TraceModeMixin],

  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    required: {
      type: Boolean,
      default: null,
    },
    traceOptions: {
      type: Object,
      default: () => ({ quoteKeys: true }),
    },
  },

  computed: {
    traceValue() {
      return this.prettyPrintJson.toHtml(
        this.getTrace(this.node),
        this.traceOptions
      )
    },
  },
}
</script>
