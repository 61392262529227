<template>
  <div
    v-if="visible"
    :id="id || dotKey"
    v-b-tooltip.hover="{
      html: true,
      customClass: tooltipClass,
      title: tooltip,
    }"
    :class="{
      'form-group': true,
      [customClass]: !!customClass,
      'form-control-border': true,
      [`is-${highlight}`]: !!highlight,
    }"
  >
    <div class="d-flex align-items-start">
      <TraceLabel :node="quoteNode" :label="label" :required="required" />
      <FieldHint v-if="hint" class-name="mt-n1 ml-3" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div
      v-for="(option, key) in options"
      :key="key"
      class="custom-control custom-radio mt-2"
      :class="{ 'custom-control-inline': inlineControl }"
    >
      <input
        :id="`${name}_${key}`"
        v-model="model"
        v-validate="validation"
        :value="option.value"
        type="radio"
        class="custom-control-input"
        :name="name"
        :data-vv-name="dotKey"
        :disabled="readonly"
        @input="emitChange"
      />

      <label class="custom-control-label" :for="`${name}_${key}`">
        {{ option.label }}
      </label>
    </div>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />
  </div>
</template>

<script>
import InputComponent from '@/General/Form/Mixins/InputComponent.js'

export default {
  name: 'Radios',

  mixins: [InputComponent],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    inlineControl: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      model: this.value,
    }
  },

  methods: {
    emitChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('onChange', event.target.value)
    },
  },
}
</script>
