import UserModel from '@/Modules/User/UserModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const EntityModelType = Object.freeze({
  COVERHOLDER: 'coverholder',
  AGENT: 'agent',
  INSURER: 'insurer',
})

export default class EntityModel {
  id = null
  code = null
  name = null
  type = null
  legalName = null
  tag = null
  network = null
  status = null
  addressHouse = null
  addressStreet = null
  addressTown = null
  addressPostCode = null
  addressCounty = null
  addressCountry = null
  addressUnit = null
  tobaStartDate = null
  tobaEndDate = null
  paymentTermId = null
  isMakingPayments = false
  bankDetails = null
  usersCount = 0
  productsCount = 0
  notes = null
  isGroup = false
  parentId = null
  data = null
  isAutomatedStatement = false
  users = []
  childUsers = []
  paymentTerm = {}
  children = []
  isChild = false
  subsidiaryEntityUsersCount = 0
  logoUrl = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additional_data
    this.users = data.users && _.map(data.users, (user) => new UserModel(user))
    this.childUsers =
      data.child_users && _.map(data.child_users, (user) => new UserModel(user))
    this.children =
      data.children && _.map(data.children, (child) => new EntityModel(child))
  }

  static types() {
    return [
      {
        label: i18n.tc('entities.coverholder'),
        value: 'coverholder',
      },
      {
        label: i18n.tc('common.agent'),
        value: 'agent',
      },
      {
        label: i18n.tc('entities.insurer'),
        value: 'insurer',
      },
    ]
  }

  static typeDataByKey(type) {
    switch (type) {
      case 'coverholder':
        return {
          label: i18n.tc('entities.coverholder'),
          badge: 'soft-secondary',
          short: 'CH',
          plural: i18n.tc('entities.coverholders'),
        }
      case 'agent':
        return {
          label: i18n.tc('common.agent'),
          badge: 'soft-primary',
          short: 'AG',
          plural: i18n.tc('common.agents'),
        }
      case 'insurer':
        return {
          label: i18n.tc('entities.insurer'),
          badge: 'soft-info',
          short: 'IN',
          plural: i18n.tc('entities.insurers'),
        }
    }
  }

  statusData() {
    switch (this.status) {
      case 'active':
        return {
          label: i18n.tc('statuses.active'),
          badge: 'soft-success',
        }
      case 'inactive':
        return {
          label: i18n.tc('statuses.inactive'),
          badge: 'soft-danger',
        }
    }
  }

  typeData() {
    switch (this.type) {
      case 'coverholder':
        return {
          label: i18n.tc('entities.coverholder'),
          badge: 'soft-secondary',
          short: 'CH',
          plural: i18n.tc('entities.coverholders'),
        }
      case 'agent':
        return {
          label: i18n.tc('common.agent'),
          badge: 'soft-primary',
          short: 'AG',
          plural: i18n.tc('common.agents'),
        }
      case 'insurer':
        return {
          label: i18n.tc('entities.insurer'),
          badge: 'soft-info',
          short: 'IN',
          plural: i18n.tc('entities.insurers'),
        }
    }
  }
}
