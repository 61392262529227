import BaseNodeResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/BaseNodeResolver.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import _ from 'lodash'

export default class ShowByValueInRange extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new BaseNodeResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const value = parseFloat(this.resolver.getValueByRuleOrSource(rule))

    const parameters = this.resolver.parseParameters(rule.parameters)

    if (
      parseFloat(_.first(parameters)) <= value &&
      value <= parseFloat(_.last(parameters))
    ) {
      this.resolver.setVisibility(rule.target, true)
    } else {
      this.resolver.setVisibility(rule.target, false)
    }

    return this.baseNode
  }
}
