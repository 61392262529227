import _ from 'lodash'

export default class SelectOptionModel {
  label = null
  meta = null
  value = null
  selected = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  toCamelCase() {
    if (this.meta) {
      return _.mapKeys(this.meta, (value, key) => _.camelCase(key))
    }

    return undefined
  }
}
