
<div
  class="toggler"
  :class="{ 'form-group': true, [customClass]: !!customClass }"
>
  <div class="d-flex align-items-start">
    <TraceLabel
      class="mb-3"
      :node="quoteNode"
      :label="label"
      :required="required"
      :name="id"
    />
    <FieldHint v-if="hint" class-name="mt-n1 ml-3" :text="hint" />
  </div>

  <small v-if="description" class="form-text text-muted">
    {{ description }}
  </small>

  <div class="custom-control custom-switch">
    <input
      :id="id || dotKey"
      v-model="model"
      :value="model"
      type="checkbox"
      class="custom-control-input"
      :disabled="readonly"
      :name="name"
    />
    <label
      :data-testid="dataTestId"
      class="custom-control-label"
      :for="id"
      @click="toggleModel"
    ></label>
    <small v-if="helperText" class="ml-1 text-muted">
      {{ helperText }}
    </small>
  </div>

  <ErrorLabel :show-error="showError" :error="error" />

  <small v-if="traceMode" class="trace-dot-key">
    {{ id || traceLabel || dotKey }}
  </small>

  <UiRulesError
    v-if="uiRulesError && traceMode"
    :id="id || dotKey"
    :message="uiRulesError.message"
    :rule="uiRulesError.rule"
  />
</div>
