import _ from 'lodash'
import * as math from 'mathjs'

export const TOTAL_CELL = 'total'

export default class MoneyTableCell {
  fields = null
  column = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }

  updateCellTotal() {
    if (_.isArray(this.fields)) {
      const values = this.fields
        .filter((item) => item?.id !== TOTAL_CELL)
        .map((item) => item?.node?.value)
      const totalCell = _.find(this.fields, (item) => item?.id === TOTAL_CELL)
      const method = totalCell.method || this.getColumnCalculationMethod()
      const newTotal = this.constructor.calcFieldTotalValue(values, method)
      if (_.isObject(totalCell)) {
        totalCell.setValue(newTotal)
      }
    }
  }

  getColumnCalculationMethod() {
    return _.get(this.column, 'method', 'sum')
  }

  /**
   * @param {array} values
   * @param {operator} string
   * @returns {number}
   */
  static calcFieldTotalValue(values = [], operator) {
    const expr = values
      .filter((value) => !isNaN(value) && value !== null)
      .join(',')
    const parser = math.parser()
    if (!expr.length) {
      return 0
    }
    return parser.evaluate(`${operator}(${expr})`)
  }

  /**
   * @param {object}
   * @returns {number}
   */
  static calcFieldValue({ properties, currency, jsonKey, operator }) {
    const parser = math.parser()

    if (_.isArray(properties) && jsonKey) {
      const values = properties
        .filter(
          (item) =>
            item &&
            item.jsonKey === jsonKey &&
            item.currency === currency &&
            item.value
        )
        .map((item) => item.value)
        .join(',')
      if (!values.length) {
        return 0
      }
      return parser.evaluate(`${operator}(${values})`)
    }
    return 0
  }

  static getFieldCurrency({ properties, jsonKey }) {
    if (!_.isArray(properties)) return

    const field = properties.find((item) => item.jsonKey === jsonKey)

    return _.get(field, 'currency')
  }

  static applyFormula(expr, scope) {
    return math.evaluate(expr, scope)
  }

  static buildScope(formula, cells) {
    return formula.split(' ').reduce((acc, part) => {
      const cell = cells.find((cell) => cell.column === part)
      if (!cell) {
        return acc
      }

      const field = cell.fields.find((field) => field.id === TOTAL_CELL)
      if (!field) {
        return acc
      }

      acc[part] = field.node.value
      return acc
    }, {})
  }

  static buildColumnFormulaScope(formula, row, index = 0) {
    return formula.split(' ').reduce((acc, part) => {
      const col = row[part]
      if (!col) {
        return acc
      }

      const field = col[index]
      if (!field) {
        return acc
      }

      acc[part] = field.node.value
      return acc
    }, {})
  }

  /**
   * Get currency based on total cell currency
   * @returns {string|undefined}
   */
  getFieldsTotalCurrency() {
    if (_.isEmpty(this.fields) || !_.isArray(this.fields)) return
    const totalCell = this.fields.find((cell) => cell.id === TOTAL_CELL)
    if (totalCell) return totalCell.currency
  }
}
