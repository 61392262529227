const isIterable = obj => {
  // checks for null and undefined
  if (obj == null) {
    return false
  }
  return typeof obj[Symbol.iterator] === 'function'
}

/**
 *
 * @param {string} emailAddress
 * @returns {boolean}
 */
const validateEmailAddress = emailAddress => {
  const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !!emailAddress.match(emailPattern)
}

/**
 *
 * @param {array} value
 * @returns {{ error: boolean, message?: string }}
 */
const validateEmailAddresses = value => {
  let errors = []
  if (_.isArray(value)) {
    value.forEach(element => {
      const emailAddress =
        typeof element === 'string'
          ? element
          : _.isObject(element)
          ? element.value
          : ''
      if (validateEmailAddress(emailAddress)) {
        return
      }
      errors.push(emailAddress)
    })
  }
  if (errors.length > 0) {
    return {
      error: true,
      message:
        errors.join(', ') +
        (errors.length > 1 ? ' are' : ' is') +
        ' not valid email' +
        (errors.length > 1 ? 's' : ''),
    }
  }
  return {
    error: false,
  }
}

export default {
  isIterable,
  validateEmailAddress,
  validateEmailAddresses,
}
