<template>
  <div class="custom-control custom-switch" :data-testid="dataTestId" @click="$emit('toggle')">
    <input
      :id="id"
      value="0"
      type="checkbox"
      class="custom-control-input"
      :disabled="readonly"
    />
    <label class="custom-control-label" :for="id" />
  </div>
</template>

<script>
export default {
  name: 'CollapseToggler',

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
.custom-switch {
  border-radius: 0.75rem;

  &:focus {
    outline: none;
  }

  &.not-collapsed {
    .custom-control-label::after {
      background-color: #fff;
      transform: translateX(1.5rem);
    }

    .custom-control-label::before {
      border-color: #2c7be5;
      background-color: #2c7be5;
    }
  }

  &.collapsed {
    .custom-control-label::after {
      top: 0.1875rem;
      left: -3.3125rem;
      background-color: #fff;
    }
  }
}
</style>