import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import Route from '@/Setup/Routes/Route.js'

export const documentTemplateRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('document-templates', {
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .only(['show'])
      .permissions({
        show: 'document-templates.view',
      })
      .resolve(),

    new Route('document-templates.list', '/document-templates/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('document-templates.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
