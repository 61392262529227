import _ from 'lodash'

export default class EndorsementGroupModel {
  id = null
  tag = null
  summary = null
  data = null

  constructor (data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value

      this.data = data.additional_data
    })
  }
}
