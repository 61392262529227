import camelcase from 'camelcase'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export default class CurrencyModel {
  code = null
  name = null
  symbol = null
  placement = 'before'
  isActive = false
  isPrimary = false

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })
  }

  static placements() {
    return [
      {
        label: i18n.tc('currency.before'),
        value: 'before',
      },
      {
        label: i18n.tc('currency.after'),
        value: 'after',
      },
    ]
  }
}
