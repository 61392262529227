import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import numToText from '@/General/numToText.js'
import _ from 'lodash'

export default class NumberToWords extends UiRuleAction {
  constructor(baseNode) {
    super(baseNode, new ConditionResolver(baseNode))
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    const currentValue = _.get(
      this.baseNode,
      `properties.${this.resolver.getPathToNode(uiRule.source)}.value`
    )

    const newValue = numToText(currentValue)

    this.resolver.setValueToNode(uiRule.target, newValue, uiRule.trigger)
    return this.baseNode
  }
}
