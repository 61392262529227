
<div v-if="loading" class="overlay">
  <rise-loader
    class="loader"
    color="#2c7be5"
    :loading="loading"
    :size="20"
    size-unit="px"
  ></rise-loader>
</div>
