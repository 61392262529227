export default class UiRuleAction {
  constructor(baseNode, resolver, quote, store) {
    this.baseNode = baseNode
    this.resolver = resolver
    this.quote = quote
    this.store = store
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute() {
    throw new Error('You have to implement the method execute!')
  }
}
