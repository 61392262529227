export default class {
  callback = () => {}
  middleware = []

  constructor (callback) {
    this.callback = callback
  }

  resolve () {
    return this.resolveMiddlewares(this.callback())
  }

  resolveMiddlewares (routes) {
    _.each(routes, (route, index) => {
      routes[index].meta.middleware = _.merge(routes[index].meta.middleware, this.middleware)
    })

    return routes
  }

  middlewares (middlewares) {
    this.middleware = middlewares

    return this
  }
}
