<template>
  <div v-if="loading" class="overlay">
    <rise-loader
      class="loader"
      color="#2c7be5"
      :loading="loading"
      :size="20"
      size-unit="px"
    ></rise-loader>
  </div>
</template>

<script>
import { LOADING_GETTER } from '@/Modules/Base/BaseModule.js'
import { RiseLoader } from '@saeris/vue-spinners'

export default {
  name: 'Loader',

  components: { RiseLoader },

  computed: {
    loading: function () {
      return this.$store.getters['BaseModule/' + LOADING_GETTER]
    },
  },
}
</script>

<style scoped>
.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9998;
  background-color: #2c7be521;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
