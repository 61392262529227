export default {
  computed: {
    translatedOptions: function() {
      if (!this.node?.options) {
        return []
      }

      const options = _.map(this.node.options, option => {
        option.label = this.__(option.label)
        return option
      })

      if (this.node.uiProperties.sorting) {
        const { sorting } = this.node.uiProperties
        switch (sorting) {
          case 'ascending':
          case 'asc':
            return options.sort(this.sortByLabel())
          case 'descending':
          case 'desc':
            return options.sort(this.sortByLabel(false))
          default:
            return options
        }
      }

      return options
    },
  },

  methods: {
    sortByLabel(asc = true) {
      return (a, b) => {
        const labelA = a.label?.toUpperCase && a.label.toUpperCase()
        const labelB = b.label?.toUpperCase && b.label.toUpperCase()
        if (labelA < labelB) {
          return asc ? -1 : 1
        }
        if (labelA > labelB) {
          return asc ? 1 : -1
        }

        return 0
      }
    },
  },
}
