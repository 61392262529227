import _ from 'lodash'
import UserModel from '@/Modules/User/UserModel.js'
import axios from 'axios'

export const SET_AUTH_USER_MUTATION = 'setUser'
export const RESET_AUTH_USER = 'resetAuthUser'
export const AUTH_USER_GETTER = 'user'
export const GET_AUTH_USER_ACTION = 'getUser'
export const GET_AUTH_USER_ACTION_ALT = _.camelCase('GET_AUTH_USER_ACTION_ALT')

export const AuthModule = {
  namespaced: true,

  state: {
    user: null,
  },

  mutations: {
    [SET_AUTH_USER_MUTATION]: (state, user) => {
      state.user = new UserModel(user)
    },

    [RESET_AUTH_USER]: (state) => {
      state.user = null
    },
  },

  getters: {
    [AUTH_USER_GETTER]: (state) => {
      return state.user
    },
  },

  actions: {
    [GET_AUTH_USER_ACTION_ALT]: async ({ commit }) => {
      return axios
        .get('/me', {
          excludeLoader: true,
          forceRefreshOnError: false,
        })
        .then((response) => {
          const user = response.data.data
          commit(SET_AUTH_USER_MUTATION, user)

          return user
        })
        .catch((error) => {
          throw error
        })
    },
    [GET_AUTH_USER_ACTION]: async ({ commit }) => {
      return axios
        .get('/me', {
          excludeLoader: true,
        })
        .then((response) => {
          const user = response.data.data
          commit(SET_AUTH_USER_MUTATION, user)

          return user
        })
        .catch(async (error) => {
          if (error.response.status === 401) {
            await localStorage.removeItem('auth_token')
            await localStorage.setItem('expired_session', 'true')
            await location.reload()
          }
        })
    },
  },
}
