import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import Route from '@/Setup/Routes/Route.js'
import auth from '@/Middlewares/auth.js'

export const exportImportRoutes = new RouteGroup(() => {
  return [
    new Route('export-import.preload', '/preload-import', () =>
      import(/* webpackChunkName: "admin" */ './Pages/PreloadImport.vue')
    )
      .permission('imports.preload')
      .resolve(),

    new Route('export-import.import', '/process-import/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/ProcessImport.vue')
    )
      .permission('imports.process')
      .resolve(),

    new Route('export-import.export', '/export', () =>
      import(/* webpackChunkName: "admin" */ './Pages/Export.vue')
    )
      .permission('exports.data')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
