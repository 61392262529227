import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const scheduledTaskRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('scheduled-tasks', {
      create: () =>
        import(/* webpackChunkName: "admin" */ './Pages/Create.vue'),
      edit: () => import(/* webpackChunkName: "admin" */ './Pages/Edit.vue'),
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .except(['list'])
      .permissions({
        create: 'scheduled-tasks.create',
        edit: 'scheduled-tasks.update',
        show: 'scheduled-tasks.view',
      })
      .meta({
        create: { back: 'scheduled-tasks.list' },
        edit: { back: 'scheduled-tasks.list' },
      })
      .resolve(),

    new Route('scheduled-tasks.list', '/scheduled-tasks/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('scheduled-tasks.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
