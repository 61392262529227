import camelcase from 'camelcase'
import _ from 'lodash'

export default class ClientContactModel {
  id = null
  firstName = null
  middleName = null
  lastName = null
  code = null
  title = null
  phone = null
  email = null
  isPrimary = false

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[camelcase(field)] = value
    })
  }

  get fullName() {
    return `${this.firstName ? this.firstName + ' ' : ''}${
      this.middleName ? this.middleName + ' ' : ''
    }${this.lastName}`
  }
}
