<template>
  <div v-if="help.visible" v-can:system.help class="help-sidebar">
    <div
      class="d-flex justify-content-between align-items-center help-header p-3"
    >
      <h4 class="m-0 p-0">
        {{ __('schemas.help') }}
      </h4>
      <b-button variant="light" size="sm" @click="handleCloseButtonClick">
        <Icon icon="times" size="14" />
      </b-button>
    </div>
    <small v-if="traceMode" class="p-3"
      >Route name: <span class="text-muted">{{ $route.name }}</span></small
    >
    <div
      v-if="helpPageContent"
      class="help-content p-3"
      v-html="helpPageContent"
    />
  </div>
</template>

<script>
import { TOGGLE_HELP_ACTION, HELP_GETTER } from '@/Modules/Base/BaseModule.js'
import CDNService from '@/Modules/CDN/CDNService.js'
import { mapActions, mapGetters } from 'vuex'
import { marked } from 'marked'

export default {
  data() {
    return {
      helpPage: null,
      traceMode: false,
    }
  },

  computed: {
    ...mapGetters('BaseModule', {
      help: HELP_GETTER,
    }),

    helpPageContent: function () {
      if (!this.helpPage?.helpPage?.body) {
        return null
      }

      return marked(this.helpPage.helpPage.body)
    },
  },

  created() {
    this.traceMode = this.$route.query.hasOwnProperty('trace')
    this.$watch(
      '$route.name',
      function (newValue) {
        if (!newValue || !this.help.visible) {
          return
        }

        this.fetchHelpPage(newValue)
      },
      { immediate: true }
    )

    this.$watch('help.visible', function (newValue) {
      if (newValue === false) {
        this.helpPage = null
        return
      }
      if (newValue === true && this.helpPage === null) {
        this.fetchHelpPage(this.$route.name)
      }
    })
  },

  methods: {
    ...mapActions('BaseModule', {
      toggleHelp: TOGGLE_HELP_ACTION,
    }),

    handleCloseButtonClick() {
      this.toggleHelp(false)
    },

    fetchHelpPage(route) {
      CDNService.getHelpPage(route)
        .then((helpPage) => (this.helpPage = helpPage))
        .catch((error) => console.error(error))
    },
  },
}
</script>
<style scoped lang="scss">
.help-sidebar {
  position: sticky;
  height: 100vh;
  margin-bottom: -24px; // sticky position hack
  right: 0;
  top: 0;
  background: #fff;
  width: 360px;
  box-sizing: border-box;
  border-left: 2px solid #e3ebf6;

  .help-header {
    border-bottom: 1px solid #e3ebf6;

    .btn-light {
      background: transparent;
      border-color: transparent;
    }
  }

  .help-content {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
}
</style>
