import auth from '@/Middlewares/auth.js'
import ResourceRoute from '@/Setup/Routes/ResourceRoute.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const userRoutes = new RouteGroup(() => {
  return [
    ...new ResourceRoute('users', {
      show: () => import(/* webpackChunkName: "admin" */ './Pages/Show.vue'),
    })
      .permissions({
        show: 'users.view',
      })
      .except(['list'])
      .resolve(),

    new Route('users.list', '/users/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('users.view')
      .resolve(),

    new Route(
      'users.linked-entities.list',
      '/users/:userId/linked-entities',
      () =>
        import(/* webpackChunkName: "admin" */ './UserEntity/Pages/List.vue')
    )
      .permission('users.entities.view')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
