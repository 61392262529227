import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import moment from 'moment'

export default class SetToday extends UiRuleAction {
  constructor(baseNode, dateFormat) {
    super(baseNode, new ConditionResolver(baseNode))
    this.dateFormat = dateFormat
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  execute(rule) {
    const uiRule = new UiRule(rule)

    if (uiRule.condition && !this.resolver.evaluateCondition(uiRule)) {
      return this.baseNode
    }

    this.resolver.setValueToNode(
      uiRule.target,
      moment().format(this.dateFormat),
      uiRule.trigger
    )

    return this.baseNode
  }
}
