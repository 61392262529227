import axios from 'axios'

export default class SettingService {
  static list = () => axios
    .get('settings')

  static guestList = () => axios
    .get('guest-settings')

  static groupedList = () => axios
    .get(`settings/grouped`)

  static saveGroup = (group, values) => axios
    .post(`settings/group/${group}`, values)
}
