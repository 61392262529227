import EntityModel from '@/Modules/Entity/EntityModel.js'
import ProductModel from '@/Modules/Product/ProductModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const BinderModelStatus = Object.freeze({
  DRAFT: 'draft',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  DEACTIVATED: 'deactivated'
})

export default class BinderModel {
  id = null
  name = null
  umr = null
  description = null
  productId = null
  underwriterId = null
  period = null
  activationDate = null
  startDate = null
  endDate = null
  tag = null
  status = null
  sectionsSummary = null
  isActive = false
  isParent = false
  canRenew = false
  canActivate = false
  hasChilds = false
  hasPeriod = false
  breadcrumbs = null
  parentId = null
  renewedId = null
  underwritingYear = null
  mainWording = null
  insurerId = null
  data = null

  product = null
  underwriter = null
  parent = null
  renewed = null
  insurer = null

  constructor (data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.data = data.additional_data
    this.product = data.product && new ProductModel(data.product)
    this.underwriter = data.underwriter && new EntityModel(data.underwriter)
    this.parent = data.parent && new BinderModel(data.parent)
    this.renewed = data.renewed && new BinderModel(data.renewed)
    this.insurer = data.insurer && new EntityModel(data.insurer)
  }

  statusData () {
    switch (this.status) {
      case 'draft':
        return {
          label: i18n.tc('statuses.draft'),
          badge: 'soft-secondary',
        }
      case 'active':
        return {
          label: i18n.tc('statuses.active'),
          badge: 'soft-primary',
        }
      case 'expired':
        return {
          label: i18n.tc('statuses.expired'),
          badge: 'soft-danger',
        }
      case 'deactivated':
        return {
          label: i18n.tc('statuses.deactived'),
          badge: 'soft-danger',
        }
    }
  }
}
