import _ from 'lodash'

export default class QuoteDuplicateModel {
  id = null
  number = null
  policyNumber = null
  version = 1

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }
}
