export default function numToText(num, currency = 'eur') {
  num = parseFloat(num).toFixed(2)
  num = Math.abs(num)
  let originalNumberToString = num.toString()
  let centsValue = originalNumberToString.split('.')[1] ?? '00'

  if (centsValue.length === 1) {
    centsValue = centsValue + '0'
  }

  const units = [
    '',
    'vienas',
    'du',
    'trys',
    'keturi',
    'penki',
    'šeši',
    'septyni',
    'aštuoni',
    'devyni',
  ]

  const subTen = [
    '',
    'vienuolika',
    'dvylika',
    'trylika',
    'keturiolika',
    'penkiolika',
    'šešiolika',
    'septyniolika',
    'aštuoniolika',
    'devyniolika',
  ]

  const tens = [
    '',
    'dešimt',
    'dvidešimt',
    'trisdešimt',
    'keturiasdešimt',
    'penkiasdešimt',
    'šešiasdešimt',
    'septyniasdešimt',
    'aštuoniasdešimt',
    'devyniasdešimt',
  ]

  const numWord = [
    ['milijonas', 'milijonai', 'milijonų'],
    ['tūkstantis', 'tūkstančiai', 'tūkstančių'],
  ]

  if (num >= 10 ** 9) {
    return `${num}`
  } else {
    const strInt_num = `${parseInt(`${num}`)}`
    const len = 9 - strInt_num.length
    const numOfZeros = Math.sign(len) === 1 ? len : 0

    const formatted = `${'0'.repeat(numOfZeros)}${strInt_num}`

    const numTripletArray = formatted.split(/(.{3})/g).filter((N) => N)
    const numSet = []
    numTripletArray.forEach((triplet, n) => {
      num = parseInt(triplet) || 0

      let numCase = 0

      const hundDiv = Math.floor(num / 100)

      if (hundDiv > 0) {
        if (hundDiv > 1) {
          numSet.push(units[hundDiv])
        }
        numSet.push(hundDiv > 1 ? 'šimtai' : 'šimtas')
      }

      const subHundNum = num % 100
      const tensValue = Math.floor(subHundNum / 10)
      const unitValue = subHundNum % 10

      if (10 < subHundNum && subHundNum < 20) {
        numSet.push(subTen[unitValue])
        numCase = 2
      } else {
        if (tensValue > 0) {
          numSet.push(tens[tensValue])
        }
        if (unitValue > 0) {
          numSet.push(units[unitValue])
          numCase = unitValue > 1 ? 1 : 0
        } else {
          numCase = 2
        }
      }
      if (n < numWord.length && triplet !== '000') {
        numSet.push(numWord[n][numCase])
      }
    })
    let number = numSet.join(' ').trim()
    number = number.replace('  ', ' ')

    if (centsValue.charAt(0) === '0') {
      centsValue = centsValue.substring(1)
    }

    return `${number} ${currency.toUpperCase()}, ${centsValue} ct.`
  }
}
