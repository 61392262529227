export default {
  data() {
    return {
      model: this.value,
    }
  },

  created() {
    this.$watch('value', (newValue) => {
      this.model = newValue
    })
  },

  computed: {
    dotKey: function () {
      if (!this.name) {
        return null
      }

      return this.name
        .replace(/]\[/g, '.')
        .replace(/\[/g, '.')
        .replace(/]/g, '')
    },

    hasErrors: function () {
      let has = _.find(
        _.values(this.errors.items),
        (item) => item.field === this.dotKey
      )

      return !!has
    },
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    quoteNode: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    permission: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: {
      default: null,
    },
    autofill: {
      type: String,
      default: 'on',
    },
  },
}
