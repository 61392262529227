import _ from 'lodash'

export default class EndorsementModel {
  id = null
  name = null
  tag = null
  description = null
  extraPremium = null
  extraPremiumCurrencyCode = null
  coins = null
  valuation = null
  limit = null
  limitCurrencyCode = null
  deductible = null
  deductibleCurrencyCode = null
  libraryId = null
  data = null
  nameWithTag = null
  index = null
  showDescription = true
  groups = []
  productId = null
  product = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })
  }
}
