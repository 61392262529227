
<div class="d-flex client-append-wrapper">
  <div
    v-if="node.value && onRemove"
    class="input-group-text deselect-button client-append-control"
    data-testid="deselect-client-btn"
    @click="onRemove"
  >
    <Icon icon="times" size="14" class="deselect-trigger" />
  </div>

  <div
    v-if="node.value"
    class="input-group-text overview-button client-append-control"
  >
    <RouterLink
      :to="{
        name: routeName,
        params: routeParams,
      }"
      target="_blank"
    >
      <Icon icon="eye" size="14" class="deselect-trigger" />
    </RouterLink>
  </div>

  <b-button
    v-if="node.value && onEdit"
    variant="primary"
    class="add-client-button client-append-control"
    @click="onEdit"
  >
    <Icon icon="edit" size="16" />
  </b-button>
  <b-button
    v-if="!node.value && onCreate"
    variant="primary"
    class="add-client-button client-append-control"
    @click="onCreate"
  >
    <Icon icon="plus" size="16" />
  </b-button>
  <div
    v-if="!node.value && isCreateDisabled"
    class="input-group-text append-placeholder"
  />
</div>
