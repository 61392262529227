<template>
  <button
    :class="{
      'btn mt-1 mb-1 nowrap': true,
      [customClass]: !!customClass,
      lift: lift,
    }"
    :disabled="isDisabled"
    :name="name"
    :type="type"
    @click="
      $emit('click')
      $emit('onClick')
    "
  >
    <i v-if="icon" :class="{ [icon]: !!icon, 'mr-2': true }"></i>
    {{ label }}
  </button>
</template>

<script>
import ButtonComponent from '@/General/Form/Mixins/ButtonComponent.js'
import { LOADING_GETTER } from '@/Modules/Base/BaseModule.js'

export default {
  name: 'VueButton',

  mixins: [ButtonComponent],
  computed: {
    isDisabled() {
      return (
        this.$store.getters['BaseModule/' + LOADING_GETTER] || this.disabled
      )
    },
  },
}
</script>
