import _ from 'lodash'
import EntityModel from '@/Modules/Entity/EntityModel.js'
import { i18n } from '@/Setup/SetupI18n.js'

export const PaymentAccountType = Object.freeze({
  wire: 'wire',
  cash: 'cash',
  creditCard: 'credit_card',
  cheque: 'cheque',
  stripe: 'stripe',
  writeOff: 'write_off',
})

export const PaymentAccountAccountType = Object.freeze({
  trust: 'trust',
  business: 'business',
})

export default class PaymentAccountModel {
  id = null
  type = PaymentAccountType.wire
  tag = null
  summary = null
  accountName = null
  accountNumber = null
  accountType = PaymentAccountAccountType.business
  entityId = null
  bankName = null
  bankAddress = null
  swiftCode = null
  sortCode = null
  paymentInstructions = null
  isPrimary = false
  onInvoice = false

  entity = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.entity = data.entity && new EntityModel(this.entity)
  }

  static accountTypes() {
    return [
      {
        label: i18n.tc('payment-accounts.account-type-trust'),
        value: PaymentAccountAccountType.trust,
      },
      {
        label: i18n.tc('payment-accounts.account-type-business'),
        value: PaymentAccountAccountType.business,
      },
    ]
  }

  static types() {
    return [
      {
        label: i18n.tc('payment-accounts.type-wire'),
        value: PaymentAccountType.wire,
      },
      {
        label: i18n.tc('payment-accounts.type-cash'),
        value: PaymentAccountType.cash,
      },
      {
        label: i18n.tc('payment-accounts.type-cheque'),
        value: PaymentAccountType.cheque,
      },
      {
        label: i18n.tc('payment-accounts.type-credit-card'),
        value: PaymentAccountType.creditCard,
      },
      {
        label: i18n.tc('payment-accounts.type-stripe'),
        value: PaymentAccountType.stripe,
      },
      {
        label: i18n.tc('payment-accounts.type-write-off'),
        value: PaymentAccountType.writeOff,
      },
    ]
  }
}
