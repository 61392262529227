
<transition name="fade">
  <div
    v-if="visible"
    class="navbar-toggler-persistent"
    :class="{ collapsed: !expanded }"
  >
    <button
      v-if="expanded"
      size="sm"
      class="nav-toggle-button"
      @click="
        () => {
          onToggle({ collapse: true })
        }
      "
    >
      <Icon class="grey" icon="angle-left" />
    </button>
    <button
      v-else
      size="sm"
      class="nav-toggle-button"
      @click="
        () => {
          onToggle({ collapse: false })
        }
      "
    >
      <Icon class="grey" icon="angle-right" />
    </button>
  </div>
</transition>
