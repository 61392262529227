import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export const addressType = Object.freeze({
  legal: 'legal',
  office: 'office',
  work: 'work',
  postal: 'postal',
  home: 'home',
})

export default class ClientAddressModel {
  id = null
  types = []
  clientId = null
  isPrimary = false
  building = null
  unit = null
  street = null
  town = null
  county = null
  country = null
  postCode = null
  address = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.types = data.types ? [...data.types] : [addressType.legal]
  }

  static types() {
    return [
      {
        label: i18n.tc('address.type-legal'),
        value: addressType.legal,
      },
      {
        label: i18n.tc('address.type-office'),
        value: addressType.office,
      },
      {
        label: i18n.tc('address.type-work'),
        value: addressType.work,
      },
      {
        label: i18n.tc('address.type-postal'),
        value: addressType.postal,
      },
      {
        label: i18n.tc('address.type-home'),
        value: addressType.home,
      },
    ]
  }

  fullAddress() {
    return _.filter([
      this.building,
      this.unit,
      this.street,
      this.town,
      this.county,
      this.country,
    ]).join(', ')
  }

  /**
   *
   * Matching quote keys for client address mapping
   * @param {object} addressObject
   * @param {array} keys
   * @param {boolean} firstMatch
   * @returns {function}
   */
  static addressKeysMatcher(keys, firstMatch = true) {
    return addressObject => {
      if (_.isObject(addressObject) && _.isArray(keys)) {
        const lowerKeys = keys
          .filter(key => typeof key === 'string')
          .map(key => key.toLowerCase())
        const matchingKeys = _.keys(addressObject).filter(key =>
          lowerKeys.includes(key.toLowerCase())
        )
        if (firstMatch) {
          return _.first(matchingKeys)
        }
        return matchingKeys
      }
    }
  }
}
