import RouteGroup from '@/Setup/Routes/RouteGroup.js'
import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'

export const apiLogRoutes = new RouteGroup(() => {
  return [
    new Route('api-logs.list', '/api-logs/:tab?', () =>
      import(/* webpackChunkName: "admin" */ './Pages/List.vue')
    )
      .permission('quotes.logs.integration.all')
      .resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
