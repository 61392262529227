import axios from 'axios'
import TranslationLibraryModel from '@/Modules/TranslationLibrary/TranslationLibraryModel.js'

export default class TranslationLibraryService {
  static URL = '/translation-libraries'

  static list = ({ parameters, filters }) =>
    axios.get(`${ this.URL }${ filters }`, {
      excludeLoader: true,
      params: parameters,
    })

  static show = id => axios
    .get(`${ this.URL }/${ id }`)
    .then(response => new TranslationLibraryModel(response.data.data))

  static create = payload => axios.post(this.URL, payload)

  static delete = id => axios.delete(`${ this.URL }/${ id }`)

  static assigned = () => axios.get(`${ this.URL }/assigned`)
    .then(response => _.map(response.data.data, item => new TranslationLibraryModel(item)))
}
