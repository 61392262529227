export default function auth ({ onNextActions, next, router }) {
  if (! localStorage.getItem('auth_token')) {
    return router.push({ name: 'auth.login' })
  }

  return next(() => {
    onNextActions.forEach(action => {
      action()
    })
  })
}
