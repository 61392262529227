import auth from '@/Middlewares/auth.js'
import Route from '@/Setup/Routes/Route.js'
import RouteGroup from '@/Setup/Routes/RouteGroup.js'

export const profileRoutes = new RouteGroup(() => {
  return [
    new Route('profile.show', '/profile', () =>
      import('./Pages/Show.vue')
    ).resolve(),
    new Route('profile.edit', '/profile/edit', () =>
      import('./Pages/Edit.vue')
    ).resolve(),
  ]
})
  .middlewares([auth])
  .resolve()
