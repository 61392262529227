<template>
  <div
    v-if="visible"
    :id="id || dotKey"
    :class="{
      'form-group': true,
      [customClass]: !!customClass,
    }"
  >
    <div class="d-flex justify-content-between align-items-center w-100">
      <TraceLabel
        :node="quoteNode"
        :label="label"
        :required="required"
        :name="id"
      />
      <small v-if="annotation" class="text-muted">
        {{ annotation }}
      </small>
      <FieldHint v-if="hint" :text="hint" />
    </div>

    <small v-if="description" class="form-text text-muted">
      {{ description }}
    </small>

    <div
      class="d-flex"
      :class="{
        'multiselect-appended': !!$slots['append'] && !disableAppend,
      }"
    >
      <b-btn
        v-if="allowSelectAll"
        class="mr-2 nowrap"
        variant="primary"
        @click="toggleAll()"
      >
        Toggle All
      </b-btn>
      <multiselect
        :id="id"
        ref="multiselect"
        v-model="model"
        v-validate="validation"
        v-b-tooltip.hover="{
          html: true,
          customClass: tooltipClass,
          title: tooltip,
        }"
        :open-direction="openDirection"
        :is-money-select="isMoneySelect"
        :is-pagination-select="isPaginationSelect"
        :precision="precision"
        :data-vv-name="dotKey"
        :value="model"
        :disabled="readonly"
        :name="name"
        :class="{
          readonly: readonly,
          'is-invalid': hasErrors,
          'fixed-height': !!multiple,
          [`is-${highlightClass}`]: !!highlightClass,
        }"
        :multiple="multiple"
        :label="itemLabel"
        :track-by="trackBy"
        :options="options"
        :placeholder="placeholder"
        select-label=""
        deselect-label=""
        selected-label=""
        :preserve-search="preserveSearch"
        :close-on-select="closeOnSelect"
        :allow-empty="allowEmpty"
        :custom-label="customLabel"
        :preselect-first="firstByDefault"
        :taggable="taggable"
        :loading="isLoading"
        @input="emitChange"
        @search-change="$emit('searchChange', $event)"
        @tag="addTag"
        @open="$emit('onOpen', $event)"
      />

      <slot name="append" />
    </div>

    <input v-if="!multiple" :value="selectValue" type="hidden" :name="name" />

    <template v-for="(item, iteration) in selectValue" v-else>
      <input :key="iteration" :value="item" type="hidden" :name="name + '[]'" />
    </template>

    <ErrorLabel :show-error="showError" :error="error" />

    <small v-if="traceMode" class="trace-dot-key">
      {{ id || traceLabel || dotKey }}
    </small>

    <UiRulesError
      v-if="uiRulesError && traceMode"
      :id="id || dotKey"
      :message="uiRulesError.message"
      :rule="uiRulesError.rule"
    />
  </div>
</template>

<script>
import Multiselect from '@/General/Form/Fields/Multiselect.vue'
import InputComponent from '@/General/Form/Mixins/InputComponent.js'

export default {
  name: 'SelectField',

  components: { Multiselect },

  mixins: [InputComponent],

  props: {
    taggable: {
      type: Boolean,
      default: false,
    },

    isMoneySelect: {
      type: Boolean,
      default: false,
    },
    isPaginationSelect: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    preserveSearch: {
      type: Boolean,
      default: false,
    },

    customLabel: {
      type: Function,
      default: undefined,
    },

    itemLabel: {
      type: String,
      default: 'label',
    },

    trackBy: {
      type: String,
      default: 'value',
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    firstByDefault: {
      type: Boolean,
      default: false,
    },

    annotation: {
      type: String,
      default: '',
    },

    onOpen: {
      type: Function,
      default: () => {},
    },

    disableAppend: {
      type: Boolean,
      default: false,
    },

    precision: {
      type: Number,
      default: 2,
    },

    openDirection: {
      type: String,
      default: function () {
        return this.setting('ui_select_dropdown_direction')
      },
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    allowSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let model = this.parseValue(this.value)

    return {
      model,
    }
  },

  computed: {
    selectValue: function () {
      if (_.isArray(this.model) && this.multiple) {
        return _.map(this.model, (item) => item?.value)
      }

      return this.model?.value
    },
  },

  created() {
    this.$watch('model', function (newValue) {
      this.model = this.parseValue(newValue)
    })

    this.$watch('options', function (newValue) {
      this.model = this.parseValue(this.value)
    })
  },

  methods: {
    isParsed(value) {
      return typeof value === 'object'
    },
    parseValue(value) {
      let parsedValue = value

      if (!this.isParsed(value) && !this.multiple) {
        const option = _.find(this.options, (item) => item.value == this.value)
        if (!option || _.isEmpty(option)) {
          return parsedValue
        }
        parsedValue = {
          label: this.__(option.label),
          value: this.value,
          meta: option.meta,
        }
      }

      if (!this.isParsed(value) && this.multiple) {
        const option = _.find(this.options, (item) => item.value == this.value)
        if (!option || _.isEmpty(option)) {
          return parsedValue
        }
        return [
          {
            label: this.__(option.label),
            value: this.value,
            meta: option.meta,
          },
        ]
      }

      if (_.isArray(value) && value.length && !this.isParsed(value[0])) {
        return _.map(value, (selectValue) => {
          if (!_.isObject(selectValue)) {
            let optionLabel = _.find(
              this.options,
              (item) => item.value == selectValue
            )?.label
            return {
              label: this.__(optionLabel || selectValue),
              value: selectValue,
            }
          }
          return {
            label: this.__(selectValue?.label),
            value: selectValue?.value,
          }
        })
      }
      return parsedValue
    },

    emitChange(event) {
      this.$emit('input', event)
      this.$emit('onChange', event)
      if (event) {
        this.$emit('changeDynamicFieldValue', event.value)
      }
    },

    addTag(newTag = '') {
      const tag = {
        label: newTag,
        value: newTag.trim(),
      }
      if (_.isArray(this.options)) {
        this.options.push(tag)
      }
      if (_.isArray(this.value)) {
        this.value.push(tag)
        this.emitChange(this.value)
      } else {
        this.emitChange([{ ...tag }])
      }
    },
    toggleAll() {
      if (this.model && this.model.length === this.options.length) {
        this.model = null
        this.emitChange(this.model)

        return
      }
      this.model = this.options
      this.emitChange(this.model)
    },
  },
}
</script>
