<template functional>
  <span v-if="props.required" class="text-danger"> * </span>
</template>

<script>
export default {
  name: 'RequiredNotation',

  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
