import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import QuoteEndorsementService from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementService.js'
import { FETCH_QUOTE_ENDORSEMENTS } from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'

const INDEX_MACRO = '{0}'

export default class UpdateEndorsementGroup extends UiRuleAction {
  constructor(baseNode, quote, store) {
    super(baseNode, new ConditionResolver(baseNode), quote, store)
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  async execute(rule) {
    if (!this.quote?.id) return this.baseNode

    const uiRule = new UiRule(rule)

    if (
      !uiRule.target ||
      (uiRule.condition && !this.resolver.evaluateCondition(uiRule))
    ) {
      return this.baseNode
    }

    const value = this.resolver.getValueBySourceOrParameters(rule)

    let section = _.isObject(uiRule.target)
      ? uiRule.target.section
      : uiRule.target

    const nodePath = _.isObject(uiRule.target)
      ? uiRule.target.path
      : uiRule.target

    if (section.includes(INDEX_MACRO) && uiRule.actualKey) {
      section = section.replace(INDEX_MACRO, uiRule.actualKey)
    }

    try {
      await QuoteEndorsementService.linkEndorsementsGroup(
        this.quote.id,
        section,
        value
      )

      this.store.dispatch(`QuoteFormModule/${FETCH_QUOTE_ENDORSEMENTS}`, {
        section,
        nodePath,
      })
    } catch (error) {
      console.error(error)
    } finally {
      return this.baseNode
    }
  }
}
