<template>
  <stack-modal
    :show="dialog.show"
    :save-button="{ visible: false }"
    :cancel-button="{ visible: false }"
    :modal-class="{
      [dialog.class]: !!dialog.class,
    }"
  >
    <template slot="modal-header">
      <div class="modal-header align-items-center">
        <span>{{ __('modal.activity-header') }}</span>
        <a
          class="modal-close"
          href="#"
          @click.prevent="closeActivityMonitorModal"
        >
          <Icon icon="times" size="18" />
        </a>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-center">
      <Icon icon="exclamation-octagon" class="text-danger mr-4" size="32" />
      <p class="p-0 m-0">
        {{ __('modal.activity-text', { sessionTimeLeft }) }}
      </p>
    </div>
    <template slot="modal-footer">
      <div class="p-4 d-flex align-items-center justify-content-end">
        <b-button
          class="mr-3"
          variant="outline-danger"
          size="sm"
          @click="closeModalAndLogout"
        >
          {{ __('buttons.logout') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="closeActivityMonitorModal"
        >
          {{ __('modal.button-session') }}
        </b-button>
      </div>
    </template>
  </stack-modal>
</template>

<script>
import { RESET_AUTH_USER } from '@/Modules/Auth/AuthModule.js'
import StackModal from '@innologica/vue-stackable-modal'
import _ from 'lodash'
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import { CLOSE_DIALOG_ACTION } from '@/Modules/Base/BaseModule.js'

export default {
  name: 'ActivityMonitor',

  components: { StackModal },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    sessionTTL: {
      type: Number,
      default: 1800,
    },
    dialogOpenOffset: {
      type: Number,
      default: 120,
    },
    intervalMs: {
      type: Number,
      default: 1000,
    },
  },

  data() {
    return {
      dialog: {
        show: this.showDialog,
        class: 'modal-sm',
      },
      monitor: null,
      secondsSinceLastActivity: 0,
    }
  },

  computed: {
    sessionTimeLeft() {
      const seconds = parseInt(this.ttlSeconds - this.secondsSinceLastActivity)
      const label = moment(new Date())
        .startOf('day')
        .seconds(seconds > 0 ? seconds : 0)
        .format('mm:ss')
      return label
    },
  },

  created() {
    this.ttlSeconds =
      import.meta.env.MIX_FRONTEND_SESSION_TTL || this.sessionTTL
    this.openDialogOffsetSeconds = this.dialogOpenOffset
    this.activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ]
    this.debouncedHandleActivity = _.debounce(this.handleActivity, 100)
    this.startMonitor()
  },

  mounted() {
    this.addListeners()
  },

  beforeDestroy() {
    this.stopMonitor()
    this.removeListeners()
  },

  methods: {
    ...mapMutations('AuthModule', {
      resetAuthUser: RESET_AUTH_USER,
    }),

    ...mapActions('BaseModule', {
      closeModal: CLOSE_DIALOG_ACTION,
    }),

    closeActivityMonitorModal() {
      this.dialog.show = false
      this.secondsSinceLastActivity = 0
    },

    startMonitor() {
      this.monitor = setInterval(() => {
        this.secondsSinceLastActivity++
        if (this.secondsSinceLastActivity > this.ttlSeconds) {
          this.handleTtlExpire()
          return
        }
        if (
          this.secondsSinceLastActivity >
          this.ttlSeconds - this.openDialogOffsetSeconds
        ) {
          this.dialog.show = true
        }
      }, this.intervalMs)
    },

    stopMonitor(logout = true) {
      clearInterval(this.monitor)
      this.closeModal()
      if (logout) {
        this.logout()
      }
    },

    handleTtlExpire() {
      if (!this.dialog.show) {
        this.dialog.show = true
      }
      this.stopMonitor()
    },

    handleActivity(event) {
      if (!this.dialog.show) {
        this.secondsSinceLastActivity = 0
      }
    },

    logout() {
      localStorage.removeItem('auth_token')
      this.resetAuthUser()
      this.$navigate('auth.login')
    },

    closeModalAndLogout() {
      this.closeModal()
      this.closeActivityMonitorModal()
      this.stopMonitor()
    },

    addListeners() {
      this.activityEvents.forEach((eventName) => {
        document.addEventListener(eventName, this.debouncedHandleActivity, true)
      })
    },

    removeListeners() {
      this.activityEvents.forEach((eventName) => {
        document.removeEventListener(
          eventName,
          this.debouncedHandleActivity,
          true
        )
      })
    },
  },
}
</script>
