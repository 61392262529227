import ConditionResolver from '@/Modules/Quote/Components/QuoteForm/UiRules/Conditions/ConditionResolver.js'
import UiRule from '@/Modules/Quote/Components/QuoteForm/UiRules/UiRule.js'
import UiRuleAction from '@/Modules/Quote/Components/QuoteForm/UiRules/Actions/UiRuleAction.js'
import {
  FETCH_QUOTE_ENDORSEMENTS,
  SET_ENDORSEMENTS_IN_SECTION,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import QuoteEndorsementService from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementService.js'
import EndorsementGroupService from '@/Modules/EndorsementLibrary/EndorsementGroup/EndorsementGroupService.js'
import QuoteEndorsementModel from '@/Modules/Quote/QuoteEndorsement/QuoteEndorsementModel.js'
import BaseNodeResolver from '../BaseNodeResolver.js'
const INDEX_MACRO = '{0}'

export default class SetEndorsementGroup extends UiRuleAction {
  constructor(baseNode, quote, store) {
    super(baseNode, new ConditionResolver(baseNode), quote, store)
  }

  async getEndorsementsFromGroup(group) {
    const response = await EndorsementGroupService.getEndorsements(group)

    const endorsements = response.data.map((endorsement, i) => {
      const model = new QuoteEndorsementModel(endorsement)
      model.index = i + 1
      return model
    })

    return endorsements
  }

  /**
   *
   * @param {object} rule
   * @returns {object} baseNode
   */
  async execute(rule) {
    const uiRule = new UiRule(rule)
    if (
      !uiRule.target ||
      (uiRule.condition && !this.resolver.evaluateCondition(uiRule))
    ) {
      return this.baseNode
    }

    const value = this.resolver.getValueBySourceOrParameters(rule)

    let section = _.isObject(uiRule.target)
      ? uiRule.target.section
      : uiRule.target

    const nodePath = _.isObject(uiRule.target)
      ? uiRule.target.path
      : uiRule.target

    if (section.includes(INDEX_MACRO) && uiRule.actualKey) {
      section = section.replace(INDEX_MACRO, uiRule.actualKey)
    }

    try {
      if (this.quote?.id) {
        if (uiRule.trigger === BaseNodeResolver.TRIGGER_ONLOAD()) {
          return this.baseNode
        }
        await QuoteEndorsementService.replaceEndorsementsGroup(
          this.quote.id,
          section,
          value
        )
      } else {
        const endorsements = await this.getEndorsementsFromGroup(value)

        this.store.dispatch(`QuoteFormModule/${SET_ENDORSEMENTS_IN_SECTION}`, {
          section,
          payload: endorsements,
        })

        return this.baseNode
      }

      this.store.dispatch(`QuoteFormModule/${FETCH_QUOTE_ENDORSEMENTS}`, {
        section,
        nodePath,
      })
    } catch (error) {
      console.error(error)
    } finally {
      return this.baseNode
    }
  }
}
