import {
  META_GETTER,
  UPDATE_META_PROPERTY,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import QuoteService from '@/Modules/Quote/QuoteService.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      showTogglable: false,
      showCollapsable: false,
      toggled: true,
      expanded: true,
    }
  },

  computed: {
    ...mapGetters('QuoteFormModule', {
      meta: META_GETTER,
    }),
  },

  created() {
    this.$watch('errors.items', async function () {
      const parentElement = await document.getElementById(
        `${this.snakeKey}_collapse`
      )

      if (parentElement) {
        let children = await parentElement.getElementsByClassName('is-invalid')
        let invalidFeedbackElements =
          parentElement.getElementsByClassName('invalid-feedback')

        const sectionHasErrors =
          invalidFeedbackElements.length > 0 || children.length > 0

        if (sectionHasErrors && !this.expanded) {
          this.updateMeta({
            node: this.node.nodePath,
            property: 'expanded',
            value: true,
          })
          this.$nextTick(() => {
            this.scrollToErrors()
          })
        }
      }
    })

    if (this.uiOptions.togglable) {
      this.showTogglable = true

      this.setTogglableByDefaultValueOrMeta()
    }

    if (this.uiOptions.collapsable) {
      this.showCollapsable = true

      this.setCollapsableByDefaultValueOrMeta()
    }
  },

  methods: {
    ...mapActions('QuoteFormModule', {
      updateMetaProperty: UPDATE_META_PROPERTY,
    }),

    toggleCollapsable(event) {
      this.updateMeta(
        {
          node: this.node.nodePath,
          property: 'expanded',
          value: !this.expanded,
        },
        false
      )
    },

    toggle(value) {
      this.updateMeta(
        {
          node: this.node.nodePath,
          property: 'toggled',
          value,
        },
        false
      )
    },

    updateMeta({ node, property, value }, fromDefault = true) {
      this[property] = value

      if (this.$route.name.includes('quotes-partial')) {
        return
      }

      this.updateMetaProperty({ node, property, value }).then(() => {
        if (this.baseNode.quoteId && !fromDefault) {
          QuoteService.updateMeta(this.baseNode.quoteId, this.meta).then()
        }
      })
    },

    setTogglableByDefaultValueOrMeta() {
      const meta = this.meta[this.node.nodePath]

      if (meta && typeof meta.toggled !== 'undefined') {
        this.toggled = meta.toggled

        return
      }

      if (this.uiOptions.togglable === 'enabled') {
        this.updateMeta({
          node: this.node.nodePath,
          property: 'toggled',
          value: true,
        })

        return
      }

      this.updateMeta({
        node: this.node.nodePath,
        property: 'toggled',
        value: false,
      })
    },

    setCollapsableByDefaultValueOrMeta() {
      const meta = this.meta[this.node.nodePath]

      if (meta && typeof meta.expanded !== 'undefined') {
        this.expanded = meta.expanded

        return
      }

      if (this.uiOptions.collapsable === 'expanded') {
        this.updateMeta({
          node: this.node.nodePath,
          property: 'expanded',
          value: true,
        })

        return
      }

      this.updateMeta({
        node: this.node.nodePath,
        property: 'expanded',
        value: false,
      })
    },
  },
}
