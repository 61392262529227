<template>
  <div class="d-flex client-append-wrapper">
    <div
      v-if="node.value && onRemove"
      class="input-group-text deselect-button client-append-control"
      data-testid="deselect-client-btn"
      @click="onRemove"
    >
      <Icon icon="times" size="14" class="deselect-trigger" />
    </div>

    <div
      v-if="node.value"
      class="input-group-text overview-button client-append-control"
    >
      <RouterLink
        :to="{
          name: routeName,
          params: routeParams,
        }"
        target="_blank"
      >
        <Icon icon="eye" size="14" class="deselect-trigger" />
      </RouterLink>
    </div>

    <b-button
      v-if="node.value && onEdit"
      variant="primary"
      class="add-client-button client-append-control"
      @click="onEdit"
    >
      <Icon icon="edit" size="16" />
    </b-button>
    <b-button
      v-if="!node.value && onCreate"
      variant="primary"
      class="add-client-button client-append-control"
      @click="onCreate"
    >
      <Icon icon="plus" size="16" />
    </b-button>
    <div
      v-if="!node.value && isCreateDisabled"
      class="input-group-text append-placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'ClientAppend',

  props: {
    node: {
      type: Object,
      default: () => {
        return {}
      },
    },
    onRemove: {
      type: Function,
      default: null,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    onEdit: {
      type: Function,
      default: null,
    },
    isEditDisabled: {
      type: Boolean,
      default: false,
    },
    isCreateDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    routeName() {
      switch (this.node.uiType) {
        case 'user':
          return 'users.show'
        case 'client':
          return 'clients.show'
        case 'entity':
          return 'entities.show'
        default:
          return 'users.show'
      }
    },

    routeParams() {
      switch (this.node.uiType) {
        case 'user':
          return {
            userId: this.$selectValue(this.node, 'value'),
          }
        case 'client':
          return {
            clientId: this.$selectValue(this.node, 'value'),
          }
        case 'entity':
          return {
            entityId: this.$selectValue(this.node, 'value'),
          }
        default:
          return {
            userId: this.$selectValue(this.node, 'value'),
          }
      }
    },
  },
}
</script>

<style lang="scss">
.d-flex {
  .overview-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.client-append-wrapper {
  position: relative;
  .append-placeholder {
    position: absolute;
    z-index: 1001;
    height: 100%;
    left: -2px;
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    width: 4px;
    padding: 0;
  }
  .client-append-control {
    &:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.deselect-button {
  border-radius: 0;
  padding: 0 10px;
  cursor: pointer;
}

.add-client-button {
  padding: 0 10px;
}

.overview-button {
  border-radius: 0;
  border-left: 0;
  cursor: pointer;
  padding: 0 10px;
  color: #2b7be5;

  a:hover {
    i {
      color: #2b7be5;
    }
  }

  .deselect-trigger:hover {
    color: #2b7be5;
  }
}

.overview-button:hover {
  color: #2b7be5;
}

.deselect-button:hover {
  color: red;
}

.pending-selection {
  .multiselect {
    input.multiselect__input:not(:focus) {
      color: red !important;
    }
  }

  .multiselect__input:focus {
    color: rgb(91, 106, 123);
  }
}
</style>
