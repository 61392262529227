import auth from '@/Middlewares/auth'
import guest from '@/Middlewares/guest.js'
import Route from '@/Setup/Routes/Route.js'

export const authRoutes = [
  new Route('auth.login', '/login', () => import('./Pages/Login.vue'))
    .middlewares([guest])
    .resolve(),
  new Route('auth.password-recovery', '/password-recovery', () =>
    import('./Pages/PasswordRecovery.vue')
  )
    .middlewares([guest])
    .resolve(),
  new Route('auth.password-reset', '/reset-password', () =>
    import('./Pages/PasswordReset.vue')
  )
    .middlewares([guest])
    .resolve(),
  new Route('auth.set-password', '/password-set', () =>
    import('./Pages/PasswordSet.vue')
  )
    .middlewares([guest])
    .resolve(),

  new Route('auth.login-external', '/login-external', () =>
    import('./Pages/LoginWithToken.vue')
  )
    .middlewares([guest])
    .resolve(),

  new Route('auth.redirect-special-role-user', '/redirecting', () => import('./Pages/RedirectingSpecialRoleUser.vue'))
    .middlewares([auth])
    .resolve(),
]
